import { makeStyles } from '@material-ui/core';

const useGridStyle = ({ ...gridStyles }) =>
  makeStyles((theme) => ({
    root: {
      width: '100%',
      padding: '10px 24px 15px 24px',
      backgroundColor: theme.palette.secondary.main,
      position: 'fixed',
      borderTop: `1px solid ${theme.palette.secondary.dark}`,
      bottom: 0,
      justifyContent: 'space-between',
      alignItems: 'center',
      zIndex: 100,
      ...gridStyles,
    },
  }))();

const useProductNameStyle = ({ textsFontSize, ...productNameStyles }) =>
  makeStyles({
    root: {
      fontSize: textsFontSize,
      lineHeight: 1,
      width: '50vw',
      ...productNameStyles,
    },
  })();

export default { useGridStyle, useProductNameStyle };
