import { Box } from '@material-ui/core';
import React, { useCallback, useEffect, useRef } from 'react';
import { deviceType, isIOS } from 'react-device-detect';
import ReactPlayer from 'react-player';
import { errorTypes, somaEvents, useEventDispatch } from '../../../events';
import { useEvent } from '../../../events/useEvent';
import { getDevice } from '../../../helpers/eventHelper';
import { usePlayerWrapper } from './styles';

const SomaTube = (props) => {
  const { url, rerunStartTime, isRerun, volume, playing } = props;
  const percentEventsFired = useRef(new Set());
  const dispatchEvent = useEventDispatch();
  const player = useRef(null);
  const playerWrapper = usePlayerWrapper();
  const isPipSuported = ReactPlayer.canEnablePIP(url);
  const vimeoTag = player.current?.getInternalPlayer();
  const userInteracted = useRef(false);
  const isMobile = deviceType === 'mobile';
  const usePipListeners = vimeoTag && isPipSuported && !isMobile;
  const webkitSupportsPresentationMode = vimeoTag?.webkitSupportsPresentationMode;
  const webkitSetPresentationMode = vimeoTag?.webkitSetPresentationMode;
  const requestPictureInPicture = vimeoTag?.requestPictureInPicture;

  const playerStartTime = () => {
    dispatchEvent(somaEvents.onVideoStart);
    if (!player.current && !player.current.props) return;

    player.current.seekTo(player.current.props.time || 0, 'seconds');
  };

  const reactPlayerConfig = () => ({
    vimeo: { playerOptions: { pip: true } },
    file: { forceHLS: !(isIOS && isMobile) },
  });

  const canPIP = () => 'pictureInPictureEnabled' in document && document.pictureInPictureEnabled;

  const isInPIP = () => Boolean(document.pictureInPictureElement);

  const supportsOldSafariPIP = useCallback(() => {
    if (!isIOS) return false;
    const suportsIOSPip = webkitSupportsPresentationMode && typeof webkitSetPresentationMode === 'function';

    return canPIP() && suportsIOSPip;
  }, [webkitSetPresentationMode, webkitSupportsPresentationMode]);

  const supportsModernPIP = useCallback(() => {
    const suportsPip = requestPictureInPicture && typeof requestPictureInPicture === 'function';

    return canPIP() && suportsPip;
  }, [requestPictureInPicture]);

  const openPIP = useCallback(
    async (video) => {
      if (isInPIP() || !userInteracted) return;

      if (supportsOldSafariPIP())
        await video.webkitSetPresentationMode('picture-in-picture').catch((error) => {
          dispatchEvent(somaEvents.onError, {
            type: errorTypes.requestError,
            message: error.message,
            path: 'SomaTube/index.js -> webkitSetPresentationMode()',
            device: getDevice(),
            stack: error,
          });
        });

      if (supportsModernPIP())
        await video.requestPictureInPicture().catch((error) => {
          dispatchEvent(somaEvents.onError, {
            type: errorTypes.requestError,
            message: error.message,
            path: 'SomaTube/index.js -> requestPictureInPicture()',
            device: getDevice(),
            stack: error,
          });
        });
    },
    [dispatchEvent, supportsModernPIP, supportsOldSafariPIP]
  );

  const closePIP = useCallback(
    async (video) => {
      if (!isInPIP()) return;

      if (supportsOldSafariPIP())
        await video.webkitSetPresentationMode('inline').catch((error) => {
          dispatchEvent(somaEvents.onError, {
            type: errorTypes.requestError,
            message: error.message,
            path: 'SomaTube/index.js -> webkitSetPresentationMode()',
            device: getDevice(),
            stack: error,
          });
        });

      if (supportsModernPIP())
        await document?.exitPictureInPicture().catch((error) => {
          dispatchEvent(somaEvents.onError, {
            type: errorTypes.requestError,
            message: error.message,
            path: 'SomaTube/index.js -> exitPictureInPicture()',
            device: getDevice(),
            stack: error,
          });
        });

      userInteracted.current = false;
    },
    [dispatchEvent, supportsModernPIP, supportsOldSafariPIP]
  );

  const buttonTogglePIP = async () => {
    if (isInPIP()) await closePIP(vimeoTag);
    else await openPIP(vimeoTag);
  };

  const changePipState = useCallback(async () => {
    if (!vimeoTag) return;
    if (document.visibilityState === 'visible') await closePIP(vimeoTag);
    else await openPIP(vimeoTag);
  }, [closePIP, openPIP, vimeoTag]);

  const handlePipListeners = useCallback(
    (action) => {
      switch (action) {
        case 'add':
          document.addEventListener('visibilitychange', changePipState);
          window.addEventListener('click', () => {
            userInteracted.current = true;
          });
          break;

        case 'remove':
          document.removeEventListener('visibilitychange', changePipState);
          break;

        default:
          break;
      }
    },
    [changePipState]
  );

  useEffect(() => {
    if (usePipListeners && playing) handlePipListeners('add');
    return () => {
      handlePipListeners('remove');
    };
  }, [handlePipListeners, playing, usePipListeners]);

  useEvent(somaEvents.onProductJumpedToTime, (payload) => player.current.seekTo(payload.time, 'seconds'));
  useEvent(somaEvents.onActivePip, () => buttonTogglePIP());

  return (
    <Box classes={playerWrapper}>
      <ReactPlayer
        ref={player}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          overflow: 'hidden',
        }}
        url={url}
        config={reactPlayerConfig()}
        playing={playing}
        muted={!volume}
        volume={volume}
        playsinline
        width="100%"
        height="100%"
        stopOnUnmount={false}
        controls={isRerun === 1}
        time={rerunStartTime}
        onStart={playerStartTime}
        onPause={() => {
          dispatchEvent(somaEvents.onVideoPause);
        }}
        onProgress={(progress) => {
          const { played = 0, playedSeconds } = progress;
          const progressPercent = parseInt(played * 100, 10);
          const wasFired = percentEventsFired.current.has(progressPercent);

          if (progressPercent === 98 && !wasFired) {
            percentEventsFired.current.add(progressPercent);
            dispatchEvent(somaEvents.onVideoCompleted);
          }
          dispatchEvent(somaEvents.onVideoPlayedMilliseconds, playedSeconds * 1000);
        }}
      />
    </Box>
  );
};

export default SomaTube;
