import { useContext } from 'react';

import EventContext, { contextFnIndex } from './eventContext';

export default () => {
  const context = useContext(EventContext);
  const dispatch = context[contextFnIndex.dispatch];

  return dispatch;
};
