import { makeStyles } from '@material-ui/core';

const svgProps = {
  fontStyle: 'normal',
  fontFamily: 'icomoon',
  fontVariant: 'normal',
  textTransform: 'none',
};

const userGridStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    zIndex: 10,
    width: '100%',
  },
}));

const userButtomStyles = (theme) => ({
  borderRadius: theme.share.borderRadius,
  borderColor: theme.palette.secondary.main,
  backgroundColor: theme.palette.secondary.main,
  minWidth: '32px',
  minHeight: '32px',
  width: '32px',
  height: '32px',
  padding: 0,
  opacity: 0.7,
});

const useShareIconStyle = makeStyles((theme) => ({
  root: {
    fontFamily: 'icomoon',
    fontSize: '24px',
    fontWeight: '400',
    lineHeight: 1,
    ...svgProps,
    '&:before': {
      content: '"\\e905"',
    },
    color: theme.palette.primary.main,
  },
}));

export default {
  useShareIconStyle,
  userGridStyles,
  userButtomStyles,
};
