import { Grid, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PropTypes from 'prop-types';
import React, { useCallback, useRef } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useRealTimeProductsContext } from '../../../contexts/realTimeProductsContext';
import { somaEvents, useEventDispatch } from '../../../events';
import { SomaSelectQuantity } from '../../atoms';
import { SomaTag } from '../../atoms/SomaTag';
import { PlayOnTimeline, ProductPrice } from '../../molecules';
import { FigureButton } from '../../molecules/SomaClickable';
import style from './style';

const Product = (props) => {
  const { product, updateQuantity, isBag, onRemove, isShowCasing, isMobileShowCasing, category } = props;

  const theme = useTheme();
  const productTypographyClasses = style.useProductTypography();
  const useBagProductTypography = style.useBagProductTypography();
  const bagGridClasses = style.useBagGridStyles();
  const deleteIconClasses = style.useDeleteIconStyles();
  const gridSizeClasses = style.useGridSizeStyles();
  const prodButtonClasses = style.useProdButtonStyles(theme, isBag);
  const clusterTagClasses = style.useClusterTagStyles(theme);

  const dispatch = useEventDispatch();
  const openDetails = useCallback(() => {
    dispatch(somaEvents.onOpenProductDetails, { category, product });
  }, [category, product, dispatch]);

  const portrait = useMediaQuery('(orientation: portrait)');

  const { realTimeProductsState } = useRealTimeProductsContext();
  const { timeline, allProductsWithIds } = realTimeProductsState;
  const productRerunTimestamps = allProductsWithIds.get(product.provider.productId?.toString());

  const productRef = useRef();

  const config = {
    direction: portrait && !isBag ? 'column' : 'row',
    portraitBool: portrait && !isBag,
  };

  const productThumb = product.images?.thumbs[0];

  const showPlayOnTimeLine =
    timeline && timeline.enabled && productRerunTimestamps && !isBag && !isShowCasing && !isMobileShowCasing;

  const renderClusterTag = (productInfo, isOnBag = false) => {
    const { productClusters, price } = productInfo;
    const clusterList = Object.values(productClusters);
    const tagStyle = isOnBag ? { ...clusterTagClasses.onBag } : { ...clusterTagClasses.notOnBag };

    if (!productClusters || !price) return <></>;

    if (clusterList.includes('lojix-exclusivo'))
      return (
        <SomaTag
          label={theme.somaTag.exclusive.text}
          tagStyles={{
            backgroundColor: theme.somaTag.exclusive.backgroundColor,
            ...tagStyle,
          }}
        />
      );

    if (clusterList.includes('queridinho-voltou'))
      return (
        <SomaTag
          label={theme.somaTag.mostLiked.text}
          tagStyles={{
            backgroundColor: theme.somaTag.mostLiked.backgroundColor,
            ...tagStyle,
          }}
        />
      );

    return <></>;
  };

  const renderBag = () => (
    <>
      <Grid container classes={bagGridClasses}>
        <Grid item style={{ width: 154 }}>
          <FigureButton
            figure={<img src={product.images.thumbs[0]} alt="product" style={{ width: '100%', objectFit: 'cover' }} />}
            buttonStyles={{ ...prodButtonClasses.bagProd }}
            gridStyles={{ flexDirection: 'column' }}
            buttonProps={{
              onClick: openDetails,
              disableElevation: true,
              disableFocusRipple: true,
              disableRipple: true,
            }}
          />
        </Grid>
        <Grid item style={{ flex: 1, padding: '30px 20px 0 20px' }}>
          <Grid container style={{ flexDirection: 'column' }}>
            <Grid item xs={12} style={{ overflow: 'hidden', marginBottom: 20 }}>
              <Typography color="primary" classes={useBagProductTypography}>
                {product.productName}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 16 }}>
              <ProductPrice product={product} isBag priceStyles={{ color: theme.mainColor }} />
            </Grid>
            {renderClusterTag(product, true)}
            <Grid item classes={gridSizeClasses}>
              <Typography style={{ textTransform: 'uppercase', fontSize: 12 }}>
                {product.bagInfo.selectedItem.size}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <SomaSelectQuantity
                number={product.bagInfo?.quantity}
                onClickMinus={() => {
                  updateQuantity(product.bagInfo.uuid, -1);
                  dispatch(somaEvents.onCartItemAmountDecreased, {
                    product: {
                      ...product,
                      sku: product.bagInfo.selectedItem.sku,
                      variant: product.bagInfo.selectedItem.size,
                      quantity: product.bagInfo.quantity + 1,
                    },
                  });
                }}
                onClickPlus={() => {
                  updateQuantity(product.bagInfo.uuid, 1);
                  dispatch(somaEvents.onCartItemAmountIncreased, {
                    product: {
                      ...product,
                      sku: product.bagInfo.selectedItem.sku,
                      variant: product.bagInfo.selectedItem.size,
                      quantity: product.bagInfo.quantity + 1,
                    },
                  });
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid classes={deleteIconClasses}>
        <FigureButton
          figure={<img src={theme.bag.deleteIcon.src} alt="delete from bag" style={{ width: 30 }} />}
          buttonStyles={{ ...prodButtonClasses.deleteProd }}
          buttonProps={{ onClick: () => onRemove(product) }}
        />
      </Grid>
    </>
  );

  const renderDefault = () => (
    <Grid
      container
      ref={productRef}
      direction={config.direction}
      style={{ position: 'relative', backgroundColor: '#FFFFFF' }}
    >
      <Grid container item xs={12} style={{ position: 'relative' }}>
        {productThumb && (
          <FigureButton
            figure={<img src={product.images.thumbs[0]} alt="product" style={{ width: '100%' }} />}
            buttonStyles={{ ...prodButtonClasses.bagProd }}
            gridStyles={{ flexDirection: 'column' }}
            buttonProps={{
              onClick: openDetails,
              disableElevation: true,
              disableFocusRipple: true,
              disableRipple: true,
            }}
          />
        )}
        {!productThumb && <Skeleton width={178} height={267} variant="rect" />}
        {showPlayOnTimeLine && <PlayOnTimeline timestamps={productRerunTimestamps} product={product} />}
        {renderClusterTag(product)}
      </Grid>
      <Grid container item xs={12} style={{ padding: '5px 10px' }}>
        <Grid item xs={12}>
          <Typography color="primary" noWrap classes={productTypographyClasses}>
            {product.productName}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ProductPrice product={product} />
        </Grid>
      </Grid>
    </Grid>
  );

  return isBag ? renderBag() : renderDefault();
};

Product.defaultProps = {
  details: null,
  images: [],
  productName: null,
  isShowCasing: false,
};

Product.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string),
  productName: PropTypes.string,
  details: PropTypes.shape({
    description: PropTypes.string,
    composition: PropTypes.string,
  }),
};

export default Product;
