import { makeStyles } from '@material-ui/core';

const usePlayOnVideoButtonStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    minWidth: 32,
    width: 32,
    minHeight: 32,
    height: 32,
    borderRadius: '50%',
    marginBottom: '6px',
    color: theme.palette.primary.main,
    zIndex: 2,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));

const useCollapseStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '10px',
    width: '85px',
    minHeight: '90px',
    marginTop: '-38px',
    marginLeft: '14px',
  },
}));

const usePlayOnVideoByTimeButtonStyles = makeStyles((theme) => ({
  label: {
    fontSize: '12px',
    lineHeight: '16px',
  },
  root: {
    borderRadius: '30px',
    margin: '9px 19px 0px',
    color: theme.palette.primary.main,
    padding: '0px 2px 0px 2px',
    zIndex: 2,
    textAlign: 'center',
    width: '40px',
    height: '20px',
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
    '&:nth-child(1)': {
      margin: '8px 16px 0px 19px',
    },
    '&:last-child': {
      margin: '9px 19px 7px',
    },
    objectFit: 'contain',
  },
}));

const useButtonPlayByTimeIconStyles = makeStyles({
  root: {
    fontSize: '10px !important',
  },
});

const usePlayOnVideoGridStyles = makeStyles({
  root: {
    position: 'absolute',
    top: 10,
    left: 10,
    height: 'auto',
  },
});

export default {
  usePlayOnVideoButtonStyles,
  usePlayOnVideoByTimeButtonStyles,
  usePlayOnVideoGridStyles,
  useButtonPlayByTimeIconStyles,
  useCollapseStyles,
};
