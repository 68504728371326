import { makeStyles } from '@material-ui/core';

export const useMainBoxStyles = makeStyles({
  root: { position: 'fixed', width: '100vw', height: '100vh', backgroundColor: '#000', zIndex: 2 },
});

export const usePlayerWrapperStyles = makeStyles({ root: { position: 'relative', paddingTop: '56.25%' } });

export const useTitleBoxStyles = makeStyles({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    padding: '5px 0px 0px 8px',
    width: '100vw',
    background: 'linear-gradient(180deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%)',
  },
});

export const useTitleTypographyStyles = (theme) =>
  makeStyles({
    root: { color: '#FFF' },
    subtitle2: {
      fontFamily: theme?.miniPlayer?.typography?.fontFamily || 'inherit',
      fontSize: '0.625rem',
    },
    h4: { color: '#999' },
    h6: {
      fontFamily: theme?.miniPlayer?.typography?.fontFamily || 'inherit',
      fontSize: theme?.miniPlayer?.typography?.titleFontSize || '0.875rem',
    },
  })();

export const useCtaBoxStyles = makeStyles({
  root: { position: 'absolute', top: 0, left: 0, width: '100vw', height: '100vh', backgroundColor: 'transparent' },
});

export const useOfflineContainerStyles = makeStyles({
  root: {
    backgroundColor: '#000',
    width: '100vw',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',

    '& img': {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'center',
    },
  },
});

export const useVideoControlsBoxStyles = makeStyles({
  root: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'flex-end',
    bottom: 0,
    left: 0,
    background: 'linear-gradient(0deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%)',
    width: '100vw',
    pointerEvents: 'none',
  },
});

export const useVideoControlsIconButtonStyles = makeStyles({
  root: { pointerEvents: 'auto' },
});

export const useVideoControlsIconStyles = makeStyles({
  root: { color: '#FFF' },
});
