import { makeStyles } from '@material-ui/core';

const useTabStyle = makeStyles((theme) => ({
  root: {
    fontSize: theme.somaNavigation.tab.fontSize,
    textTransform: theme.somaNavigation.tab.textTransform,
    color: theme.palette.primary.light,
    minHeight: '64px',
    padding: 0,
  },
  wrapper: {
    justifyContent: 'flex-end',
    '& img': {
      height: '17px',
      objectFit: 'contain',
      width: '17px',
    },
  },
}));

const svgProps = {
  fontStyle: 'normal',
  fontFamily: 'icomoon',
  fontVariant: 'normal',
  textTransform: 'none',
};

const useMenuStyle = makeStyles(() => ({
  root: {
    display: 'none',
  },
}));

const useTabPanelStyle = makeStyles(() => ({
  root: {
    minHeight: 0,
    padding: 0,
    flex: 1,
  },
  container: { width: '100vw', height: 'auto' },
}));

const useTabPanelChatStyle = makeStyles(() => ({
  root: {
    minHeight: 0,
    padding: 0,
    flex: 1,
    height: '100%',
  },
}));

const useTabPanelBagStyle = makeStyles(() => ({
  root: {
    minHeight: 0,
    padding: 0,
    flex: 1,
    height: '100%',
  },
}));

const useAppBarStyle = makeStyles((theme) => ({
  root: {
    elevation: 0,
    backgroundColor: theme.palette.secondary.main,
    top: 0,
    zIndex: 10,
    maxHeight: 64,
    position: 'relative',
    boxShadow: 'none',
  },
}));

const useBadgeStyles = makeStyles(() => ({
  root: {
    position: 'static',
  },
  badge: {
    top: 0,
    right: 40,
    transform: 'translate(111%, 140%)',
    minWidth: '16px',
    height: '16px',
    transformOrigin: '100% 0%',
    padding: 0,
  },
}));

const useProductIconStyle = ({ isActive }) =>
  makeStyles((theme) => ({
    root: {
      fontFamily: 'icomoon',
      fontSize: '17px',
      lineHeight: 1,
      ...svgProps,
      '&:before': {
        content: '"\\e902"',
      },
      color: isActive === 'product' ? theme.palette.primary.main : theme.palette.primary.light,
    },
  }))();

const useBagIconStyle = ({ isActive }) =>
  makeStyles((theme) => ({
    root: {
      fontFamily: 'icomoon',
      fontSize: '17px',
      lineHeight: 1,
      ...svgProps,
      '&:before': {
        content: '"\\e900"',
      },
      color: isActive === 'bag' ? theme.palette.primary.main : theme.palette.primary.light,
    },
  }))();

const useFaqIconStyle = ({ isActive }) =>
  makeStyles((theme) => ({
    root: {
      fontFamily: 'icomoon',
      fontSize: '17px',
      lineHeight: 1,
      ...svgProps,
      '&:before': {
        content: '"\\e901"',
      },
      color: isActive === 'faq' ? theme.palette.primary.main : theme.palette.primary.light,
    },
  }))();

const useAnchorSizeIconStyle = ({ isActive }) =>
  makeStyles((theme) => ({
    root: {
      fontFamily: 'icomoon',
      fontSize: '17px',
      lineHeight: 1,
      ...svgProps,
      '&:before': {
        content: '"\\e906"',
      },
      color: isActive === 'anchorSize' ? theme.palette.primary.main : theme.palette.primary.light,
    },
  }))();

const usechatIconStyle = ({ isActive }) =>
  makeStyles((theme) => ({
    root: {
      fontFamily: 'icomoon',
      fontSize: '17px',
      lineHeight: 1,
      ...svgProps,
      '&:before': {
        content: '"\\e904"',
      },
      color: isActive === 'chat' ? theme.palette.primary.main : theme.palette.primary.light,
    },
  }))();

const useSelectedStyle = makeStyles((theme) => ({
  root: {
    fontSize: theme.somaNavigation.tab.fontSize,
    textTransform: theme.somaNavigation.tab.textTransform,
    color: theme.palette.primary.main,
    minHeight: '64px',
    padding: 0,
  },
  wrapper: {
    justifyContent: 'flex-end',
    '& img': {
      height: '17px',
      objectFit: 'contain',
      width: '17px',
    },
  },
}));

export default {
  useTabStyle,
  useAppBarStyle,
  useTabPanelStyle,
  useTabPanelChatStyle,
  useTabPanelBagStyle,
  useBadgeStyles,
  useMenuStyle,
  useProductIconStyle,
  useSelectedStyle,
  useBagIconStyle,
  useFaqIconStyle,
  usechatIconStyle,
  useAnchorSizeIconStyle,
};
