import { makeStyles } from '@material-ui/core';

const usePriceTypography = ({ discontPriceColor, fontSize, ...priceStyles }) =>
  makeStyles((theme) => ({
    root: {
      fontSize,
      lineHeight: '16px',
      textTransform: 'uppercase',
      color: theme.palette.primary.light,
      ...priceStyles,
    },
    body2: {
      color: discontPriceColor || theme.palette.feedback.main,
    },
    subtitle1: {
      textDecoration: 'line-through',
      fontSize: 14,
    },
    subtitle2: {
      textDecoration: 'line-through',
    },
  }))();

const useGridClasses = ({ ...gridPriceStyle }) =>
  makeStyles({
    root: {
      columnGap: 5,
      margin: '0px 0 3px 0',
      ...gridPriceStyle,
    },
  })();

const useGridItemClasses = makeStyles({
  root: {
    padding: '0',
  },
});

export default {
  usePriceTypography,
  useGridClasses,
  useGridItemClasses,
};
