import React, { useEffect, useState, useRef, useCallback } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Helmet from 'react-helmet';

import { Grid, Snackbar } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';

import { StyledEngineProvider } from '@mui/material';
import { Live, EmbedLiveStream, LiveNotFound } from './components/pages';
import config from './config';
import Theme from './themes/index';
import { decodeLogin } from './helpers/authHelper';
import { get as getStorage, save } from './helpers/localStorageHelper';
import { UserContext, SalesWomanContext, LocalStorageContext } from './hooks/contexts';
import { EventEmitter } from './events/eventEmitter';
import Plugins from './plugins';
import { socketBuilder } from './services/socket';
import { useEventDispatch, somaEvents, errorTypes } from './events';
import { RealTimeProductsContextProvider, SocketContextProvider, StreamContextProvider } from './contexts';
import { useRealTimeProductsContext } from './contexts/realTimeProductsContext';
import { useSocketContext } from './contexts/socketContext';
import { useStreamContext } from './contexts/streamContext';

import SomaliveAPIClient from './services/somaliveAPI';
import { ChatContextProvider } from './contexts/chatContext';
import { BagContextProvider } from './contexts/bagContext';
import { SnackbarContextProvider, useSnackbarContext } from './contexts/snackBarContext';
import { getDevice } from './helpers/eventHelper';

import Loading from './components/atoms/Loading';
import { useEvent } from './events/useEvent';

const [, streamName] = window.location.pathname.split('/');

const urlSearchParams = new URLSearchParams(window.location.search);
const saleswomanCouponCode = urlSearchParams.get('codigovendedora') || urlSearchParams.get('cod');

const Somalive = new SomaliveAPIClient(streamName);

const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];

if (path) {
  window.history.replaceState(null, 'Soma Live', path);
}

const AppContainer = () => {
  const user = decodeLogin();
  const [theme, setTheme] = useState(Theme());
  const [brand, setBrand] = useState();
  const [initialData, setInitialData] = useState();
  const [userStorage, setUserStorage] = useState();
  const [showLGPD, setShowLGPD] = useState(false);
  const [reactionControl, setReactionControl] = useState();
  const [loading, setLoading] = useState(true);
  const [hasChat, setHasChat] = useState();

  const { snackBarOpened, snackBarText, snackBarTypeAlert, closeSnack } = useSnackbarContext();
  const prevButtonsReqAbortController = useRef(null);

  const dispatchEvent = useEventDispatch();
  const { setRealTimeProductsTimeline, setShowCasing } = useRealTimeProductsContext();
  const { socket, setSocket, setReceivedMessage, setSurveyQuestion } = useSocketContext();
  const { streamContent, setStreamContent } = useStreamContext();

  const mapUniqueProducts = (categories) => {
    if (categories && categories.length) {
      return new Map(
        categories.reduce(
          (acc, category) => acc.concat(category.products.map((product) => [product.provider.productId, product])),
          []
        )
      );
    }
    return new Map();
  };

  useEffect(() => {
    Somalive.getStream({
      filters: 'id_stream,brand,is_live,stream_name,sales_channel,video,analytics,buttons,has_chat,has_reaction',
    })
      .request.then((response) => {
        setShowCasing([]);
        setStreamContent({ ...response, uniqueProducts: mapUniqueProducts(response.buttons) });
        setReactionControl(response.has_reaction);
        setHasChat(response.has_chat);
        if (response.id_stream && response.video.isRerun) {
          Somalive.getTimeline(response.id_stream)
            .request.then((timeline) => setRealTimeProductsTimeline(timeline))
            .catch((error) => {
              dispatchEvent(somaEvents.onError, {
                type: errorTypes.requestError,
                message: 'Failed to get getTimeline',
                path: 'App.js -> Somalive.getTimeline()',
                device: getDevice(),
                stack: error.stack,
              });
            });
        }
      })
      .catch((error) => {
        dispatchEvent(somaEvents.onError, {
          type: errorTypes.requestError,
          message: error.message,
          path: 'App.js -> Somalive.getStream()',
          device: getDevice(),
          stack: error.stack,
        });
        setStreamContent(null);
        setRealTimeProductsTimeline(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    dispatchEvent,
    setRealTimeProductsTimeline,
    setShowCasing,
    setStreamContent,
    setLoading,
    hasChat,
    reactionControl,
  ]);

  const updateProductInfo = useCallback(() => {
    const { request, abortController } = Somalive.getStream({ filters: 'buttons' });

    if (prevButtonsReqAbortController.current) {
      prevButtonsReqAbortController.current.abort(theme?.system?.cancelPreviousRequestError);
    }
    prevButtonsReqAbortController.current = abortController;

    request
      .then((response) => {
        if (response.buttons) setStreamContent({ ...response, uniqueProducts: mapUniqueProducts(response.buttons) });
      })
      .catch((error) => {
        dispatchEvent(somaEvents.onError, {
          type: errorTypes.requestError,
          message: 'Failed to request products',
          path: 'App.js -> Somalive.getStream(buttons)',
          device: getDevice(),
          stack: error.stack,
        });
      });
  }, [dispatchEvent, setStreamContent, theme]);

  useEffect(() => {
    if (!streamContent || !streamContent.brand || !streamContent.stream_name) return;
    setTheme(Theme(streamContent.brand.name, streamContent.stream_name));
    setBrand(streamContent.brand);
    setUserStorage(getStorage(streamContent.brand.name + streamContent.stream_name));
  }, [streamContent]);

  useEffect(() => {
    const intervalId = setInterval(updateProductInfo, config.streamContentUpdateInterval);
    return () => window.clearInterval(intervalId);
  }, [updateProductInfo]);

  useEffect(() => {
    if (!socket) return undefined;

    socket.on('connect', () => {
      socket.emit('getCurrentProduct', 'getCurrentProduct');
      const chat = userStorage?.get('chat');
      if (chat) {
        socket.emit('joinUserPrivateRoom', chat.get('privateRoom'));
      }
    });

    socket.on('receivePrivateReplies', (privateReplies) => {
      const chat = userStorage?.get('chat');
      privateReplies.forEach((reply) => {
        const found = chat.get('messages').find((message) => message.replyingTo === reply.replyingTo);
        if (found) return;

        chat.set('messages', chat.get('messages').concat(reply));
        save(userStorage);
        setUserStorage(new Map(userStorage));
      });
    });

    socket.on('refreshStream', (streamData) => {
      if (!streamContent || !streamData || !streamData.video?.id || typeof streamData.isLive === 'undefined')
        return setStreamContent({ is_live: Boolean(streamData.isLive) });

      const hasNotUpdatedVideoID = streamData.video.id === streamContent.video.vimeo_id;
      const hasNotUpdatedLivenessState = Boolean(streamContent.is_live) === Boolean(streamData.isLive);
      const hasChangeOnReactionState = setReactionControl(streamData.isReaction);
      const hasChangeOnChatState = setHasChat(streamData.hasChat);

      if (hasNotUpdatedLivenessState && hasNotUpdatedVideoID && hasChangeOnReactionState && hasChangeOnChatState)
        return null;

      if (hasNotUpdatedVideoID) return setStreamContent({ is_live: streamData.isLive });

      if (/^\d+$/.test(streamData.video?.id)) {
        return Somalive.getVimeoInfo(streamData.video.id)
          .request.then((result) => {
            const vimeoData = { ...streamData.video, url: result.liveUrl };
            return setStreamContent({ is_live: streamData.isLive, video: { ...vimeoData } });
          })
          .catch((error) => {
            setStreamContent({ is_live: streamData.isLive });
            dispatchEvent(somaEvents.onError, {
              type: errorTypes.requestError,
              message: `Error on socket refreshStream :${error.message}`,
              path: 'App.js -> refreshStream',
              device: getDevice(),
              stack: error.stack,
            });
          });
      }
      return setStreamContent({ is_live: streamData.isLive });
    });

    socket.on('updateRealTimeProducts', (realtimeProducts) => {
      setShowCasing(realtimeProducts);
    });

    socket.on('currentProductData', (currentProducts) => {
      if (currentProducts) {
        setShowCasing(currentProducts);
      }
    });

    socket.on('currentData', (data) => {
      if (user) {
        setInitialData(data);
      }
    });

    socket.on('receiveSurvey', (data) => {
      setSurveyQuestion(data);
    });

    socket.on('receivedMessage', (data) => {
      setReceivedMessage(data);
    });

    socket.on('receivedReaction', () => {
      if (document.hasFocus()) {
        dispatchEvent('othersReaction');
      }
    });

    return () => {
      socket.removeAllListeners();
    };
  }, [
    socket,
    user,
    streamContent,
    userStorage,
    dispatchEvent,
    setShowCasing,
    setReceivedMessage,
    setStreamContent,
    setSurveyQuestion,
  ]);

  useEvent('heartsUp', () => {
    if (socket) {
      socket.emit('heartsUp');
    }
  });

  useEffect(() => {
    if (!brand || !streamContent?.stream_name || socket) return;

    const socketProps = { brand: brand.name, streamName: streamContent.stream_name };

    setSocket(socketBuilder(socketProps));
  }, [brand, setSocket, socket, streamContent]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <>
        {streamContent && userStorage && (
          <>
            <Helmet>
              <link rel="icon" type="favicon.ico" href={theme.favicon} sizes="16x16" />
              <title>{theme.title}</title>
            </Helmet>
            <Plugins brand={brand} config={config} showLGPD={showLGPD} />
            <Snackbar
              open={snackBarOpened}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              onClose={closeSnack}
              autoHideDuration={3000}
            >
              <Alert
                style={{ borderRadius: theme.snackBarRadius }}
                severity={snackBarTypeAlert}
                action={<CloseIcon style={theme.snackbar.iconStyles} onClick={closeSnack} />}
              >
                {snackBarText}
              </Alert>
            </Snackbar>
            <Grid container>
              <UserContext.Provider value={user}>
                <SalesWomanContext.Provider value={{ saleswomanCouponCode }}>
                  <BrowserRouter>
                    <Switch>
                      <Route
                        path="/:stream"
                        render={() => (
                          <LocalStorageContext.Provider value={userStorage}>
                            <StyledEngineProvider injectFirst>
                              <Grid item xs={12}>
                                <Live
                                  user={user}
                                  initialData={initialData}
                                  setShowLGPD={setShowLGPD}
                                  reactionSocketState={reactionControl}
                                  hasChatSocketState={hasChat}
                                />
                              </Grid>
                            </StyledEngineProvider>
                          </LocalStorageContext.Provider>
                        )}
                      />
                    </Switch>
                  </BrowserRouter>
                </SalesWomanContext.Provider>
              </UserContext.Provider>
            </Grid>
          </>
        )}
        {!loading && !streamContent && <LiveNotFound />}
        {loading && <Loading />}
      </>
    </ThemeProvider>
  );
};

const App = () => (
  <>
    <CssBaseline />
    <EventEmitter>
      <BrowserRouter>
        <Switch>
          <Route path="/:stream/embed">
            <EmbedLiveStream />
          </Route>
          <Route path="/">
            <StreamContextProvider>
              <RealTimeProductsContextProvider>
                <SocketContextProvider>
                  <ChatContextProvider>
                    <BagContextProvider>
                      <SnackbarContextProvider>
                        <AppContainer />
                      </SnackbarContextProvider>
                    </BagContextProvider>
                  </ChatContextProvider>
                </SocketContextProvider>
              </RealTimeProductsContextProvider>
            </StreamContextProvider>
          </Route>
        </Switch>
      </BrowserRouter>
    </EventEmitter>
  </>
);

export default App;
