import { makeStyles } from '@material-ui/core';

const usePaperStyle = (paper, portrait, liveActive) =>
  makeStyles({
    root: {
      position: 'fixed',
      left: 0,
      top: 0,
      alignItems: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundColor: 'white',
      width: '100%',
      height: '100%',
      backgroundImage: portrait ? paper.backgroundImageMobile : paper.backgroundImageDesktop,
      overflow: 'hidden',
      zIndex: 1500,
      cursor: liveActive ? 'pointer' : 'auto',
    },
  });

const useContainerStyle = makeStyles({
  container: {
    width: '100%',
    height: '100%',
  },
});

const useLogoStyle = makeStyles((theme) => {
  const { paddingBotton, paddingTop } = theme.welcomePage.container.header;

  return {
    root: {
      paddingBotton,
      paddingTop,
      display: 'none',
    },
  };
});

const useWelcomeContentStyles = makeStyles((theme) => {
  const { paddingTop, paddingRight, paddingLeft, paddingBottom, maxHeight } = theme.welcomePage.container.content;
  return {
    root: {
      display: 'none',
      maxHeight,
      paddingTop,
      paddingRight,
      paddingLeft,
      paddingBottom,
      overflowY: 'auto',
    },
  };
});

const useCallToActionDesktopStyles = makeStyles((theme) => {
  const { maxHeight, margin } = theme.welcomePage.container.callToAction;
  const screenWidth = window.screen.width;
  return {
    root: {
      maxHeight,
      margin,
    },
    item: {
      whiteSpace: 'nowrap',
      minWidth: '172px',
      fontSize: '16px',
      height: '56px',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      marginTop: screenWidth === 1920 ? '260px' : '200px',
      '& .MuiButton-text': {
        padding: '0px 30px',
        maxWidth: ' 215px',
      },
    },
  };
});

const useCallToActionLandscapeStyles = makeStyles((theme) => {
  const { maxHeight, margin } = theme.welcomePage.container.callToAction;
  return {
    root: {
      maxHeight,
      margin,
    },
    item: {
      width: '164px',
      fontSize: '16px',
      height: '56px',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-290%)',
      marginTop: '250px',
    },
  };
});

const useCallToActionPortraitStyles = makeStyles((theme) => {
  const { maxHeight, margin } = theme.welcomePage.container.callToAction;
  return {
    root: {
      maxHeight,
      margin,
    },
    item: {
      minWidth: '200px',
      fontSize: '16px',
      height: '56px',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      marginTop: '200px',
    },
  };
});

export default ({ portrait, paper, liveActive }) => ({
  usePaperStyle: usePaperStyle(paper, portrait, liveActive),
  useLogoStyle,
  useContainerStyle,
  useWelcomeContentStyles,
  useCallToActionDesktopStyles,
  useCallToActionLandscapeStyles,
  useCallToActionPortraitStyles,
});
