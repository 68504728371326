import { makeStyles } from '@material-ui/core';

const useProductNameStyle = {
  marginBottom: 10,
  whiteSpace: 'break-spaces !important',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  lineHeight: '16px',
};

const svgProps = {
  fontStyle: 'normal',
  fontFamily: 'icomoon',
  fontVariant: 'normal',
  textTransform: 'none',
};

const useButtonStyle = (theme) => ({
  label: {
    color: theme.palette.secondary.main,
    fontSize: 'inherit',
  },
  primary: {
    width: theme.pdp.ctaButtonWidth,
    height: theme.pdp.ctaButtonHeight,
    borderRadius: theme.pdp.ctaButtonRadius,
    fontSize: theme.pdp.ctaButtonSize,
    border: `none`,
    backgroundColor: theme.palette.primary.main,

    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },

    '@media (orientation: portrait)': {
      marginTop: '0',
      width: '98px',
    },
  },
  secondary: {
    border: `1px solid ${theme.palette.secondary.dark}`,
    backgroundColor: theme.palette.secondary.main,

    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
});

const useGridStyle = makeStyles({
  root: {
    padding: '30px 0 10px 49px',
    columnGap: 32,
    height: '100%',
    width: '100%',

    '@media (orientation: portrait)': {
      padding: '0',
    },
  },
});

const useRenderDetailGridStyle = makeStyles({
  root: {
    padding: '10px 30px 10px 0',
    height: '100%',
    width: '100%',

    '@media (orientation: portrait)': {
      padding: '24px 20px 117px 20px',
    },
  },
});

const useDetailsGridStyle = makeStyles({
  root: {
    maxWidth: 343,
    width: 343,
    minWidth: 343,
    paddingBottom: 10,

    '@media (orientation: portrait)': {
      maxWidth: '100%',
      width: '100%',
      minWidth: '100%',
    },
  },
});

const useNameStyle = makeStyles((theme) => ({
  root: {
    fontSize: theme.pdp.productNameSize,
    lineHeight: theme.pdp.productNameHeight,
    marginBottom: '5px',
    marginTop: theme.pdp.productNameSpacingTop,
    width: '100%',
  },
}));

const usePriceStyle = makeStyles({
  root: {
    fontSize: 18,
    fontFamily: 'inherit',
  },
});

const useDetailsStyle = makeStyles((theme) => ({
  container: {
    marginTop: 46,

    '@media (orientation: portrait)': {
      marginTop: 35,
    },
  },
  text: {
    color: theme.palette.primary.main,
    fontSize: theme.products.details.textSize,
    marginTop: '16px',
    lineHeight: '16px',

    '@media (orientation: portrait)': {
      fontSize: 12,
    },
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: theme.products.details.titleSize,
    marginBottom: 16,
    text: 'Sobre a peça',

    '@media (orientation: portrait)': {
      marginBottom: 13,
    },
  },
}));

const useButtomStyles = makeStyles({
  root: {
    paddingBottom: '0',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

const useImageGridStyle = makeStyles({
  root: {
    position: 'relative',
    maxWidth: 402,
    width: 402,
    paddingBottom: 10,

    '@media (orientation: portrait)': {
      maxWidth: '100%',
      width: '100%',
      paddingBottom: 0,
    },
  },
});

const FigureButtonMobileStyle = (theme) => ({
  padding: 0,
  position: 'absolute',
  zIndex: 10,
  height: theme.products.FigureButtonMobileStyle.height,
  minWidth: theme.products.FigureButtonMobileStyle.minWidth,
  marginLeft: -353,
  marginTop: -565,
  borderRadius: theme.products.FigureButtonMobileStyle.shape.borderRadius,

  '@media (max-width: 415px) and (orientation: portrait)': {
    marginLeft: -345,
    marginTop: -550,
  },

  '@media (max-width: 400px) and (orientation: portrait)': {
    marginLeft: -310,
    marginTop: -500,
  },
  '@media (max-width: 360px) and (orientation: portrait)': {
    marginLeft: -295,
    marginTop: -475,
  },
});

const useshareIconMobileStyle = makeStyles((theme) => ({
  root: {
    fontFamily: 'icomoon',
    fontSize: '32px',
    fontWeight: '500',
    lineHeight: 1,
    ...svgProps,
    '&:before': {
      content: '"\\e905"',
    },
    color: theme.palette.primary.main,
  },
}));

const useShareIconStyle = makeStyles((theme) => ({
  root: {
    fontFamily: 'icomoon',
    fontSize: '40px',
    fontWeight: '500',
    lineHeight: 1,
    ...svgProps,
    '&:before': {
      content: '"\\e905"',
    },
    color: theme.palette.primary.main,
  },
}));

const useGridButtonShareStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 334,
    height: 48,
    marginTop: 35,
  },
}));

const useFigureButtonStyles = (theme) => ({
  height: 48,
  width: 48,
  minWidth: 0,
  borderRadius: theme.products.useFigureButtonStyles.shape.borderRadius,
  border: theme.products.useFigureButtonStyles.border,
  padding: 0,
  '&:hover': {
    backgroundColor: theme.products.useFigureButtonStyles.backgroundColor,
  },
});

const useClusterTagClasses = (portrait) => ({
  marginBottom: portrait ? 16 : 0,
  marginTop: portrait ? 0 : 8,
  padding: '4px 12px',
  borderRadius: '4px',
  lineHeight: '16px',
  height: 'auto',
  width: 'auto',
  fontSize: 12,
});

export default {
  useNameStyle,
  usePriceStyle,
  useGridStyle,
  useDetailsStyle,
  useButtomStyles,
  useButtonStyle,
  useImageGridStyle,
  useDetailsGridStyle,
  useRenderDetailGridStyle,
  FigureButtonMobileStyle,
  useshareIconMobileStyle,
  useProductNameStyle,
  useFigureButtonStyles,
  useGridButtonShareStyles,
  useShareIconStyle,
  useClusterTagClasses,
};
