import React, { useState } from 'react';
import { Grid, Typography, Dialog, DialogContent, DialogActions, Fab } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { BrowserView, MobileView } from 'react-device-detect';
import lodash from 'lodash';
import SomaDrawer from '../../organisms/SomaDrawer';
import { useEventDispatch, somaEvents } from '../../../events';
import { TableComponent } from './table';
import Style from './styles';
import config from '../../../config';
import TextButton from '../SomaClickable/textButton';

const MeasureTable = (props) => {
  const { product, portrait } = props;

  const theme = useTheme();

  const additionalInfoText = lodash.get(theme, 'products.measure.additionalText');
  const measureImage = lodash.get(theme, 'products.measure.image');
  const mobileImage = lodash.get(theme, 'products.measure.mobileImage');

  const measureArrow = `${config.assetsUrl}/common/icons/measure_arrow.svg`;
  const closeModalIcon = `${config.assetsUrl}/common/icons/modal-close-icon.svg`;

  const dispatchEvent = useEventDispatch();

  const [open, setOpen] = useState(false);

  const iconButtonClasses = Style.useIconButtonStyle();
  const imageClasses = Style.useImageStyle();
  const aditionalTextClasses = Style.useAditionTextStyles();
  const dialogContainerClasses = Style.useDialogContainerStyles();
  const dialogActionClasses = Style.useDialogActionsStyle();
  const desktopGridClasses = Style.useDesktopGridStyles();
  const buttonClasses = Style.useButtontyles(theme);

  const [dialogContentClasses, gridClasses] = [
    Style.useDialogContentStyle.useDialogContentStyles(),
    Style.useDialogContentStyle.useGridStyles(),
  ];

  const openDialog = () => {
    setOpen(true);
    dispatchEvent(somaEvents.onProductMeasurementChartShown, product);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const MeasureTableContent = () => (
    <>
      <DialogContent classes={dialogContentClasses}>
        <Grid container direction={portrait ? 'column' : 'row'} justify="center" classes={gridClasses}>
          {measureImage && (
            <BrowserView style={{ height: '100%' }}>
              <Grid item xs={4} classes={imageClasses}>
                <img alt="measure_guide" src={measureImage} />
              </Grid>
            </BrowserView>
          )}

          <Grid
            container
            item
            xs={12}
            md={mobileImage ? 6 : 12}
            lg={mobileImage ? 6 : 12}
            direction="row"
            justify="center"
            alignItems="center"
            classes={desktopGridClasses}
          >
            {mobileImage && (
              <MobileView>
                <Grid item xs={12} classes={imageClasses}>
                  <img alt="measure_guide" src={mobileImage} width="100%" />
                </Grid>
              </MobileView>
            )}
            <TableComponent product={product} />
            <Grid item container xs={12} md={12} lg={12} justify="center">
              <Grid container item xs={12} lg={12} md={12} direction="column">
                <Grid container direction="row" justify="center">
                  <Typography classes={aditionalTextClasses}>{additionalInfoText}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
  return (
    <>
      <TextButton
        label={theme.products.measure.title}
        labelStyles={{ ...buttonClasses.label }}
        buttonProps={{ onClick: openDialog, fullWidth: false, endIcon: <img src={measureArrow} alt="measureArrow" /> }}
        buttonStyles={{ ...buttonClasses.button }}
      />
      {!portrait && (
        <Dialog open={open} classes={dialogContainerClasses} onClose={closeDialog} onBackdropClick={closeDialog}>
          <DialogActions classes={dialogActionClasses}>
            <Fab classes={iconButtonClasses} onClick={closeDialog} disableRipple disableFocusRipple>
              <img src={closeModalIcon} alt="close_modal" width="12px" height="12px" />
            </Fab>
          </DialogActions>
          <MeasureTableContent />
        </Dialog>
      )}
      {portrait && (
        <SomaDrawer
          isOpen={open}
          setIsOpen={setOpen}
          anchorDirection="bottom"
          borderRadius={theme.pdp.mobileBorderRadius}
          padding="0"
          maxHeight="90%"
          closeSelfButton
        >
          <MeasureTableContent />
        </SomaDrawer>
      )}
    </>
  );
};

export default MeasureTable;
