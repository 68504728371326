import { makeStyles } from '@material-ui/core';

const useGridListStyles = makeStyles(() => ({
  root: {
    '@media (orientation: landscape)': {
      overflow: 'auto',
      height: 'calc(100vh - 248px)',
      width: '96%',
      margin: '0 auto',
      marginTop: 20,
    },
  },
}));

const useGridStyles = makeStyles({
  root: {
    flex: 1,
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
    margin: 0,
    padding: 0,
    height: 'auto',
    width: 482,
    columnGap: '6px',
    '@media (orientation: landscape)': {
      columnGap: '40px',
    },
    '@media (orientation: portrait)': {
      width: '100%',
      rowGap: 20,
    },
  },
});

const useBagGridStyles = makeStyles(() => ({
  root: {
    flex: 1,
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
    margin: 0,
    padding: 0,
    height: 'auto',
    columnGap: 6,
    '@media (orientation: landscape)': {
      rowGap: 20,
      width: 382,
      columnGap: 40,
    },
    '@media (orientation: portrait)': {
      width: 'calc(100vh - 50px)',
      rowGap: 10,
    },
  },
}));

const errorTextStyles = (theme) => ({
  h1: {
    fontSize: theme.errorOnSearch.fontSizeH1,
    lineHeight: '32px',
  },
  p: {
    fontSize: theme.errorOnSearch.fontSizeP,
    marginTop: '8px',
    lineHeight: theme.errorOnSearch.lineHeightP,
  },
  img: {
    marginTop: '-65px',
    marginBottom: '19px',
  },
});

const useErroTextContainerStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.errorOnSearch.marginTop,
    marginRight: '42px',
    marginLeft: '42px',
    '@media (orientation: portrait)': {
      marginTop: '45px',
      marginRight: '25px',
      marginLeft: '25px',
    },
  },
}));

const useProductStyles = makeStyles({
  root: {
    margin: 0,
    '@media (orientation: portrait)': {
      width: 'calc(50% - 3px)!important',
      paddingRight: '0!important',
      paddingLeft: '0!important',
    },
    '@media (orientation: landscape)': {
      width: '221px !important',
      padding: '0 0 40px 0 !important',
    },
  },
});

const useFormattedPricesClasses = makeStyles((theme) => ({
  root: {
    textTransform: theme.products.price.textTransform,
  },
}));

const useTabStyles = makeStyles((theme) => ({
  root: {
    minWidth: 50,
    border: '1px solid',
    color: theme.palette.primary.main,
    borderColor: theme.palette.secondary.dark,
    borderRadius: theme.products.categoryButtom.shape.borderRadius,
    margin: '0 8px 0 8px',
    minHeight: 32,
    height: 32,
    textTransform: theme.products.categoryButtom.textTransform,
    padding: '8px 16px',
    fontSize: 12,
    lineHeight: 16,
    '@media (orientation: portrait)': {
      margin: '0 5px 0 5px',
    },
  },
  selected: {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main,
    border: '1px solid ',
    borderColor: theme.palette.primary.main,
    fontSize: 12,
    fontWeight: 100,
    lineHeight: 16,
    padding: '8px 16px',
    minWidth: 50,
    minHeight: 32,
  },
}));

const arrowStyles = makeStyles({
  root: {
    '& .leftArrow': {
      'padding-right': '15px',
    },
    '& .rightArrow': {
      'padding-left': '15px',
    },
  },
});

const productSearchGridStyles = makeStyles({
  root: {
    width: 482,
    marginTop: 0,
    '@media (orientation: portrait)': {
      width: 373,
      marginTop: 15,
      marginBottom: 5,
    },
  },
});
const useFilterProductGridStyles = makeStyles({
  root: {
    paddingRight: 13,
    '@media (orientation: portrait)': {
      marginBottom: 18,
    },
    '@media (orientation: landscape)': {
      paddingRight: 39,
    },
  },
});

const categoryGridStyles = makeStyles({
  root: {
    height: 32,
    marginTop: 40,
    marginBottom: 40,
    '@media (orientation: landscape)': {
      maxWidth: '482px',
      marginBottom: 34,
    },
    '@media (orientation: portrait)': {
      marginTop: 15,
      marginBottom: 20,
    },
  },
});

const useBagStyles = makeStyles({
  root: {
    width: '100%',
  },
});

const useButtonStyles = (theme) => ({
  button: {
    bottom: 10,
    right: 46,
    width: 75,
    height: 40,
    margin: 0,
    padding: '12px 16px',
    borderRadius: '100px',
    position: 'absolute',
    backgroundColor: theme.palette.secondary.light,
    opacity: '80%',
    border: `1px solid ${theme.palette.secondary.dark}`,
    '@media (max-width: 480px)': {
      left: '73%',
      position: 'fixed',
    },
    '& .MuiIconButton-label': {
      flexDirection: 'row-reverse',
      padding: 0,
      fontSize: '12px',
    },
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
  },
  label: {
    fontSize: 12,
    lineHeight: '16px',
    color: theme.palette.primary.main,
  },
  endIcon: {
    marginLeft: -5,
    height: 14,
    width: 14,
  },
});

export default {
  useGridStyles,
  useFormattedPricesClasses,
  useProductStyles,
  useTabStyles,
  useFilterProductGridStyles,
  arrowStyles,
  productSearchGridStyles,
  categoryGridStyles,
  useBagStyles,
  useBagGridStyles,
  useGridListStyles,
  useButtonStyles,
  errorTextStyles,
  useErroTextContainerStyles,
};
