const extractUser = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch {
    return undefined;
  }
};

const decodeLogin = () => (localStorage.getItem('CurUser') ? extractUser(localStorage.getItem('CurUser')) : undefined);

const logoff = () => {
  localStorage.removeItem('CurUser');
  return Promise.resolve(true);
};

const authFetch = async (path, method = 'GET', data) => {
  const response = await fetch(`${path}`, {
    body: data ? JSON.stringify(data) : undefined,
    method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('CurUser')}`,
    },
    redirect: 'follow',
  });

  if (response.status === 403) {
    logoff();
    // eslint-disable-next-line no-restricted-globals
    location.assign('/');
    return Promise.reject(new Error('invalid login'));
  }

  return Promise.resolve(response.ok);
};

export { authFetch, decodeLogin, extractUser, logoff };
