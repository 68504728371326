export class Heart {
  constructor(reactionButtonRef, isMobile, color = 'red') {
    this.scale = (isMobile ? 0.25 : 0.4) + Math.random() * 0.1;
    this.speed = (isMobile ? 1 : 1.5) + Math.random() * 0.5;
    this.targetRef = reactionButtonRef.current;
    this.horizontalMove = Math.random() * 360;
    this.radius = Math.random() * 1;
    this.isMobile = isMobile;
    this.wasAnimated = false;
    this.opacity = 1.5;
    this.grow = 0.01;
    this.x = 0;
    this.y = 0;

    this.outerDiv = document.createElement('div');
    this.outerDiv.className = 'heart-outer';

    this.innerDiv = document.createElement('div');
    this.innerDiv.className = 'heart-inner';
    this.innerDiv.style.backgroundColor = color;

    this.outerDiv.appendChild(this.innerDiv);
    this.targetRef.appendChild(this.outerDiv);
  }

  remove() {
    if (this.targetRef.contains(this.outerDiv)) {
      this.targetRef.removeChild(this.outerDiv);
    }

    this.outerDiv = null;
    this.innerDiv = null;
  }

  draw() {
    if (this.wasAnimated) return;
    this.outerDiv.style.transform = `translateX( ${this.x}px ) translateY( ${this.y}px ) translateZ( 0 ) scale( ${this.grow} )`;
    this.outerDiv.style.opacity = this.opacity;
  }

  update() {
    this.opacity -= this.isMobile ? 0.02 : 0.008;
    this.x += Math.cos(this.horizontalMove / 50) * this.radius;
    this.y -= this.speed;

    this.grow += (this.scale - this.grow) / 10;
    this.horizontalMove += 1;

    this.wasAnimated = this.opacity <= 0;
  }
}
