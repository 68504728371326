import { makeStyles } from '@material-ui/core';

const useEmptyBoxStyle = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '14px 35px 14px 22px',
    maxHeight: '100%',
    '@media(orientation: portrait)': {
      padding: '0 24px',
    },
  },
});

const useButtomStyle = (theme) => ({
  composed: {
    cursor: 'default',
    width: '100%',
    padding: 0,
  },
  label: {
    fontSize: theme.survey.shape.fontSize,
    lineHeight: '32px',
    whiteSpace: 'nowrap',
  },
});

const useCardHeaderStyle = (theme) =>
  makeStyles({
    root: {
      padding: 0,
    },
    title: {
      lineHeight: '32px',
      fontFamily: theme.survey.textStyle.fontFamily,
    },
    subheader: {
      lineHeight: '24px',
      fontSize: '16px',
      color: theme.palette.primary.light,
      marginBottom: '24px',
    },
  })();

const useSubtextStyle = makeStyles((theme) => ({
  root: {
    marginTop: '8px',
    fontSize: theme.survey.subTextStyle.fontSize,
    lineHeight: '24px',
  },
}));

export default { useEmptyBoxStyle, useButtomStyle, useCardHeaderStyle, useSubtextStyle };
