import { makeStyles } from '@material-ui/core';

export const userSpinnerStyles = makeStyles(() => ({
  container: {
    width: '100vw',
    height: '100vh',
  },
  item: {
    border: '5px solid',
    borderColor: '#e5e5e5',
    borderTopColor: '#333333',
    borderRadius: '50%',
    width: '32px',
    height: '32px',
    animation: '$rotating-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite',
  },
  '@keyframes rotating-ring': {
    '0%': {
      transform: 'rotate(45deg)',
    },
    '100%': {
      transform: 'rotate(405deg)',
    },
  },
}));
