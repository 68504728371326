import { makeStyles } from '@material-ui/core';

const useCardStyle = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },

  boxContainer: {
    overflow: 'auto',
    margin: '27px 0px 75px 40px',
    '@media(orientation:portrait)': {
      margin: 0,
      fontSize: '14px',
      paddingTop: '23px',
      paddingBottom: '36px',
    },
  },

  container: {
    '@media(orientation:landscape)': {
      padding: '16px 25px 17px 0px!important',
    },
    '@media(orientation:portrait)': {
      margin: '13px 27px 13px 27px',
      padding: 0,
      '&:last-child': {
        paddingBottom: 0,
      },
    },
  },
  text: {
    color: theme.palette.primary.main,
    fontSize: '14px',
    lineHeight: theme.faq.lineHeightText,
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 16,
    marginBottom: '7px',
    lineHeight: theme.faq.lineHeight,
    fontWeight: theme.faq.fontWeight,
  },
  useLinkStyle: {
    color: theme.palette.primary.main,
    display: 'inline-block',
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export default {
  useCardStyle,
};
