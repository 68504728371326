import { useEvent } from '../../events/useEvent';

/**
 * Originalmente o código abaixo nos foi fornecido para 'embedar' no nosso HTML.
 * 
 * Para permitir que widget do OmniChatWeb apareça somente após o evento de entrar na stream,
 * adaptamos um pouco para que possa ser utilizado como um plugin junto ao sistema de dispatch
 * de eventos do próprio Somalive.
 *

<!-- OmniChat web-chat widget -->
  <script>
      window.omnichatConfig = {
      retailerId: "pm6mBTTjmK"
      };
  </script>
  <script defer type="text/javascript">
      !function(){var t=document.createElement("script");t.type="text/javascript",t.defer=!0,t.src="https://static.omni.chat/web-chat/web-chat.min.js",t.onload=function(){OmniChatWebChat.init(window.omnichatConfig)};var e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(t,e)}();
  </script>
<!-- OmniChat web-chat widget -->

 */

const enableOmniChatWeb = () => {
  const t = document.createElement('script');
  const e = document.getElementsByTagName('script')[0];
  window.omnichatConfig = { retailerId: 'pm6mBTTjmK' };
  t.defer = !0;
  t.type = 'text/javascript';
  t.src = 'https://static.omni.chat/web-chat/web-chat.min.js';
  t.onload = () => {
    window.OmniChatWebChat.init(window.omnichatConfig);
  };
  e.parentNode.insertBefore(t, e);
};

export default () => {
  useEvent('enableOmniChatWeb', enableOmniChatWeb);
  return null;
};
