import { makeStyles } from '@material-ui/core';

const tableRowsDimensions = {
  minWidth: '150px',
  maxWidth: '150px',
  minHeight: '49px',
  maxHeight: '49px',
};

const mobileTableRowsDimensions = {
  minWidth: '116.19px',
  maxWidth: '116.19px',
  minHeight: '49px',
  maxHeight: '49px',
};

const useTableRowStyles = {
  useMainRowStyle: makeStyles((theme) => ({
    root: {
      position: 'sticky',
      left: 0,
      zIndex: 1,
      color: theme.palette.primary.main,
      backgroundColor: '#FFFFFF',
      border: '1px solid #E5E5E5',
      overflow: 'auto',
      padding: 10,
      '@media(orientation: landscape)': {
        ...tableRowsDimensions,
      },
      '@media(orientation: portrait)': {
        ...mobileTableRowsDimensions,
      },
      height: '50px',
      lineHeight: '24px',
      fontSize: '16px',
      fontFamily: theme.products.measure.typography.fontFamily,
    },
  })),
  useGeneratedRowStyle: makeStyles((theme) => ({
    root: {
      color: theme.palette.primary.main,
      border: '1px solid #E5E5E5',
      backgroundColor: '#FFFFFF',
      padding: 10,
      '@media(orientation: landscape)': {
        ...tableRowsDimensions,
      },
      '@media(orientation: portrait)': {
        ...mobileTableRowsDimensions,
      },
      height: '50px',
      lineHeight: '24px',
      fontSize: '16px',
    },
  })),
};

const useTableHeaderStyle = {
  useMainColumnStyle: makeStyles((theme) => ({
    root: {
      textTransform: 'capitalize',
      position: 'sticky',
      left: 0,
      zIndex: 1,
      backgroundColor: '#FFFFFF',
      border: '1px solid #E5E5E5',
      color: theme.palette.primary.main,
      padding: 0,
      '@media(orientation: landscape)': {
        ...tableRowsDimensions,
      },
      '@media(orientation: portrait)': {
        ...mobileTableRowsDimensions,
        fontSize: '14px',
      },
      height: '50px',
      lineHeight: '24px',
      fontSize: '16px',
      fontFamily: theme.products.measure.typography.fontFamily,
    },
  })),
  useGeneratedColumnStyle: makeStyles((theme) => ({
    root: {
      textTransform: 'capitalize',
      border: '1px solid #E5E5E5',
      color: theme.palette.primary.main,
      backgroundColor: '#FFFFFF',
      padding: 0,
      '@media(orientation: landscape)': {
        ...tableRowsDimensions,
      },
      '@media(orientation: portrait)': {
        ...mobileTableRowsDimensions,
        fontSize: '14px',
      },
      height: '50px',
      lineHeight: '24px',
      fontSize: '16px',
      fontFamily: theme.products.measure.typography.fontFamily,
    },
  })),
};

const useButtontyles = (theme) => ({
  button: {
    padding: '0',
    height: 'auto',
    border: 'none',
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  label: {
    color: theme.palette.primary.main,
    fontSize: 12,
  },
});

const useTableStyle = makeStyles({
  root: {
    overflowX: 'auto',
    margin: '20px 0 24px 0',
    '@media(orientation: landscape)': {
      margin: '20px 0 0 0',
    },
    width: '100%',
    '@media(max-width: 431px)': {
      width: '234px!important',
    },
    '@media(orientation: portrait)': {
      width: '355px',
    },

    height: 'fit-content',
    display: 'block',
    zIndex: '0',
    '&::-webkit-scrollbar': {
      width: '100%',
      height: '5px',
    },
  },
  main: {
    borderCollapse: 'separate',
    border: '1px solid #E5E5E5',
  },
});

const useDialogActionsStyle = makeStyles({
  root: {
    height: '24px',
    padding: 0,
  },
});

const useIconButtonStyle = makeStyles({
  root: {
    width: 20,
    '@media(orientation: landscape)': {
      width: '12px',
      margin: '0 20px 0 0',
      height: '12px',
      minHeight: '12px',
      top: '20px',
      alignSelf: 'start',
    },
    margin: '0 10px 0 0',
    padding: 0,
    position: 'relative',
    top: '10px',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

const useDialogContentStyle = {
  useDialogContentStyles: makeStyles({
    root: {
      padding: '0 40px',
      marginTop: '21px',
      '@media(orientation: landscape)': {
        marginTop: '16px',
        paddingBottom: '38px',
      },
    },
  }),
  useGridStyles: makeStyles({
    root: {
      height: '100%',
      justifyContent: 'space-between',
    },
  }),
  useDialogTitleStyle: makeStyles({
    root: {
      paddingBottom: '30px',
    },
  }),
};

const useMeasureTableIconStyle = makeStyles({
  root: {
    marginRight: '13px',
    transform: 'scale(1.2)',
  },
});

const useAditionTextStyles = makeStyles({
  root: {
    fontSize: '12px',
    lineHeight: '16px',
    textAlign: 'left',
    color: '#999999',
    '@media(orientation: landscape)': {
      marginTop: '35px',
    },
  },
});

const useImageStyle = makeStyles({
  root: {
    '@media(orientation: landscape)': {
      margin: '0',
      height: '100%',
    },
    '@media(orientation: portrait)': {
      width: '100%',
      maxWidth: '353px',
    },
  },
});

const useDialogContainerStyles = makeStyles((theme) => ({
  root: {
    zIndex: '1450!important',
  },
  paperWidthSm: {
    width: '100%',
    maxWidth: '898px',
    height: '544px',
    borderRadius: theme.products.measure.borderRadius,
    '@media(orientation: landscape)': {
      maxHeight: '544px',
    },
  },
}));

const useDesktopGridStyles = makeStyles({
  root: {
    margin: 'auto 0',
    maxWidth: '454px',
  },
});

export default {
  useTableRowStyles,
  useTableStyle,
  useDialogActionsStyle,
  useIconButtonStyle,
  useDialogContentStyle,
  useMeasureTableIconStyle,
  useAditionTextStyles,
  useImageStyle,
  useTableHeaderStyle,
  useDialogContainerStyles,
  useDesktopGridStyles,
  useButtontyles,
};
