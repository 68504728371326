import { createContext } from 'react';

export const contextFnIndex = {
  subscribe: 0,
  unsubscribe: 1,
  dispatch: 2,
};

export default createContext([
  () => {}, // subscribe
  () => {}, // unsubscribe
  () => {}, // dispatch
]);
