import { makeStyles } from '@material-ui/core';

const useDrawerStyle = ({ width, padding, left, borderRadius, maxHeight, ...propsStyles }) =>
  makeStyles({
    root: {
      width,
      padding,
      borderRadius,
      maxHeight,
      left,
      overflowY: 'auto',
      '&::scrollbar': {
        '@media (orientation: portrait)': {
          display: 'none',
        },
      },
      '&::-webkit-scrollbar': {
        '@media (orientation: portrait)': {
          display: 'none',
        },
      },
      ...propsStyles,
    },
  })();

const useHeaderDrawerStyle = ({ type }) =>
  makeStyles((theme) => ({
    root: {
      maxHeight: 24,
      marginBottom: type === 'productList' ? 8 : 0,

      '@media (orientation: portrait)': {
        backgroundColor: theme.palette.secondary.main,
        position: 'sticky',
        maxHeight: 44,
        zIndex: 1000,
        top: 0,
        padding: 5,
      },
    },
  }))();

const useGridStyles = ({ ...gridStyles }) =>
  makeStyles(() => ({
    root: { ...gridStyles },
  }))();

export { useDrawerStyle, useHeaderDrawerStyle, useGridStyles };
