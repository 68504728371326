/* eslint-disable camelcase */
import React, { useState } from 'react';
import { useStreamContext } from '../contexts/streamContext';
import GoogleTagManager from './googleTagManager';
import LGPD from './LGPD';
import Logger from './logger';
import OmniChatWeb from './omniChatWeb';

export default (props) => {
  const { streamContent } = useStreamContext();

  const { config, showLGPD } = props;

  const { debug } = config;
  const { brand, stream_name } = streamContent;

  const useSomaliveGTM = config.googleTagManager.active;

  const localstorageLGPDkey = `LGPD`;
  const [cookiesAccepted, setCookiesAccepted] = useState(!!localStorage.getItem(localstorageLGPDkey));
  const setAcceptation = (value) => {
    setCookiesAccepted(value);
    localStorage.setItem(localstorageLGPDkey, true);
  };

  return (
    <>
      <Logger debug={debug} />
      <OmniChatWeb />
      {useSomaliveGTM && (
        <GoogleTagManager
          brand={brand.name}
          streamName={stream_name}
          somaLiveGtmId={config.googleTagManager.id}
          useBrandGTM={useSomaliveGTM}
        />
      )}

      {!cookiesAccepted && showLGPD && <LGPD setAcceptation={setAcceptation} />}
    </>
  );
};
