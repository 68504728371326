import { ptBR } from '@material-ui/core/locale';
import { themeTemplate, colorTransform } from './somalive';
import config from '../config';

const welcomeBackgroundDesktop = `${config.assetsUrl}/crisbarros/limited/background.jpg`;
const welcomeBackgroundMobile = `${config.assetsUrl}/crisbarros/limited/background.jpg`;
const welcomeIcon = `${config.assetsUrl}/crisbarros/limited/logotipo.png`;
const faqIcon = `${config.assetsUrl}/crisbarros/limited/icon/ajuda.png`;
const bagIcon = `${config.assetsUrl}/crisbarros/limited/icon/sacola.png`;
const productIcon = `${config.assetsUrl}/crisbarros/limited/icon/produtos.png`;
const chatIcon = `${config.assetsUrl}/crisbarros/limited/icon/chat.png`;
const couponImage = `${config.assetsUrl}/crisbarros/limited/cupom.png`;
const saleswomanCouponImage = `${config.assetsUrl}/crisbarros/limited/cupom-vendedora.png`;

const colorIntensity = 50;
const template = themeTemplate({
  mainColor: '#000000',
  secondaryColor: '#E8B46B',
  fontFamily: 'Futura',
  badgeBgColor: '#E8B46B',
  badgeColor: 'white',
  buttonsBorderRadius: 20,
});

const main = {
  palette: {
    primary: colorTransform(template.mainColor, colorIntensity),
    secondary: colorTransform(template.secondaryColor, colorIntensity),
  },
  typography: {
    fontFamily: template.fontFamily,
  },
  DialogTitle: {
    fontFamily: template.fontFamily,
  },
  overrides: {
    MuiTypography: {
      root: {
        fontFamily: template.fontFamily,
        color: template.mainColor,
      },
    },
    MuiButton: {
      root: {
        borderRadius: template.buttonsBorderRadius,
      },
    },
    MuiAlert: {
      root: {
        color: template.mainColor,
      },
      standardSuccess: {
        color: template.mainColor,
        backgroundColor: '#fff',
        '& $icon': {
          color: template.mainColor,
        },
      },
      standardInfo: {
        color: template.mainColor,
        backgroundColor: '#fff',
        '& $icon': {
          color: template.mainColor,
        },
      },
    },
  },
  faq: {
    text: 'Ajuda',
    icon: {
      src: faqIcon,
      alt: 'Ajuda',
    },
    content: [
      {
        title: 'O que é a Loja Live Cris Barros?',
        description: `É uma live solidária de lançamento e venda exclusiva da linha Limited Edition Recycling-Coleção AW21-ENTRE CÉU E TERRA, com Thai Bufrem & Cris Barros.`,
      },
      {
        title: 'Posso tirar minhas dúvidas?',
        description: `Sim! Você pode interagir ou enviar uma mensagem ao vivo pelo ícone "chat"`,
      },
      {
        title: 'Como vejo os produtos apresentados?',
        description: `Clique no ícone "produtos" no canto esquerdo da tela e navegue pelas categorias pra melhor visualização.`,
      },
      {
        title: 'Posso comprar em tempo real?',
        description: `Sim! Escolha o produto, clique em "adicionar à sacola" e em seguida selecione o seu tamanho. O produto será imediatamente adicionado à sacola, que está localizada no canto superior direito da tela.`,
      },
      {
        title: 'Como finalizo a compra?',
        description: `Clique no ícone "sacola" no canto superior direito da tela e depois em "finalizar compra". A gente vai te direcionar pro nosso site pra efetuar o pagamento de forma rápida e segura.`,
      },
    ],
  },
  bag: {
    text: 'Sacola',
    emptyBag: 'Sua sacola está vazia',
    emptyBagColor: 'secondary',
    icon: {
      src: bagIcon,
      alt: 'Sacola',
    },
  },
  products: {
    text: 'Produtos',
    icon: {
      src: productIcon,
      alt: 'Produtos',
    },
    size: {
      text: 'Selecione o Tamanho:',
      typography: {
        variant: 'subtitle1',
      },
    },
    details: {
      text: 'Detalhes do Produto',
      button: {
        variant: 'text',
        fullWidth: false,
      },
    },
    button: {
      text: 'ADICIONAR À SACOLA',
      variant: 'outlined',
      color: 'secondary',
    },
    composition: {
      text: 'Composição',
    },
    name: {
      color: 'primary',
    },
    price: {
      sale: {
        color: 'primary',
      },
    },
    quantity: {
      variant: 'subtitle1',
    },
    notAvaiable: {
      text: 'Produto Indisponível',
    },
    search: {
      notFound: 'Produto não encontrado',
      emptyBrand: 'É necessário fornecer uma brand para pesquisar o produto',
    },
  },
  welcomePage: {
    paper: {
      backgroundImageDesktop: `url(${welcomeBackgroundDesktop})`,
      backgroundImageMobile: `url(${welcomeBackgroundMobile})`,
    },
    container: {
      justify: 'center',
      header: {
        maxHeight: '30%',
        paddingBotton: 0,
        paddingTop: 0,
        maxWidthMobile: '60%',
        maxWidthDesktop: '20%',
        welcomeIcon: {
          src: welcomeIcon,
          alt: 'crisbarros-welcome-background',
        },
      },
      content: {
        maxHeight: '50%',
        boxContainerMaxWidth: 700,
        paddingTop: 20,
        paddingBottom: 20,
        main: {
          text: `REPRISE DA LIVE SOLIDÁRIA DE LANÇAMENTO E VENDA DA LINHA LIMITED EDITION RECYCLING | COLEÇÃO AW21 – ENTRE CÉU E TERRA`,
        },
        additional: {
          fontSize: 16,
          text: [
            `COM THAI BUFREM & CRIS BARROS`,
            `5% da venda durante a reprise da live serão revertidos para apoiar a ONG Casa do Rio da Amazônia.`,
          ],
        },
        footer: {
          text: 'DISPONÍVEL ATÉ DIA 25 DE ABRIL',
          fontSize: 28,
        },
      },
      callToAction: {
        maxHeight: '20%',
        button: {
          text: 'CLIQUE AQUI PARA INGRESSAR NA REPRISE',
          color: '#FFF',
          textColor: '#000000',
          variant: 'contained',
        },
      },
    },
  },
  checkout: {
    baseUrl: 'https://www.crisbarros.com.br',
    path: '/checkout/cart/add',
    button: {
      text: 'FINALIZAR COMPRA',
      variant: 'contained',
      color: 'secondary',
    },
    discountText: 'Outros Descontos',
    totalText: 'Total',
    couponText: 'CUPOM DE 15% OFF',
    subtotalText: 'Subtotal',
    couponImage,
    saleswomanCouponImage,
  },
  chat: {
    active: false,
    text: 'Chat',
    icon: {
      src: chatIcon,
      alt: 'Chat',
    },
    mainBackgroundColor: template.mainColor,
    headerTextColor: '#FFF',
    headerText: 'Chat!',
    setNameText: `seja bem-vinda ao nosso atendimento! para começar digite o seu e-mail abaixo.`,
    buttonTextTheme: 'primary',
    setUserNameButtonText: 'Enviar',
    colorTextChatPortrait: '#64473F',
    mainColor: template.mainColor,
    headerBackgroundColor: template.mainColor,
    tooltipEmailText: 'E-mail',
    tooltipMessageText: 'Mensagem',
    emptyMessageErrorText: 'Por favor insira uma mensagem!',
    messageSuccessText: 'Mensagem enviada com sucesso!',
    emptyNameErrorText: 'Por favor insira um nome!',
  },
  drawer: {
    header: {
      button: {
        size: 'small',
      },
    },
    padding: 10,
    width: '40%',
  },
  somaNavigation: {
    appBar: {
      position: 'static',
      elevation: 0,
      color: 'transparent',
    },
    tab: {
      textColor: 'primary',
      variant: 'fullWidth',
      indicatorColor: 'primary',
    },
    quantity: {
      box: {
        style: {
          display: 'flex',
          alignItems: 'center',
        },
      },
      button: {
        style: {
          minWidth: 50,
          fontSize: 18,
          margin: 5,
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
      variant: 'subtitle1',
    },
  },
  realtimeProduct: {
    styles: {
      padding: 10,
    },
    title: 'Apresentando:',
    titleVariant: 'subtitle2',
  },
  i18n: {
    locale: 'pt-BR',
    currency: 'BRL',
  },
  operator: {},
  system: {
    messages: {
      lgpd: {
        button: {
          variant: 'outlined',
          style: {
            backgroundColor: 'transparent',
            color: '#000',
            border: '1px solid',
          },
        },
        box: { background: 'rgba(255,255,255, 0.8)' },
        text: {
          style: { color: '#000' },
          content: [
            {
              value:
                'Utilizamos cookies para personalizar e melhorar sua experiência em nosso site. Ao clicar em prosseguir, você concorda com a nossa ',
              type: 'text',
            },
            {
              value: 'Política de Privacidade.',
              type: 'link',
              href: 'https://www.crisbarros.com.br/institucional/prazo-de-entrega#privacidade',
              title: 'Política de privacidade',
            },
            {
              value: '',
              type: 'text',
            },
            {
              buttonText: 'PROSSEGUIR',
              type: 'text',
            },
          ],
        },
      },
    },
  },
};

export default { ...main, ...ptBR };

export { themeTemplate, colorTransform };
