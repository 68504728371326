import React from 'react';
import { IconButton, TextField, useTheme, InputAdornment, Typography } from '@material-ui/core';
import { get, debounce } from 'lodash';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import {
  useSearchFieldStyles,
  useFormHelperTextStyles,
  useInputStyles,
  closeIconStyle,
  iconButtonStyles,
  useSearchIconStyles,
} from './styles';

import { useProductPanelContext } from '../../../contexts/productPanelContext';
import { somaEvents, useEventDispatch } from '../../../events';

const ProductSearch = (props) => {
  const { allProducts, debounceTime } = props;
  const theme = useTheme();

  const searchTitle = get(theme, 'products.search.title', 'Pesquisar produtos');
  const {
    productsPanelState,
    setProductsFiltered: sendSearch,
    setHelperText,
    clearFilter,
    setSearchText: setTextValue,
  } = useProductPanelContext();
  const { searchText: textValue } = productsPanelState;

  const searchFieldClasses = useSearchFieldStyles();
  const formHelperTextClasses = useFormHelperTextStyles(theme);
  const innerInputClasses = useInputStyles();
  const closeIconClasses = closeIconStyle();
  const inputButtonClasses = iconButtonStyles();
  const SearchIconClasses = useSearchIconStyles();

  const dispatchEvent = useEventDispatch();

  const filterProducts = debounce((searchedProduct) => {
    clearFilter();
    if (searchedProduct) {
      const uniqueProducts = new Map();
      const searchRegex = new RegExp(searchedProduct, 'i');

      allProducts.forEach((product) => {
        const { productId, reference } = product.provider;
        const { productName } = product;

        if (searchRegex.test(productName) || searchRegex.test(reference)) {
          uniqueProducts.set(productId, product);
        }
      });

      sendSearch([...uniqueProducts.values()]);

      const updateHelperText = uniqueProducts.size === 0 ? theme.productSearch.errorText : '';

      setHelperText(updateHelperText, uniqueProducts.size === 0);
    } else {
      sendSearch(null);
      setHelperText(null, false);
    }
  }, debounceTime);

  const clearSearchText = () => {
    setTextValue('');
    sendSearch(null);
    setHelperText(null, false);
  };

  const textFieldHandleChange = (e) => {
    const searchedProduct = e.target.value;
    setTextValue(searchedProduct);
    if (!searchedProduct.length) clearSearchText();
    filterProducts(searchedProduct);
  };

  const hideKeyboardOnSearch = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.target.blur();
    }
  };

  return (
    <TextField
      onKeyPress={hideKeyboardOnSearch}
      placeholder={searchTitle}
      variant="outlined"
      size="small"
      fullWidth
      type="search"
      FormHelperTextProps={{
        classes: formHelperTextClasses,
      }}
      value={textValue}
      onChange={textFieldHandleChange}
      onClick={() => dispatchEvent(somaEvents.onProductsSearched)}
      classes={searchFieldClasses}
      InputProps={{
        classes: innerInputClasses,
        endAdornment: (
          <InputAdornment position="end">
            {textValue ? (
              <IconButton onClick={clearSearchText} classes={inputButtonClasses} disableRipple>
                <CloseIcon classes={closeIconClasses} />
              </IconButton>
            ) : (
              <Typography classes={SearchIconClasses} />
            )}
          </InputAdornment>
        ),
      }}
    />
  );
};

ProductSearch.defaultProps = {
  debounceTime: 400,
};

ProductSearch.propTypes = {
  debounceTime: PropTypes.number,
};

export default ProductSearch;
