import { Grid, Paper, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { somaEvents } from 'events';
import PropTypes from 'prop-types';
import { useStreamContext } from '../../contexts/streamContext';

import { EmailField, Countdown } from '../molecules';
import Styles from './home.style';

const HomeTemplate = (props) => {
  const { children, streamEmailRequired, inputStyleProps, onClick, liveActive, justify, email, sendEmail } = props;

  const theme = useTheme();

  const portrait = useMediaQuery('(orientation: portrait)');
  const isMobilePortrait = useMediaQuery('(max-width:480px)');
  const isMobileLandscape = useMediaQuery('(max-width:926px)');
  const isDesktop = useMediaQuery('(min-width:1024px)');

  const { container: welcomeContainer, paper } = theme.welcomePage;
  const {
    usePaperStyle,
    useContainerStyle,
    useCallToActionPortraitStyles,
    useCallToActionLandscapeStyles,
    useCallToActionDesktopStyles,
  } = Styles({ portrait, paper, liveActive });

  const paperClasses = usePaperStyle();
  const containerClasses = useContainerStyle();
  const callToActionClassesPortrait = useCallToActionPortraitStyles();
  const callToActionClassesLandscape = useCallToActionLandscapeStyles();
  const callToActionClassesDesktop = useCallToActionDesktopStyles();

  const { streamContent } = useStreamContext();
  const { isRerun } = streamContent.video;

  const [currentClass, setCurrentClass] = useState();

  useEffect(() => {
    if (isMobilePortrait) {
      setCurrentClass(callToActionClassesPortrait);
      return;
    }
    if (isMobileLandscape) {
      setCurrentClass(callToActionClassesLandscape);
      return;
    }
    if (isDesktop) {
      setCurrentClass(callToActionClassesDesktop);
    }
  }, [
    callToActionClassesPortrait,
    callToActionClassesLandscape,
    callToActionClassesDesktop,
    isMobilePortrait,
    isMobileLandscape,
    isDesktop,
  ]);

  const WelcomeButton = () => children;

  const hasLiveActiveButton = () => {
    if (liveActive) {
      return (
        <Grid item classes={currentClass}>
          <WelcomeButton onClick={onClick} />
        </Grid>
      );
    }
    return null;
  };

  const timer = () => {
    if (!liveActive) {
      return (
        <Grid container spacing={2} justify="center" align="center">
          <Countdown />
        </Grid>
      );
    }
    return null;
  };

  return (
    <Grid item xs={12}>
      <Paper elevation={0} onClick={liveActive ? children.props.buttonProps.onClick : null} classes={paperClasses}>
        <Grid container justify={welcomeContainer.justify ?? justify} classes={containerClasses} direction="column">
          <Grid container classes={currentClass} justify="center">
            {liveActive && (
              <>
                {streamEmailRequired === 'input' && (
                  <Grid item>
                    <EmailField
                      themeStyles={inputStyleProps}
                      sendEmail={sendEmail}
                      onSuccess={() => {
                        onClick();
                        dispatchEvent(somaEvents.onEnter, { email });
                      }}
                      email={email}
                    />
                  </Grid>
                )}
              </>
            )}
            {hasLiveActiveButton()}
            {isRerun === 0 && timer()}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

HomeTemplate.defaultProps = {
  justify: 'flex-start',
  liveActive: true,
  onClick: () => {},
};
HomeTemplate.propTypes = {
  justify: PropTypes.oneOf(['space-between', 'center', 'space-evenly', 'space-around', 'flex-start', 'flex-end']),
  liveActive: PropTypes.bool,
  onClick: PropTypes.func,
};
export default HomeTemplate;
