import React from 'react';
import { Grid, Typography, useTheme } from '@material-ui/core';
import config from '../../../config';
import { useCountdown } from '../../../hooks/useCountdown';
import Styles from './styles';

const CountdownItem = (props) => {
  const { label, value } = props;
  const theme = useTheme();

  const countdownValueClasses = Styles.useCountdownValueStyles();
  const countdownLabelClasses = Styles.useCountdownLabelStyles();
  const wrapperItemClasses = Styles.useWrapperItemStyles();

  return (
    <Grid classes={wrapperItemClasses} item xs={2}>
      <Typography color={theme.palette.primary.main} component="span" variant="h4" classes={countdownValueClasses}>
        {value}

        <Typography
          color={theme.palette.primary.main}
          component="p"
          variant="subtitle2"
          classes={countdownLabelClasses}
        >
          {label}
        </Typography>
      </Typography>
    </Grid>
  );
};

const CountdownSeparator = () => {
  const separatorClasses = Styles.useSeparatorStyles();
  const theme = useTheme();

  return (
    <Grid item xs={1} classes={separatorClasses}>
      <Typography color={theme.palette.primary.main} component="span" variant="h4" align="center">
        :
      </Typography>
    </Grid>
  );
};

const CountDown = () => {
  const countDownClasses = Styles.useCountDownStyles();
  const countdowGridClasses = Styles.useCountdownGridStyles();
  const [days, hours, minutes, seconds] = useCountdown(config.countDown);
  const liveTimeOut = days > -1 && hours > -1 && minutes > -1 && seconds > -1;
  const timeOut = !!liveTimeOut;

  return (
    <>
      {timeOut && (
        <Grid classes={countdowGridClasses}>
          <Grid classes={countDownClasses}>
            <CountdownItem label="dias" value={days} />
            <CountdownSeparator />
            <CountdownItem label="horas" value={hours} />
            <CountdownSeparator />
            <CountdownItem label="minutos" value={minutes} />
            <CountdownSeparator />
            <CountdownItem label="segundos" value={seconds} />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default CountDown;
