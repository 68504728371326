import React, { useState } from 'react';
import { Typography, Link, Box, useTheme, Grid } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { useWrapperStyle, useTextStyle, useLinkStyle, useButtonAcceptStyle, useButtonGridStyle } from './styles';
import { TextButton } from '../../components/molecules/SomaClickable';

const LGPD = ({ setAcceptation }) => {
  const [transform, setTransform] = useState('translateY(0)');
  const portrait = useMediaQuery('(orientation: portrait)');
  const theme = useTheme();

  const wrapperStyle = useWrapperStyle();
  const textStyle = useTextStyle();
  const linkStyle = useLinkStyle();
  const buttonAcceptClasses = useButtonAcceptStyle(theme, portrait);
  const buttonGridStyle = useButtonGridStyle();

  const handleAcceptation = () => {
    setTransform('translateY(100%)');
    setTimeout(() => setAcceptation(true), 200);
  };

  const getButtonLabel = () => {
    const label = theme.system.messages.lgpd.text.content.find((item) => item.buttonText).buttonText;
    return label || 'aceitar cookies';
  };

  return (
    <Box classes={wrapperStyle} style={{ transform }}>
      <Typography classes={textStyle}>
        {theme.system.messages.lgpd.text.content.map((item) => {
          if (item.type === 'text') {
            return item.value;
          }
          if (item.type === 'link') {
            return (
              <Link
                href={item.href}
                underline="always"
                target="_blank"
                title={item.title}
                classes={linkStyle}
                key={item.value}
              >
                {item.value}
              </Link>
            );
          }
          return null;
        })}
      </Typography>
      <Grid classes={buttonGridStyle}>
        <TextButton
          label={getButtonLabel()}
          buttonProps={{ onClick: handleAcceptation }}
          buttonStyles={{ ...buttonAcceptClasses.button }}
          labelStyles={{ ...buttonAcceptClasses.label }}
        />
      </Grid>
    </Box>
  );
};

export default LGPD;
