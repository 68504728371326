import { makeStyles } from '@material-ui/core';

const useDialogStyles = makeStyles(() => ({
  root: {
    zIndex: '1450!important',
  },
  paperWidthSm: {
    maxWidth: '898px',
    position: 'relative',
    borderRadius: '10px',
    padding: '24px 40px 48px',

    '@media(orientation: landscape)': {
      maxHeight: '544px',
    },
  },
}));

const useDrawerStyles = () => ({
  borderRadius: '16px 16px 0 0',
  maxHeight: '95%',
  width: '100%',
  padding: 0,
  left: 0,
});

const useCloseButtonStyles = makeStyles({
  root: {
    backgroundColor: 'transparent',
    position: 'absolute',
    boxShadow: 'none',
    padding: 0,
    width: 20,
    right: 40,

    '&:hover': {
      backgroundColor: 'transparent',
    },

    '@media(orientation: landscape)': {
      alignSelf: 'start',
      height: 40,
      width: 40,
    },
  },
});

const useContainerStyles = makeStyles(() => ({
  root: {
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    display: 'flex',
    padding: '0px ',
    maxWidth: 457,

    '@media(orientation: portrait)': {
      padding: '0px 46px 72px',
    },

    '& img': {
      margin: '32px 0 24px',
    },

    '& h1': {
      fontSize: 24,
      lineHeight: '32px',
      marginBottom: 16,
    },

    '& > p': {
      fontSize: 14,
      lineHeight: '16px',
    },

    '& span': {
      fontWeight: 'bold',
    },
  },
}));

const useButtonStyles = () => ({
  button: {
    padding: '12px 16px',
    whiteSpace: 'nowrap',
    marginTop: 24,
    height: 40,
    width: 135,
  },
  label: {
    lineHeight: '16px',
    fontSize: '12px',
  },
});

export default { useCloseButtonStyles, useDialogStyles, useContainerStyles, useButtonStyles, useDrawerStyles };
