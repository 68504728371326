import { makeStyles } from '@material-ui/core';

const useCarouselStylesWithProps = ({ hasContentEnoughToScroll }) =>
  makeStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
      flexWrap: 'nowrap',
      flexDirection: 'row',
      width: '402px',
      '@media(orientation: portrait)': {
        width: '100%',
      },
    },
    scrollable: {
      display: 'flex',
      overflow: !hasContentEnoughToScroll ? 'hidden' : 'auto',
      '-webkit-overflow-scrolling': 'touch',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      height: '93px',
      flexDirection: 'row',
      width: 'max-content',
      '@media(orientation: portrait)': {
        height: 'auto',
      },
    },
    imageItem: {
      padding: 0,
      '&:hover': {
        backgroundColor: '#fff',
      },
      opacity: 0.7,
      width: '30%',
      margin: '0 10px 0 0',
      height: 'unset',
      cursor: 'pointer',

      '@media(orientation: portrait)': {
        width: '100%',
        minHeight: '150vw',
        minWidth: '100vw',
        margin: 0,
        opacity: 1,
      },
    },
    paper: {
      height: 178,
      width: '100%',
      position: 'absolute',
      bottom: 0,
      borderRadius: 0,
      background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%)',
      pointerEvents: 'none',

      '@media(orientation: landscape)': {
        display: 'none',
      },
    },
  });

const useArrowStyles = makeStyles((theme) => ({
  root: {
    width: '28px',
    height: '20px',
    maxWidth: '28px',
    maxHeight: '18px',
    padding: 0,
    color: theme.palette.primary.main,
  },
  previous: {
    '&:active': {
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
    margin: '0 18px 0 0',
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
  next: {
    '&:active': {
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
    margin: '0 0 0 18px',
    transform: 'rotate(180deg)',
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
}));

const indicatorIconButtonProps = {
  style: {
    backgroundColor: '#E5E5E5',
    width: '4px',
    height: '4px',
    borderRadius: '4px',
    zIndex: 100,
  },
};
const activeIndicatorIconButtonProps = {
  style: {
    backgroundColor: '#FFF',
    width: '24px',
    height: '4px',
    borderRadius: '4px',
  },
};
const indicatorContainerProps = {
  style: {
    margin: 0,
    position: 'relative',
    top: -120,
    left: 3,
    marginLeft: '20px',
    textAlign: 'left',
    height: '4px',
    display: 'flex',
    columnGap: '8px',
  },
};

export {
  useCarouselStylesWithProps,
  useArrowStyles,
  indicatorIconButtonProps,
  activeIndicatorIconButtonProps,
  indicatorContainerProps,
};
