import React, { useEffect, useState, useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@material-ui/core';
import Product from '../Product';
import Styles from './styles';

const SomaGridList = (props) => {
  const { cols, products: allProducts, isShowcasing, category, isBag = false, scrollTarget, ...otherArgs } = props;
  const isOperation = !!otherArgs.user;
  const [searchedProducts, setProdutos] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [scrollPage, setScrollPage] = useState(1);
  const gridListClasses = Styles.useGridStyles();
  const bagGridListClasses = Styles.useBagGridStyles();
  const productClasses = Styles.useProductStyles();
  const bagClasses = Styles.useBagStyles();
  const portrait = useMediaQuery('(orientation: portrait)');

  function inheritedPropsAdapt(iProps) {
    if (iProps.user) {
      return { ...iProps };
    }
    return iProps;
  }

  const inheritedProps = inheritedPropsAdapt(otherArgs);

  const loadMore = useCallback(
    (page) => {
      const numberOfProductsToRender = page * 4;
      const updatedProductsList = allProducts.slice(0, numberOfProductsToRender);
      setProdutos(updatedProductsList);

      if (allProducts.length === updatedProductsList.length) {
        setHasMore(false);
      }
    },
    [allProducts]
  );

  useEffect(() => {
    if (isBag || isShowcasing) {
      loadMore(Infinity);
    }
  }, [isBag, loadMore, isShowcasing]);

  useEffect(() => {
    setHasMore(true);
    loadMore(scrollPage);
  }, [allProducts, loadMore, scrollPage]);

  return (
    <InfiniteScroll
      pageStart={0}
      loadMore={setScrollPage}
      hasMore={hasMore}
      loader={
        <div key="loading">
          <span>Loading ...</span>
        </div>
      }
      useWindow={false}
      getScrollParent={() => scrollTarget?.current}
    >
      <GridList
        cellHeight="auto"
        spacing={portrait ? 6 : 0}
        classes={isBag ? bagGridListClasses : gridListClasses}
        cols={cols}
      >
        {searchedProducts.map((product) => (
          <GridListTile
            key={product.bagInfo?.uuid || product.id || product.provider.productId}
            classes={isBag ? bagClasses : productClasses}
          >
            <Product
              category={category}
              product={product}
              {...inheritedProps}
              isOperation={isOperation}
              isShowcasing={isShowcasing}
              isBag={isBag}
              isMobileShowCasing={!!isShowcasing}
            />
          </GridListTile>
        ))}
      </GridList>
    </InfiniteScroll>
  );
};

SomaGridList.defaultProps = {
  cols: 1,
};

SomaGridList.propTypes = {
  cols: PropTypes.number,
};

export default SomaGridList;
