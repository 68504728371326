import { makeStyles } from '@material-ui/core';

const useCardHeaderStyle = (theme) =>
  makeStyles({
    root: {
      padding: 0,
    },
    title: {
      fontSize: '16px',
      lineHeight: '24px',
    },
    subheader: {
      fontSize: '14px',
      lineHeight: '16px',
      marginBottom: '18px',
      color: theme.palette.primary.light,
    },
  })();

const useCardStyle = ({ theme, isAnswered }) =>
  makeStyles({
    root: {
      background: isAnswered
        ? `linear-gradient(to bottom, ${theme.palette.primary.main} 0px ,${theme.palette.primary.main} 34px, transparent 34px,transparent 100%)`
        : `transparent`,
      border: `2px solid ${theme.palette.secondary.light}`,
      borderRadius: theme.poll.borderRadius,
      boxShadow: 'none',
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 10,
      padding: isAnswered ? theme.survey.padding : '20px 20px 16px',
      '&:before': {
        display: !isAnswered ? 'none' : 'auto',
        color: `${theme.palette.secondary.main}`,
        content: `"${theme.survey.answeredSurvey}"`,
        textAlign: 'center',
        fontSize: '13px',
        lineHeight: '16px',
        marginBottom: '28px',
        '@media(orientation: portrait)': {
          marginLeft: '-15px',
          marginRight: '-15px',
        },
        '@media (max-width: 423px)': {
          marginTop: '-8px',
          marginBottom: '8px',
        },
      },
    },
  })();

export default { useCardHeaderStyle, useCardStyle };
