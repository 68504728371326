import React, { useRef } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Style from './styles';

export const TableComponent = ({ product }) => {
  const tableStyles = Style.useTableStyle();

  const [mainColumnsClasses, generatedColumnClasses] = [
    Style.useTableHeaderStyle.useMainColumnStyle(),
    Style.useTableHeaderStyle.useGeneratedColumnStyle(),
  ];

  const [mainRowClasses, generatedRowClasses] = [
    Style.useTableRowStyles.useMainRowStyle(),
    Style.useTableRowStyles.useGeneratedRowStyle(),
  ];

  const theme = useTheme();
  const { products } = theme;

  const columnsSetRef = useRef(new Set());

  const getMeasuresValue = product.guide.map((size) => {
    const [key, value] = Object.entries(size)[0];
    const sizeParams = value.split(',');
    const sizeParamsObj = sizeParams.map((sizeStr) => {
      const sizeTypeSplited = sizeStr.split('=');
      columnsSetRef.current.add(sizeTypeSplited[0].trim());

      return sizeTypeSplited.map((item) => item.toLowerCase().trim());
    });

    return { key, ...Object.fromEntries(sizeParamsObj) };
  });

  return (
    <div className={tableStyles.root}>
      <Table size="medium" className={tableStyles.main}>
        <TableHead>
          <TableRow>
            <TableCell align="center" classes={mainColumnsClasses}>
              {products.measure.firstColumnTitle}
            </TableCell>
            {Array.from(columnsSetRef.current).map((item) => (
              <TableCell align="center" key={item} classes={generatedColumnClasses}>
                {item.toLowerCase()}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {getMeasuresValue.map((row) => (
            <TableRow key={row.key} classes={mainRowClasses}>
              <TableCell align="center" component="th" scope="row" classes={mainRowClasses}>
                {row.key}
              </TableCell>
              {Array.from(columnsSetRef.current).map((item) => (
                <TableCell align="center" key={item} classes={generatedRowClasses}>
                  {row[item.toLowerCase()] ?? '-'}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
