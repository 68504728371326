import { makeStyles } from '@material-ui/core';

const useDialogStyle = makeStyles({
  root: {
    height: '32px',
    padding: 0,
  },
});

const useGridChatStyle = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    overflow: 'hidden',
    height: '100%',
  },
  formWrapper: {
    flex: '0 0 auto',
  },
  messagesWrapper: {
    flex: '1 1 100%',
    overflow: 'hidden',
  },
});

const useFaqStyles = makeStyles((theme) => ({
  root: {
    padding: 1,
    minWidth: 32,
    margin: '5px 0',
    color: theme.palette.primary.main,
  },
}));

const useDialogContainerStyles = makeStyles((theme) => ({
  root: {
    zIndex: '1450!important',
  },
  paperWidthSm: {
    width: '100%',
    maxWidth: '898px',
    height: '544px',
    borderRadius: theme.faq.shape.borderRadius,
    '@media(orientation: landscape)': {
      maxHeight: '544px',
    },
  },
}));

const useIconButtonStyle = makeStyles({
  root: {
    width: 20,
    '@media(orientation: landscape)': {
      width: '12px',
      margin: '0 20px 0 0',
      height: '12px',
      minHeight: '12px',
      top: '20px',
      alignSelf: 'start',
    },
    margin: '0 10px 0 0',
    padding: 0,
    position: 'relative',
    top: '10px',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

const useReactionButtonStyles = (reacted, theme) => ({
  backgroundColor: theme.palette.secondary.main,
  cursor: `${reacted ? `default` : `pointer`}`,
  height: 48,
  minWidth: 48,
  width: 48,
  opacity: 0.7,
  zIndex: 50,
  border: 'none',
  borderRadius: theme.reaction.borderRadius,

  '@media(orientation: portrait)': {
    height: 32,
    minWidth: 32,
    width: 32,

    '& span': {
      transform: `scale(0.83)`,
    },
  },

  '&::before': {
    content: `''`,
    position: 'absolute',
    zIndex: 1,
    transform: `${reacted ? `scale(1)` : `scale(0)`}`,
    transition: '.3s ease',
    width: '100%',
    height: '100%',
    backgroundImage: `url(${theme.reaction.icons.selfReactionHeart})`,
    background: 'no-repeat',
    backgroundPosition: 'center',

    '@media(orientation: portrait)': {
      transform: `${reacted ? `scale(0.5)` : `scale(0)`}`,
    },
  },
});

const usePipButtonStyles = (theme) => ({
  backgroundColor: theme.palette.secondary.main,
  borderRadius: theme.pictureInPicture.borderRadius,
  border: 'none',
  opacity: 0.7,
  minWidth: 48,
  height: 48,
  zIndex: 50,
  width: 48,

  '@media(orientation: portrait)': {
    height: 32,
    minWidth: 32,
    width: 32,
  },
});

export default {
  useDialogStyle,
  useGridChatStyle,
  useIconButtonStyle,
  useFaqStyles,
  useDialogContainerStyles,
  useReactionButtonStyles,
  usePipButtonStyles,
};
