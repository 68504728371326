import { makeStyles } from '@material-ui/core';

const useCardStyle = ({ enteredOnChat }) =>
  makeStyles((theme) => ({
    root: {
      borderTopLeftRadius: theme.chat.borderTopLeftRadius,
      borderBottomLeftRadius: theme.chat.borderBottomLeftRadius,
      borderTopRightRadius: theme.chat.borderTopRightRadius,
      borderBottomRightRadius: theme.chat.borderBottomRightRadius,
      width: theme.chat.width,
      height: enteredOnChat ? 450 : theme.chat.height,
      position: 'fixed',
      right: '0',
      bottom: '17.3%',
      maxHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      boxShadow: 'none !important',
      zIndex: 50,

      '@media (max-width: 926px) and (orientation: landscape)': {
        borderTopLeftRadius: '0 !important',
        borderTopRightRadius: '0 !important',
        width: '100%',
        height: '100%',
        right: '0',
        zIndex: '10',
      },
    },
  }))();

const useCardContentStyle = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    flex: '1 1 100%',
    overflow: 'hidden',
    padding: '0px 26px 20px 26px !important',
  },
}));

const useHeaderStyle = ({ enteredOnChat, theme }) =>
  makeStyles({
    root: {
      display: 'flex',
      height: '38px',
      padding: '14px 16px 0px 16px',
      alignItems: 'flex-end',
      boxShadow: enteredOnChat ? `10px 5px 13px 8px ${theme.palette.common.white}` : 'none',
      zIndex: 1,
    },
    action: {
      margin: 0,
    },
  })();

const useHeaderStyleMobileLandscape = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.chat.headerBackgroundColor,
    color: theme.chat.headerTextColor,
    height: 45,
    flex: '0 0 45px',
    alignItems: 'flex-start',
    padding: '11px 16px',
  },
  action: {
    marginRight: 0,
    marginTop: 0,
  },
}));

const useCloseButtonStyles = makeStyles({
  root: {
    padding: '6px 4px',
    minWidth: 'auto',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '@media(orientation: portrait)': {
      margin: '0 10px 0 0',
    },
  },
});

export default {
  useCardStyle,
  useCardContentStyle,
  useHeaderStyle,
  useHeaderStyleMobileLandscape,
  useCloseButtonStyles,
};
