import { v4 } from 'uuid';

const oneDay = 1 * 24 * 60 * 60 * 1000;
const expirationMs = oneDay;

const newStorageInstance = (id) => {
  const storageInstance = new Map();
  storageInstance.set('id', id);
  const chat = new Map();
  chat.set('privateRoom', v4());
  chat.set('messages', []);
  storageInstance.set('chat', chat);
  storageInstance.set('bag', []);
  storageInstance.set('expiresAt', new Date().setTime(new Date().getTime() + expirationMs));
  return storageInstance;
};

const persist = ({ id, storageItem }) => {
  const replacer = (key, value) => {
    if (value instanceof Map) {
      return {
        dataType: 'Map',
        value: Array.from(value.entries()),
      };
    }
    return value;
  };
  const strData = JSON.stringify(storageItem, replacer);
  window.localStorage.setItem(id, strData);
};

const isExpired = (expiresAt) => {
  if (expiresAt === undefined) return true;
  return Date.now() > expiresAt;
};

export const get = (id) => {
  try {
    const reviver = (key, value) => {
      if (typeof value === 'object' && value !== null) {
        if (value.dataType === 'Map') {
          return new Map(value.value);
        }
      }
      return value;
    };
    const storageItem = window.localStorage.getItem(id);
    if (storageItem) {
      const storeMap = JSON.parse(storageItem, reviver);
      if (!isExpired(storeMap.get('expiresAt'))) return storeMap;
    }
    return newStorageInstance(id);
  } catch {
    return newStorageInstance(id);
  }
};

export const save = (data) => {
  const id = data.get('id');
  persist({ id, storageItem: data });
};
