import { ptBR } from '@material-ui/core/locale';
import config from '../config';
import { themeTemplate, colorTransform } from './somalive';

const welcomeBackgroundDesktop = `${config.assetsUrl}/animale/images/backgroundImage.png`;
const welcomeBackgroundMobile = `${config.assetsUrl}/animale/images/backgroundImage.png`;
const welcomeIcon = `${config.assetsUrl}/farm/logotipo-novo-farm.png`;
const faqIcon = `${config.assetsUrl}/animale/icons/animale_helpIcon.svg`;
const bagIcon = `${config.assetsUrl}/animale/icons/animale_bagIcon.svg`;
const mobileBagIcon = `${config.assetsUrl}/animale/icons/sacolaSelectedIcon.svg`;
const productIcon = `${config.assetsUrl}/animale/icons/animale_productIcon.svg`;
const mobileProductIcon = `${config.assetsUrl}/animale/icons/productSelectedIcon.svg`;
const chatIcon = `${config.assetsUrl}/animale/icons/animale_boldChatIcon.svg`;
const mobileChatIcon = `${config.assetsUrl}/animale/icons/chatSelectedIcon.svg`;
const quizIcon = `${config.assetsUrl}/common/icons/poll_icon.svg`;
const onGoingIcon = `${config.assetsUrl}/common/icons/onGoingIcon.svg`;
const resultsIcon = `${config.assetsUrl}/animale/icons/animale_onGoingIcon.svg`;
const couponImage = `${config.assetsUrl}/animale/images/animale_coupom.png`;
const saleswomanCouponImage = `${config.assetsUrl}/farm/vendedor-30-01-23.png`;
const tapumeMiniplayer = `${config.assetsUrl}/farm/miniplayer-tapume.jpg`;
const measureGuideImageAnimale = `${config.assetsUrl}/animale/images/measure_guide.png`;
const measureMobileImageAnimale = `${config.assetsUrl}/animale/images/measure_guide_mobile.png`;
const emptyBagIcon = `${config.assetsUrl}/farm/icon/emptyBag.svg`;
const pageNotFound = `${config.assetsUrl}/common/error-page-image.png`;
const sendMessageIcon = `${config.assetsUrl}/common/icons/send-messag-animale.svg`;
const faviconAnimale = `${config.assetsUrl}/common/favicons/animale.png`;
const outlinedHeart = `${config.assetsUrl}/animale/icons/reactionIcon.svg`;
const selfReactionHeart = `${config.assetsUrl}/animale/icons/animale_filledHeart.svg`;
const answeredHeart = `${config.assetsUrl}/animale/icons/animale_asweredHeart.svg`;
const anchorSizeIcon = `${config.assetsUrl}/animale/icons/animale_medidaApresentadores.svg`;

const template = themeTemplate({
  mainColor: '#000000',
  secondaryColor: '#FFFFFF',
  secondaryFontFamily: 'CanelaRegular',
  fontFamily: 'SolinaBook',
  badgeBgColor: '#333333',
  badgeColor: 'white',
  buttonsBorderRadius: 0,
});

const main = {
  snackBarRadius: '0px',
  pdp: {
    mobileBorderRadius: '0',
    productNameMobileSize: '12px',
    productNameSize: '20px',
    productNameHeight: '32px',
    productNameSpacingTop: '0',
    productPriceSize: 12,
    ctaButtonWidth: '167px',
    ctaButtonHeight: '40px',
    ctaButtonRadius: '0',
    ctaButtonSize: '12px',
    ctaButtonMobileBG: template.mainColor,
    ctaButtonMobileColor: template.secondaryColor,
    ctaButtonMobileBorder: '',
    buttonSizeWidth: '64px',
    buttonSizeHeight: '40px',
    buttonSizeRadius: '0',
    buttonSizeGap: '8px',
  },
  palette: {
    primary: { main: '#000000', dark: '#222222', light: '#999999' },
    secondary: { main: '#fff', light: '#f5f5f5', dark: '#E5E5E5' },
    feedback: {
      main: '#D66157',
      light: '#FFF3F2',
      medium: '#F94F44',
      dark: '#C4A9FF',
    },
    common: {
      white: '#FFF',
    },
  },
  typography: {
    fontFamily: template.fontFamily,
  },
  quantitySelector: {
    borderRadius: '1px',
    backgroundColor: '#FFFFFF',
    border: '1px solid #E5E5E5',
  },
  DialogTitle: {
    fontFamily: template.fontFamily,
  },
  overrides: {
    MuiTypography: {
      root: {
        fontFamily: template.fontFamily,
        color: template.mainColor,
        fontWeight: 300,
      },
    },
    MuiButton: {
      root: {
        borderRadius: template.buttonsBorderRadius,
      },
    },
    MuiAlert: {
      root: {
        color: template.mainColor,
        textTransform: 'lowercase',
      },
      standardSuccess: {
        color: template.mainColor,
        backgroundColor: '#fff',
        '& $icon': {
          color: template.mainColor,
        },
      },
      standardInfo: {
        color: template.mainColor,
        backgroundColor: '#fff',
        '& $icon': {
          color: template.mainColor,
        },
      },
    },
  },

  errorOnSearch: {
    icon: {
      src: null,
    },
    errorTextPrimary: 'Ops, sua busca não foi encontrada',
    errorTextSecondary: 'Você pode tentar utilizar outro termo ou navegar através das categorias',
    fontSizeH1: '20px',
    fontSizeP: '14px',
    lineHeightP: '20px',
    marginTop: 0,
  },

  faq: {
    active: true,
    text: 'Ajuda',
    icon: {
      src: faqIcon,
      alt: 'ajuda',
    },
    shape: {
      borderRadius: 0,
    },
    content: [
      {
        title: 'O que é a live da Animale?',
        description:
          'É a nossa primeira loja virtual 100% interativa. Uma experiência única com a curadoria especial do time Animale pra você.',
      },
      {
        title: 'Posso tirar minhas dúvidas?',
        description: 'Sim! você pode interagir ou enviar uma mensagem ao vivo pelo ícone "chat"',
      },
      {
        title: 'Como vejo os produtos apresentados?',
        description:
          'Clique no ícone "produtos" no lado esquerdo da tela e navegue pelas categorias pra melhor visualização.',
      },
      {
        title: 'Posso comprar em tempo real? ',
        description:
          'Sim! escolha o produto, clique em "incluir na sacola" e em seguida selecione o seu tamanho. o produto será imediatamente adicionado à sacola, que está localizada no canto superior direito da tela.',
      },
      {
        title: 'Como finalizo minha compra?',
        description:
          'Clique no ícone "sacola" no menu à esquerda da tela e depois em "fechar compra". a gente vai te direcionar pro nosso site pra efetuar o pagamento de forma rápida e segura.',
      },
    ],
    fontWeight: 300,
    lineHeight: '20px',
    lineHeightText: '20px',
  },
  survey: {
    openModalColor: template.mainColor,
    buttonChoiceColor: template.mainColor,
    shape: {
      borderRadius: 1,
      fontSize: 20,
    },
    textStyle: {
      fontFamily: template.secondaryFontFamily,
    },
    subTextStyle: {
      fontSize: '14px',
    },
    emptySurvey: {
      title: 'Enquetes',
      subtitle: 'Queremos saber o que você acha',
      text: 'Parece que nenhuma enquete foi iniciada...',
      subtext: 'Aproveite pra explorar nossas novidades e pra interagir com a gente pelo chat',
    },
    emptyResult: {
      text: 'Parece que ainda não temos resultados',
      subtext: 'Se sim, aproveita pra explorar nossas novidades e pra interagir com a gente pelo chat',
    },
    winnerColor: '#1D5B1B',
    questionText: 'Pergunta ',
    buttonChoiceTextColor: '#fff',
    icon: {
      src: quizIcon,
      alt: 'Enquete',
    },
    emptyIcon: {
      src: '',
      alt: '',
    },
    onGoingIcon: {
      src: onGoingIcon,
      alt: 'Em andamento',
    },
    resultsIcon: {
      src: resultsIcon,
      alt: 'Resultados',
    },
  },
  poll: {
    shape: {
      borderRadius: '1px',
    },
  },
  anchorSize: {
    icon: {
      src: anchorSizeIcon,
      alt: 'medidas dos apresentadores',
    },
    style: {
      borderRadius: '1px',
      fontSize: '24px',
      fontFamily: template.secondaryFontFamily,
    },
    mobileStyle: {
      fontSize: '24px',
    },
    subtitleStyle: {
      fontSize: '14px',
    },
    titleAnchorSize: 'Medidas dos apresentadores',
    subTitleAnchorSize: 'Confira as medidas dos nossos apresentadores.',
    profiles: [
      {
        name: 'Carol Potengy',
        srcImage: `${config.assetsUrl}/farm/profile/anchor/carolpotengy.jpg`,
        size: 'P',
        bust: '92',
        waist: '70',
        hips: '102',
        height: '1,61',
      },
      {
        name: 'Daniel Medeiros​',
        srcImage: `${config.assetsUrl}/farm/profile/anchor/danielmedeiros.jpg`,
        size: 'M',
        bust: '91',
        waist: '76​',
        hips: '97',
        height: '1,70',
      },
      {
        name: 'Jéssica Louzada',
        srcImage: `${config.assetsUrl}/farm/profile/anchor/jessicalouzada.jpg`,
        size: 'P',
        bust: '88',
        waist: '73',
        hips: '109',
        height: '1,66',
      },
      {
        name: 'Júlia Polido',
        srcImage: `${config.assetsUrl}/farm/profile/anchor/juliapolido.jpg`,
        size: 'P',
        bust: '86',
        waist: '70',
        hips: '99',
        height: '1,65',
      },
      {
        name: 'Paula Missiaggia',
        srcImage: `${config.assetsUrl}/farm/profile/anchor/paulamissiaggia.jpg`,
        size: 'M',
        bust: '89',
        waist: '73',
        hips: '99',
        height: '1,76',
      },
      {
        name: 'Reysla Rocha',
        srcImage: `${config.assetsUrl}/farm/profile/anchor/reyslarocha.jpg`,
        size: 'GG',
        bust: '124',
        waist: '98',
        hips: '121',
        height: '1,59',
      },
    ],
  },
  bag: {
    active: true,
    text: 'Sacola',
    emptyBag: 'Sua sacola está vazia',
    emptyTextStyle: {
      fontFamily: template.secondaryFontFamily,
    },
    button: {
      shape: {
        marginLeft: '57px',
      },
    },
    emptyBagColor: 'secondary',
    urlSharer: {
      color: template.mainColor,
      textFirstStep: 'Compartilhar mochila',
      textSecondStep: 'Copiar link',
      background: 'default',
      buttonFontWeight: 'bold',
    },
    icon: {
      src: bagIcon,
      alt: 'sacola',
    },
    mobileIcon: {
      src: mobileBagIcon,
      alt: 'sacola',
    },
    emptyIcon: {
      src: emptyBagIcon,
      alt: 'sacola',
    },
    continueShopping: {
      borderRadius: 1,
    },
  },
  products: {
    categoryButtom: {
      shape: {
        borderRadius: 0,
      },
      textTransform: 'uppercase',
    },
    useFigureButtonStyles: {
      shape: {
        borderRadius: 0,
      },
      border: 'none',
      backgroundColor: 'transparent',
    },
    FigureButtonMobileStyle: {
      shape: {
        borderRadius: 0,
      },
      height: '40px',
      minWidth: '40px',
    },
    active: true,
    text: 'Produtos',
    icon: {
      src: productIcon,
      alt: 'produtos',
      shape: {
        borderRadius: '1px',
        backgroundColor: 'transparent',
      },
    },
    placeholder: '#626262',
    mobileIcon: {
      src: mobileProductIcon,
      alt: 'produtos',
    },
    filter: {
      buttonConfirm: {
        label: 'CONFIRMAR',
        shape: {
          borderRadius: 0,
        },
        width: '108px',
        height: '40px',
        fontSize: '12px',
      },
      buttonClearFilters: {
        label: 'LIMPAR',
        width: '77px',
        height: '40px',
      },
      sideHelper: {
        label: 'FILTRAR POR TAMANHO',
      },
      textDecoration: 'underline',
      badge: {
        textColor: '#999',
        background: '#eee',
      },
      shape: {
        borderRadius: 0,
      },
    },
    size: {
      text: 'selecione o tamanho:',
      typography: {
        variant: 'subtitle1',
      },
      shape: {
        borderRadius: '1px',
      },
    },
    details: {
      title: 'Sobre a peça',
      titleSize: '14px',
      textSize: '12px',
      descriptionSize: '12px',
      text: 'detalhes do produto',
      button: {
        variant: 'text',
        fullWidth: false,
      },
    },
    filters: {
      titles: {
        sizes: 'Filtre por tamanho',
      },
      FilterButton: {
        shape: {
          borderRadius: 0,
        },
      },
      ToggleButton: {
        minWidth: '64px',
        shape: {
          borderRadius: 0,
        },
        margin: '0px 24px',
      },
      shape: {
        borderRadius: 0,
      },
      marginRight: '35px',
    },
    measure: {
      title: 'TABELA DE MEDIDAS',
      image: measureGuideImageAnimale,
      mobileImage: measureMobileImageAnimale,
      borderRadius: 0,
      typography: {
        fontFamily: template.fontFamily,
      },
    },
    button: {
      text: 'ADICIONAR À SACOLA',
      variant: 'contained',
      color: 'primary',
      hoverBackground: '#553717',
    },
    composition: {
      text: 'composição',
    },
    name: {
      color: 'primary',
    },
    price: {
      sale: {
        color: 'primary',
      },
    },
    quantity: {
      variant: 'subtitle1',
    },
    notAvaiable: {
      text: 'produto indisponível',
    },
    search: {
      title: 'Busque em todas as categorias',
      notFound: 'produto não encontrado',
      emptyBrand: 'é necessário fornecer uma brand para pesquisar o produto',
      shape: {
        borderRadius: 0,
      },
    },
  },
  liveNotFound: {
    image: {
      src: pageNotFound,
      alt: 'Page 404',
    },
    title: 'Erro 404',
    subtitle: 'Ops, algo deu errado',
    additionalTitle: 'Por favor, verifique se o link está correto ou tente novamente em instantes.',
  },
  welcomePage: {
    paper: {
      backgroundImageDesktop: `url(${welcomeBackgroundDesktop})`,
      backgroundImageMobile: `url(${welcomeBackgroundMobile})`,
      backgroundPosition: 'center bottom',
    },
    container: {
      header: {
        maxWidthDesktop: '18%',
        maxWidthMobile: '58%',
        maxHeight: '35%',
        paddingTop: 20,
        welcomeIcon: {
          src: welcomeIcon,
          alt: 'farm-welcome-logo',
        },
      },
      content: {
        paddingTop: 20,
        paddingBottom: 20,
        paddingRight: 0,
        paddingLeft: 0,
        boxContainerMaxWidth: 650,
        main: {
          text: ['a reprise da live já tá disponível!', 'aproveita pra assistir quantas vezes quiser 🤍'],
          textAlign: 'center',
          fontSize: '1.75rem',
          fontWeight: '200',
          lineHeight: 1,
        },
        additional: {
          text: '',
          textAlign: 'center',
          fontSize: 24,
          fontWeight: '200',
          maxHeight: '',
          letterSpacing: 1,
        },
        footer: {
          text: '',
          fontSize: '1.75rem',
          fontWeight: '700',
          letterSpacing: 1,
        },
      },
      callToAction: {
        mode: 'button',
        width: 150,
        button: {
          text: 'vem assistir',
          textColor: '#333333',
          color: 'secondary',
          variant: 'contained',
          borderRadius: '100px',
          fontSize: '16px',
          maxHeight: '',
          custom: true,
          fullWidth: true,
          shape: {
            borderRadius: 0,
          },
        },
      },
    },
  },

  checkout: {
    baseUrl: 'https://www.animale.com.br',
    path: '/checkout/cart/add',
    shouldShowCoupon: false,
    installmentNumber: 12,
    installmentMinValue: 10,
    button: {
      text: 'FINALIZAR COMPRA',
      shape: {
        borderRadius: '1px',
      },
    },
    discountText: 'Outros Descontos',
    totalText: 'Total',
    couponText: 'adicione o cupom "LIVE30" no checkout',
    subtotalText: 'Subtotal',
    couponImage,
    padding: 20,
    saleswomanCouponImage,
    discountTextColor: '#1D5B1B',
    shareButton: {
      shape: {
        border: 'none',
        borderRadius: 1,
      },
    },
  },
  somaTag: {
    stockOut: {
      text: 'Sem estoque',
      backgroundColor: '#D66157',
    },
    exclusive: {
      text: 'Exclusivo',
      backgroundColor: '#1D5B1B',
    },

    color: '#FAFAFA',
    width: 91,
    height: 16,
    borderRadius: '1px',
    fontSize: '12px',
  },
  chat: {
    active: true,
    text: 'chat',
    icon: {
      src: chatIcon,
      alt: 'chat',
    },
    sendMessageIcon,
    mobileIcon: {
      src: mobileChatIcon,
      alt: 'chat',
    },
    mainBackgroundColor: template.mainColor,
    headerTextColor: '#FFF',
    headerText: 'chat!',
    setFirstNameText: 'seja bem-vinda(o) ao chat da live da Animale!',
    setSecondNameText: 'para participar, informe seu nome e e-mail.',
    buttonTextTheme: 'primary',
    setUserNameButtonText: 'ACESSAR CHAT',
    colorTextChatPortrait: '#64473F',
    mainColor: template.mainColor,
    headerBackgroundColor: template.mainColor,
    tooltipEmailText: 'Seu e-mail',
    tooltipNameText: 'Seu nome',
    tooltipMessageText: 'Escreva aqui',
    emptyMessageErrorText: 'por favor insira uma mensagem!',
    messageSuccessText: 'mensagem enviada com sucesso!',
    emptyNameErrorText: 'por favor insira um nome!',
    borderRadius: template.buttonsBorderRadius,
    fontSizeButtonLabel: '12px',
    lineHeight: '20px',
    fontWeight: 300,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  drawer: {
    header: {
      button: {
        size: 'small',
      },
    },
    padding: 10,
    width: '585px',
  },
  somaNavigation: {
    appBar: {
      position: 'static',
      elevation: 0,
      color: 'transparent',
    },
    tab: {
      variant: 'fullWidth',
      indicatorColor: 'primary',
      textTransform: 'capitalize',
    },
    quantity: {
      box: {
        style: {
          display: 'flex',
          alignItems: 'center',
        },
      },
      button: {
        style: {
          minWidth: 50,
          fontSize: 18,
          margin: 5,
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
      variant: 'subtitle1',
    },
  },
  realtimeProduct: {
    styles: {
      padding: 10,
    },
    title: 'Apresentando:',
    titleVariant: 'subtitle2',
    rerun: {
      playOnVideoTitle: 'Ir para o momento da apresentação',
    },
  },
  i18n: {
    locale: 'pt-BR',
    currency: 'BRL',
  },
  miniPlayer: {
    typography: {
      fontFamily: template.secondaryFontFamily,
      titleFontSize: '0.875rem',
    },
    tapumeImageURL: tapumeMiniplayer,
  },

  operator: {},
  system: {
    messages: {
      lgpd: {
        button: {
          style: {
            fontSize: '12px',
            backgroundColor: '#000000',
            borderRadius: '1px',
          },
        },
        box: { background: template.secondaryColor },
        text: {
          style: { color: template.mainColor, fontSize: '14px' },
          content: [
            {
              value:
                'Usamos cookies no site para dar a você uma melhor experiência. Para continuar navegando, é só clicar no botão: ',
              type: 'text',
            },
            {
              value: '',
              type: 'link',
              href: '',
              title: '',
            },
            {
              value: '',
              type: 'text',
            },
            {
              buttonText: 'ACEITAR COOKIES',
              type: 'text',
            },
          ],
        },
      },
    },
  },
  favicon: faviconAnimale,
  title: 'ANIMALE EXPERIENCE',
  snackbar: {
    iconStyles: {
      color: themeTemplate.mainColor,
      height: '50%',
    },
  },
  mutePlayer: {
    borderRadius: 1,
  },
  reaction: {
    active: true,
    borderRadius: 1,
    icons: {
      outlinedHeart,
      selfReactionHeart,
      answeredHeart,
    },
    colors: {
      myReaction: template.mainColor,
      othersReaction: '#FFFFFF',
    },
  },
  pictureInPicture: { borderRadius: 1 },
  share: { borderRadius: 1 },
};

export default { ...main, ...ptBR };

export { themeTemplate, colorTransform };
