import { makeStyles } from '@material-ui/core';

const useContainerStyle = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    flexDirection: 'row',
    height: '100vh',
    '@media(orientation: portrait)': {
      justifyContent: 'center',
      flexDirection: 'column',
      textAlign: 'center',
    },
  },
});

const useTypographyTitleStyle = makeStyles((theme, checkLocation) => ({
  root: {
    fontSize: 32,
    lineHeight: 1.5,
    textAlign: 'center',
  },
  h4: {
    color: theme.palette.primary.light,
    fontFamily: checkLocation ? theme.typography.fontFamily : 'SolinaBook',
  },
}));

const useTypographySubStyle = makeStyles((theme, checkLocation) => ({
  root: {
    fontSize: 32,
    lineHeight: 1.5,
    textAlign: 'center',
  },
  h4: {
    color: theme.typography.mainColor,
    fontFamily: checkLocation ? theme.typography.fontFamily : 'SolinaBook',
  },
}));

const useTypographyAdditionalTitleStyle = makeStyles((theme, checkLocation) => ({
  root: {
    marginTop: 16,
    textAlign: 'center',
    fontSize: 16,
    lineHeight: 1.5,
  },
  subtitle1: {
    color: theme.typography.mainColor,
    fontFamily: checkLocation ? theme.typography.fontFamily : 'SolinaBook',
  },
}));

const useBoxStyle = makeStyles({
  root: {
    marginBottom: 34,
    display: 'flex',
    justifyContent: 'center',
  },
});

const useImageStyle = makeStyles({
  root: {
    width: 57,
    height: 123,
  },
});

export default {
  useContainerStyle,
  useTypographySubStyle,
  useTypographyTitleStyle,
  useBoxStyle,
  useImageStyle,
  useTypographyAdditionalTitleStyle,
};
