import React, { useState, createContext, useContext, useCallback } from 'react';

const ChatContext = createContext();

const ChatContextProvider = ({ children }) => {
  const state = useState({
    chatMessages: [],
    chatBadgeCount: 0,
  });

  return <ChatContext.Provider value={state}>{children}</ChatContext.Provider>;
};

const useChatContext = () => {
  const [chatState, setChatState] = useContext(ChatContext);
  const { chatMessages, chatBadgeCount } = chatState;

  const setChatMessages = useCallback(
    (value) => {
      setChatState((oldState) => ({
        ...oldState,
        chatMessages: typeof value === 'function' ? value(oldState.chatMessages) : value,
      }));
    },
    [setChatState]
  );
  const setChatBadgeCount = useCallback(
    (value) => {
      setChatState((oldState) => ({
        ...oldState,
        chatBadgeCount: typeof value === 'function' ? value(oldState.chatBadgeCount) : value,
      }));
    },
    [setChatState]
  );

  return { chatMessages, setChatMessages, chatBadgeCount, setChatBadgeCount };
};

export { ChatContextProvider, useChatContext };
