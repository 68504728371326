import { makeStyles } from '@material-ui/core';

const useSelectedSize = makeStyles((theme) => ({
  root: {
    fontSize: theme.products.size.typography.size,
    textTransform: theme.products.size.typography.textTransform,
    borderRadius: '50%',
    width: '28px',
    height: '28px',
    padding: '1px 1px 0px 0px',
    border: '3px solid',
    textAlign: 'center',
    borderWidth: 'thin',
    marginLeft: '-7%',
  },
}));

export default {
  useSelectedSize,
};
