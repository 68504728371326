export default {
  onError: 'error',
  deviceInfo: 'device_info',

  onEnter: 'entered',

  onVideoStart: 'video_started',
  onVideoPause: 'video_paused',
  onVideoCompleted: 'video_completed',
  onVideoProgress: 'on_video_progress',
  onVideoPlayedMilliseconds: 'on_played_milliseconds',
  onMiniplayerRedirectClicked: 'miniplayer_redirect_clicked',

  onProductsShown: 'products_shown',
  onProductCategoryViewed: 'product_category_viewed',
  onProductImagesGalleryNavigatedNext: 'product_images_gallery_navigated_next',
  onProductImagesGalleryNavigatedPrevious: 'product_images_gallery_navigated_previous',
  onProductAddedToCart: 'product_added_to_cart',
  onProductJumpedToTime: 'product_jumped_to_time',
  onProductMeasurementChartShown: 'product_measurement_chart_shown',
  onProductsFiltered: 'products_filtered',
  onProductsSearched: 'products_searched',
  onProductShowcasingClicked: 'product_showcasing_clicked',
  onProductZoom: 'product_zoom',
  onOpenProductDetails: 'on_open_product_details',

  onCartShown: 'cart_shown',
  onCartItemRemoved: 'cart_item_removed',
  onCartItemAmountIncreased: 'cart_item_amount_increased',
  onCartItemAmountDecreased: 'cart_item_amount_decreased',
  onCartSharingLinkCopied: 'cart_sharing_link_copied',
  onCheckoutStarted: 'checkout_started',

  onChatShown: 'chat_shown',
  onChatMessageSent: 'chat_message_sent',

  onAnchorSizeShown: 'anchor_size_show',

  onFaqShown: 'faq_shown',
  onRedirectHelpButton: 'redirect_help_button',

  liveSharedParam: 'shared',
  onLiveShared: 'live_shared',
  onLiveSharedAccessed: 'live_shared_accessed',

  closeAllDrawers: 'close_all_drawers',

  onShareProduct: 'share_product',

  heartsUp: 'hearts_up',
  onActivePip: 'pip_activated',
};
