import { makeStyles } from '@material-ui/core';

const useFilterButtonStyle = (theme) => ({
  label: {
    fontSize: theme.products.filter.buttonConfirm.fontSize,
    '@media (orientation: portrait)': {
      fontSize: 14,
    },
  },
  button: {
    height: 30,
    margin: 0,
    border: 'none',
    padding: 0,
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
      borderRadius: theme.products.filters.FilterButton.shape.borderRadius,
    },
  },
  modalButton: {
    backgroundColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: theme.products.filter.buttonConfirm.shape.borderRadius,

    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
});

const useDialogTitleStyles = makeStyles((theme) => ({
  root: {
    marginTop: '28px',
    marginBottom: '41px',
    color: theme.palette.primary.main,
    fontSize: '24px',
    lineHeight: '32px',
    fontFamily: theme.DialogTitle.fontFamily,
  },
}));

const useGridContentStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    minwidth: '43px',
    maxWidth: '360px',
    Height: '100%',
    rowGap: '19px',
    columnGap: '24px',
    '@media(orientation:portrait)': {
      minWidth: '41px',
      maxWidth: '300px',
      height: '100%',
      rowGap: '30px',
      columnGap: '12px',
    },
  },
}));

const useButtomStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '56px',
    marginTop: '44px',
    marginBottom: '37px',
    '@media(orientation:landscape)': {
      marginBottom: '30px',
    },
  },
}));

const useGridButtomCleanStyles = makeStyles((theme) => ({
  root: {
    width: theme.products.filter.buttonClearFilters.width,
    height: theme.products.filter.buttonClearFilters.height,
    marginRight: '30px',
    '@media(orientation:portrait)': {
      width: '135px',
      height: '48px',
    },
  },
}));

const useGridButtomConfirmStyles = makeStyles((theme) => ({
  root: {
    width: theme.products.filter.buttonConfirm.width,
    height: theme.products.filter.buttonConfirm.height,
    '@media(orientation:portrait)': {
      width: '135px',
      height: '48px',
    },
  },
}));

const useDialogContentStyles = makeStyles(() => ({
  root: {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '@media(orientation:portrait)': {
      marginTop: '18px',
    },
    '@media(orientation:landscape)': {
      maxHeight: '450px',
    },
    marginTop: 0,
    overflow: 'hidden',
  },
}));
const useDialogActionsStyles = makeStyles(() => ({
  root: {
    padding: 0,
  },
}));
const useButtonSelectFilterStyles = makeStyles((theme) => ({
  root: {
    minWidth: theme.products.filters.ToggleButton.minWidth,
    maxWidth: '40px',
    height: '40px',
    background: 'transparent',
    borderRadius: theme.products.filters.ToggleButton.shape.borderRadius,
    border: '1px solid #E5E5E5',
    color: theme.palette.primary.main,
    marginTop: 4,
    padding: 0,
    margin: theme.products.filters.ToggleButton.margin,
  },
  label: {
    fontSize: '12px',
    lineHeight: '16px',
  },
  selected: {
    '&&': {
      '&:hover': {
        background: theme.palette.primary.main,
      },
      background: theme.palette.primary.main,
      color: 'white',
      border: '1px solid #333333',
    },
  },
}));

const useFiltersQntNotificationStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    right: 10,
    top: 0,
  },
  badge: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    transform: 'translate(50%, -50%)',
    minWidth: '16px',
    height: '16px',
    padding: 0,
    '@media(orientation:portrait)': {
      transform: 'translate(50%, -50%)',
      minWidth: '12px',
      height: '12px',
      fontSize: '10px',
    },
  },
}));

const useCloseModalStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    height: '12px',
    padding: 0,
    marginTop: '20px',
    marginRight: '20px',
  },
}));

const useGridCloseIconStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
}));

const useGridToogleStyles = makeStyles((theme) => ({
  root: {
    minwidth: '100%',
    maxheight: '450px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '@media(orientation:portrait)': {
      marginRight: theme.products.filters.marginRight,
    },
  },
}));

const useDialogContainerStyles = makeStyles((theme) => ({
  root: {
    zIndex: '1450!important',
    margin: 'auto!important',
  },
  paperWidthSm: {
    width: '100%',
    maxWidth: '608px',
    maxHeight: '550px',
    borderRadius: theme.products.filter.shape.borderRadius,
  },
}));

const filterProductTitleStyle = (theme) => ({
  color: theme.palette.primary.main,
  fontSize: 12,
  lineHeight: 16,
  textDecoration: theme.products.filter.textDecoration,
});

export {
  useDialogTitleStyles,
  useButtonSelectFilterStyles,
  useGridContentStyles,
  useFiltersQntNotificationStyles,
  useCloseModalStyles,
  useDialogContentStyles,
  useDialogContainerStyles,
  useButtomStyles,
  useGridButtomCleanStyles,
  useGridButtomConfirmStyles,
  useGridCloseIconStyles,
  useGridToogleStyles,
  useDialogActionsStyles,
  useFilterButtonStyle,
  filterProductTitleStyle,
};
