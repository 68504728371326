import { Badge, Button, Grid } from '@material-ui/core';
import React from 'react';
import { useBadgeStyles, useButtonStyles, useGridStyles } from './styles';

const FigureButton = (props) => {
  const { buttonProps, buttonStyles, gridProps, gridStyles, badgeStyles, badgeCount, figure } = props;

  const gridClasses = useGridStyles({ ...gridStyles });
  const badgeClasses = useBadgeStyles({ ...badgeStyles });
  const buttonClasses = useButtonStyles({ ...buttonStyles });

  return (
    <Button classes={buttonClasses} {...buttonProps}>
      <Grid container {...gridProps}>
        <Grid item container classes={gridClasses}>
          <Badge badgeContent={badgeCount} classes={badgeClasses}>
            {figure}
          </Badge>
        </Grid>
      </Grid>
    </Button>
  );
};

export default FigureButton;
