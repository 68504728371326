import React, { useState, useContext, createContext, useCallback } from 'react';

const StreamContext = createContext();

export default function StreamContextProvider({ children }) {
  const state = useState(null);
  return <StreamContext.Provider value={state}>{children}</StreamContext.Provider>;
}

export const useStreamContext = () => {
  const [streamContent, updateStreamContent] = useContext(StreamContext);

  const setStreamContent = useCallback(
    (newState) => {
      if (typeof newState !== 'object' || newState === null) {
        return;
      }

      updateStreamContent((prevState) => ({
        ...prevState,
        ...newState,
      }));
    },
    [updateStreamContent]
  );

  return {
    streamContent,
    setStreamContent,
  };
};
