import { makeStyles } from '@material-ui/core';

const useSearchFieldStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    height: 48,

    '@media (orientation: portrait)': {
      height: 36,
    },
    '& .MuiInputBase-input': {
      color: theme.products.placeholder,
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: theme.products.search.shape.borderRadius,
      borderColor: theme.products.placeholder,

      '&.Mui-focused fieldset': {
        border: '1px solid',
        borderColor: theme.palette.secondary.dark,
      },
      '&:not(.Mui-focused):hover fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.43)',
      },
    },
    [`& fieldset`]: {
      borderRadius: theme.products.search.shape.borderRadius,
      border: '1px solid',
      borderColor: theme.palette.secondary.dark,
    },
  },
}));

const svgProps = {
  fontStyle: 'normal',
  fontFamily: 'icomoon',
  fontVariant: 'normal',
  textTransform: 'none',
};

const closeIconStyle = makeStyles({
  root: {
    color: '#D1D1D6',
    width: '24px',
    height: '24px',
    marginRight: '-10px',
  },
});

const iconButtonStyles = makeStyles({
  root: {
    '&:hover': {
      background: 'none',
    },
  },
});

const useFormHelperTextStyles = makeStyles((theme) => ({
  root: {
    margin: '145px 0px 0px 55px',
    '@media (max-width: 480px)': {
      margin: '145px 0px 0px 27px',
    },
    fontSize: 12,
    color: theme.palette.primary.main,
  },
}));

const useSearchIconStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'icomoon',
    fontSize: 11,
    marginRight: '19px',
    fontWeight: 500,
    ...svgProps,
    '&:before': {
      content: '"\\e907"',
    },
    color: theme.palette.primary.main,
  },
}));

const useInputStyles = makeStyles({
  root: {
    fontSize: 14,
    height: '100%',
    paddingLeft: 12,
    lineHeight: 1.5,
  },
  input: {
    '&::-webkit-search-cancel-button': {
      display: 'none',
    },
    '&::placeholder': {
      fontSize: 14,
    },
  },
});

export {
  useSearchFieldStyles,
  useFormHelperTextStyles,
  useInputStyles,
  closeIconStyle,
  iconButtonStyles,
  useSearchIconStyles,
};
