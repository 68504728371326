import { makeStyles } from '@material-ui/core';

const zoom = 2.5;

const useDesktopStyles = ({ width, height, showZoom, src }) =>
  makeStyles((theme) => ({
    img: {
      opacity: showZoom ? '0' : '1',
    },
    zoom: {
      width,
      height,
      margin: '10px',
      backgroundImage: `url('${src}')`,
      backgroundSize: `${width * zoom}px ${height * zoom}px`,
      backgroundPosition: '0 0',
      cursor: `${theme.products.hover.icon} 2 2, crosshair`,
    },
  }))();

const mobileZoomStyles = ({ width, height }) => ({
  wrapper: {
    width,
    height,
    maxHeight: '90%',
    justifyContent: 'center',
    margin: '10px',
  },
  component: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
  },
});

export { useDesktopStyles, mobileZoomStyles };
