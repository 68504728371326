import { makeStyles } from '@material-ui/core';

const useButtonStyles = (openShowCase) =>
  makeStyles((theme) => ({
    root: {
      height: 40,
      width: 40,
      background: `url(${theme.showCase.button.src})`,
      transform: openShowCase && 'rotate(-180deg)',
    },
  }))();

const useGridButtonStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '0 5px',
  },
});

const useGridCardStyles = makeStyles((theme) => ({
  root: {
    width: theme.showCase.productWidth,
    margin: '0 5px',
    alignSelf: 'flex-end',
  },
}));

const mainGridContainerStyles = (noAnimation, openShowCase, minHeight) =>
  makeStyles((theme) => ({
    root: {
      margin: '0 5px',
      transform: openShowCase ? 'none' : `translateX(-100%) translateX(${60 + theme.showCase.closeTabShowCaseWidth}px)`,
      transition: noAnimation ? 'none' : 'transform 0.7s ease-out',
      minHeight,
    },
  }))();

export default {
  useButtonStyles,
  useGridButtonStyles,
  useGridCardStyles,
  mainGridContainerStyles,
};
