import { makeStyles } from '@material-ui/core';

const useEmailFieldStyles = ({ textColor, letterSpacing }) =>
  makeStyles({
    root: {
      color: textColor,
      background: '#FFF',
      letterSpacing,
      width: '19rem',
    },
  })();

const useInputStyles = makeStyles({
  input: {
    padding: '14px 0px 15px 8px',
    fontSize: '16px',
    '&::placeholder': {
      color: ' black ',
      opacity: 0.7,
      fontSize: '14px',
    },
  },
});

const useFormHelperTextStyles = makeStyles({
  root: {
    backgroundColor: '#FFF',
    margin: '0px 0px 0px 8px',
  },
});

const useIconStyles = makeStyles(() => ({
  root: {
    borderRadius: 0,
    backgroundColor: '#3bd90a',
    '&:hover': {
      backgroundColor: '#2ea908',
    },
  },
  disabled: {
    backgroundColor: '#afaeac',
  },
}));

export { useEmailFieldStyles, useInputStyles, useFormHelperTextStyles, useIconStyles };
