import React, { useEffect, useState } from 'react';
import { Grid, Typography, useTheme, Box, TextField, IconButton, InputAdornment } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PropTypes from 'prop-types';
import Styles from './styles';
import { emailRegexTest } from '../../../../helpers/emailHelper';
import TextButton from '../../SomaClickable/textButton';

const SomaChatForm = (props) => {
  const { presetEmail, isLoggedIn, onSend, presetName, saveUserData, setLoggedIn } = props;
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [justify, setJustify] = useState('space-between');
  const [message, setMessage] = useState('');
  const [nameError, setNameError] = useState();
  const [emailError, setEmailError] = useState();
  const [emailValid, setEmailValid] = useState(false);
  const [messageError, setMessageError] = useState();

  const theme = useTheme();
  const buttonClasses = Styles.useButtonStyles(theme);
  const inputFieldClasses = Styles.useInputFieldStyles();
  const chatTitleClasses = Styles.useChatTitleStyles();
  const buttonGridClasses = Styles.useButtonGridStyles();
  const boxClasses = Styles.useBoxStyles();
  const chatTitleGridClasses = Styles.useChatTitleGridStyles();
  const portrait = useMediaQuery('(orientation: portrait)');
  const isMobileLandscape = useMediaQuery('(max-width:926px) and (orientation: landscape)');
  const { mobileChatImage, sendMessageIcon } = theme.chat;

  useEffect(() => {
    setJustify(isLoggedIn ? 'flex-end' : 'flex-start');
    if (presetEmail) {
      setEmail(presetEmail);
      setEmailError(false);
    }
    if (presetName) {
      setName(presetName);
      setNameError(false);
    }
  }, [isLoggedIn, presetEmail, presetName]);

  const signInchat = () => {
    if (nameError || nameError === undefined) {
      setNameError(true);
    }
    if (emailError || emailError === undefined) {
      setEmailError(true);
    }
    if (emailError === false && nameError === false) {
      saveUserData({ name, email });
      setMessageError(false);
      setLoggedIn(true);
    }
  };

  const sendMessage = () => {
    if (!message) {
      setMessageError(true);
    }
    if (message !== '' && message !== undefined) {
      const formatedMessage = {
        text: message,
        name,
        email,
      };
      onSend(formatedMessage);
      setMessageError(false);
      setMessage('');
    }
  };

  const getMessageValue = (e) => {
    e.preventDefault();
    setMessage(e.target.value);
    setMessageError(false);
  };

  const setEmailValue = () => {
    const valid = emailRegexTest(String(email).toLowerCase());
    setEmailError(!valid);
    setEmailValid(valid);
  };

  const setNameValue = () => {
    const valid = name.length >= 3;
    setNameError(!valid);
  };
  return (
    <Grid item container style={{ height: '100%' }} direction="column" justify={justify}>
      <Box classes={boxClasses}>
        {((!isLoggedIn && !isMobileLandscape) ||
          (isMobileLandscape && !isLoggedIn && (email.length === 0 || !emailValid))) && (
          <>
            <Grid item>
              <Grid item container justify="flex-start">
                {mobileChatImage ? portrait && <img src={mobileChatImage} alt="chat_image" width="140px" /> : null}
              </Grid>
            </Grid>
            <Grid item classes={chatTitleGridClasses}>
              <Typography variant="subtitle2" classes={chatTitleClasses}>
                {theme.chat.setFirstNameText}
              </Typography>
              <Typography variant="subtitle2" classes={chatTitleClasses}>
                {theme.chat.setSecondNameText}
              </Typography>

              <TextField
                classes={inputFieldClasses}
                required
                variant="outlined"
                value={name}
                onChange={(e) => {
                  e.preventDefault();
                  setName(e.target.value);
                }}
                onBlur={() => {
                  setNameValue();
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') setNameValue();
                }}
                fullWidth
                placeholder={theme.chat.tooltipNameText}
                aria-label="inserir nome"
                error={nameError}
                size={isMobileLandscape ? 'small' : 'medium'}
              />

              <TextField
                classes={inputFieldClasses}
                required
                placeholder={theme.chat.tooltipEmailText}
                aria-label="inserir email"
                value={email}
                error={emailError}
                style={{ marginTop: !portrait && 10 }}
                onChange={(e) => {
                  e.preventDefault();
                  setEmail(e.target.value);
                }}
                onBlur={() => {
                  setEmailValue();
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') setEmailValue();
                }}
                variant="outlined"
                fullWidth
                size={isMobileLandscape ? 'small' : 'medium'}
              />
            </Grid>
          </>
        )}

        <Grid item style={{ padding: portrait ? '0 25px' : 0 }}>
          {isLoggedIn && (
            <TextField
              classes={inputFieldClasses}
              required
              placeholder={theme.chat.tooltipMessageText}
              aria-label="inserir mensagem"
              value={message}
              error={messageError}
              onChange={getMessageValue}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton onClick={sendMessage} /* color="primary" */>
                      <img src={sendMessageIcon} alt="" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onKeyUp={({ key }) => {
                if (String(key).toLowerCase() === 'enter') {
                  sendMessage();
                }
              }}
              variant="outlined"
              fullWidth
              size={isMobileLandscape ? 'small' : 'medium'}
            />
          )}
        </Grid>
        {!isLoggedIn && (
          <Grid container item justify="flex-end" classes={buttonGridClasses}>
            <TextButton
              label={theme.chat.setUserNameButtonText}
              buttonProps={{ onClick: signInchat }}
              buttonStyles={{ ...buttonClasses.button }}
              labelStyles={{ ...buttonClasses.label }}
            />
          </Grid>
        )}
      </Box>
    </Grid>
  );
};

SomaChatForm.defaultProps = {
  presetEmail: '',
  presetName: '',
  isLoggedIn: false,
};

SomaChatForm.propTypes = {
  presetEmail: PropTypes.string,
  presetName: PropTypes.string,
  isLoggedIn: PropTypes.bool,
  onSend: PropTypes.func.isRequired,
};

export default SomaChatForm;
