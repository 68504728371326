import { makeStyles } from '@material-ui/core';

const useButtonStyles = (buttonStyles, disabledProps) =>
  makeStyles((theme) => ({
    root: {
      cursor: 'pointer',
      minWidth: '48px',
      backgroundColor: theme.palette.secondary.main,
      border: `1px solid ${theme.palette.secondary.dark}`,
      borderRadius: theme.welcomePage.container.callToAction.button.shape.borderRadius,
      height: '100%',
      '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
      },
      ...buttonStyles,
    },
    label: {
      width: 'auto',
    },
    disabled: {
      border: 'none',
      backgroundColor: theme.palette.secondary.dark,
      '& .MuiTypography-root': {
        color: theme.palette.primary.light,
      },
      ...disabledProps,
    },
  }))();

const useLabelStyles = ({ ...labelStyles }) =>
  makeStyles({
    root: {
      ...labelStyles,
    },
  })();

const useBadgeStyles = ({ ...badgeStyles }) =>
  makeStyles(() => ({
    badge: {
      height: 16,
      minWidth: 16,
      maxWidth: 16,
      ...badgeStyles,
    },
  }))();

const useGridStyles = ({ ...gridStyles }) =>
  makeStyles({
    root: {
      ...gridStyles,
    },
  })();

const useComposedButtonStyles = ({ ...buttonStyles }) =>
  makeStyles({
    root: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
      ...buttonStyles,
    },
    focusVisible: {
      backgroundColor: 'transparent',
    },
  })();

const useFigureStyles = ({ figureChar = '', ...figureCharStyles }) =>
  makeStyles({
    root: {
      fontSize: 24,
      lineHeight: 1,
      fontFamily: 'icomoon',
      marginBottom: '12px',
      '&:before': {
        content: `"${figureChar}"`,
      },
      color: 'inherit',
      ...figureCharStyles,
    },
  })();

const useImgStyles = ({ ...figureCharStyles }) =>
  makeStyles({
    root: {
      lineHeight: 1,
      marginBottom: '12px',
      color: 'inherit',
      ...figureCharStyles,
    },
  })();

export {
  useFigureStyles,
  useBadgeStyles,
  useButtonStyles,
  useGridStyles,
  useLabelStyles,
  useComposedButtonStyles,
  useImgStyles,
};
