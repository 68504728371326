import { ptBR } from '@material-ui/core/locale';
import config from '../config';
import { themeTemplate, colorTransform } from './somalive';

const welcomeBackgroundDesktop = `${config.assetsUrl}/mariafilo/background.png`;
const welcomeBackgroundMobile = `${config.assetsUrl}/mariafilo/background.png`;
const welcomeIcon = `${config.assetsUrl}/mariafilo/logo mf.png`;
const faqIcon = `${config.assetsUrl}/mariafilo/icon/helpIcon.svg`;
const bagIcon = `${config.assetsUrl}/mariafilo/icon/bagIcon.svg`;
const productIcon = `${config.assetsUrl}/mariafilo/icon/productIcon.svg`;
const chatIcon = `${config.assetsUrl}/mariafilo/icon/chatIcon.svg`;

const faviconMariaFilo = `${config.assetsUrl}/common/favicons/MariaFilo.png`;

const colorIntensity = 50;
const template = themeTemplate({
  mainColor: '#000',
  secondaryColor: '#F5CCA6',
  fontFamily: 'Lato',
  badgeBgColor: '#F44336',
  badgeColor: '#000',
  buttonsBorderRadius: 0,
});

const main = {
  palette: {
    primary: colorTransform(template.mainColor, colorIntensity),
    secondary: colorTransform(template.secondaryColor, colorIntensity),
  },
  typography: {
    fontFamily: template.fontFamily,
  },
  DialogTitle: {
    fontFamily: template.fontFamily,
  },
  overrides: {
    MuiTypography: {
      root: {
        color: template.mainColor,
      },
    },
    MuiButton: {
      root: {
        borderRadius: template.buttonsBorderRadius,
      },
    },
    MuiAlert: {
      root: {
        color: template.mainColor,
      },
      standardSuccess: {
        color: template.mainColor,
        backgroundColor: '#fff',
        '& $icon': {
          color: template.mainColor,
        },
      },
      standardInfo: {
        color: template.mainColor,
        backgroundColor: '#fff',
        '& $icon': {
          color: template.mainColor,
        },
      },
    },
  },
  faq: {
    text: 'Ajuda',
    icon: {
      src: faqIcon,
      alt: 'Ajuda',
    },
    content: [
      {
        title: 'O que é o Preview Reflexos?',
        description:
          'Uma prévia em 1ª mão para você conhecer as novidades da coleção Reflexos bem de perto, com benefícios exclusivos, de forma 100% digital.',
      },
      {
        title: 'Posso tirar minhas dúvidas?',
        description: 'Sim! Você pode enviar uma mensagem pelo ícone "Chat" que responderemos assim que possível.',
      },
      {
        title: 'Como vejo os produtos apresentados?',
        description:
          'Clique no ícone "Produtos" no canto esquerdo da tela e navegue pelas categorias para ver as peças de pertinho.',
      },
      {
        title: 'Posso comprar em tempo real?',
        description:
          'Sim! Escolha o produto, clique em "Adicionar à sacola" e selecione o seu tamanho. A peça será adicionada à sua sacola, que está no canto superior direito da tela.',
      },
      {
        title: 'Como finalizo minha compra?',
        description:
          'Clique no ícone "Sacola" no canto superior direito da tela e depois em "Finalizar compra". Você será direcionanda para o nosso site e poderá fazer o pagamento de forma rápida e segura.',
      },
    ],
  },
  bag: {
    text: 'Sacola',
    emptyBag: 'Sua sacola está vazia',
    emptyBagColor: 'secondary',
    icon: {
      src: bagIcon,
      alt: 'Sacola',
    },
  },
  products: {
    text: 'Produtos',
    icon: {
      src: productIcon,
      alt: 'Produtos',
    },
    size: {
      text: 'Selecione o Tamanho:',
      typography: {
        variant: 'subtitle1',
      },
    },
    details: {
      text: 'Detalhes do Produto',
      button: {
        variant: 'text',
        fullWidth: false,
      },
    },
    button: {
      text: 'Adicionar à sacola',
      variant: 'contained',
      color: 'primary',
    },
    composition: {
      text: 'Composição',
    },
    name: {
      color: 'primary',
    },
    price: {
      sale: {
        color: 'primary',
      },
    },
    quantity: {
      variant: 'subtitle1',
    },
    notAvaiable: {
      text: 'Produto Indisponível',
    },
    search: {
      notFound: 'Produto não encontrado',
      emptyBrand: 'É necessário fornecer uma brand para pesquisar o produto',
    },
  },
  welcomePage: {
    paper: {
      backgroundImageDesktop: `url(${welcomeBackgroundDesktop})`,
      backgroundImageMobile: `url(${welcomeBackgroundMobile})`,
      backgroundPosition: 'left top',
    },
    container: {
      justify: 'center',
      header: {
        welcomeIcon: {
          src: welcomeIcon,
          alt: 'mariafilo-welcome-background',
        },
      },
      content: {
        paddingBottom: 20,
        main: {
          text: 'Uma prévia em primeira mão para você conhecer as novidades da coleção bem de perto, com benefícios exclusivos, de forma 100% digital',
        },
        additional: {
          text: '',
        },
        footer: {
          text: '',
        },
      },
      callToAction: {
        button: {
          text: 'ASSISTA AGORA',
          color: 'secondary',
          variant: 'outlined',
        },
      },
    },
  },
  checkout: {
    baseUrl: 'https://www.mariafilo.com.br',
    path: '/checkout/cart/add',
    button: {
      text: 'Finalizar compra',
    },
    discountText: 'Outros Descontos',
    totalText: 'Total',
    couponText: 'CUPOM DE 15% OFF',
    installmentNumber: 10,
  },
  chat: {
    active: true,
    text: 'Chat',
    icon: {
      src: chatIcon,
      alt: 'Chat',
    },
    mainBackgroundColor: template.mainColor,
    headerTextColor: '#FFF',
    headerText: 'Chat!',
    setNameText: 'seja bem-vinda ao nosso atendimento! Para começar digite o seu e-mail abaixo.',
    buttonTextTheme: 'primary',
    setUserNameButtonText: 'Enviar',
    colorTextChatPortrait: '#f5cca6',
    mainColor: template.mainColor,
    headerBackgroundColor: template.mainColor,
    tooltipEmailText: 'E-mail',
    tooltipMessageText: 'Mensagem',
    emptyMessageErrorText: 'Por favor insira uma mensagem!',
    messageSuccessText: 'Mensagem enviada com sucesso!',
    emptyNameErrorText: 'Por favor insira um nome!',
  },
  drawer: {
    header: {
      button: {
        size: 'small',
      },
    },
    padding: 10,
    width: '40%',
  },
  somaNavigation: {
    appBar: {
      position: 'static',
      elevation: 0,
      color: 'transparent',
    },
    tab: {
      textColor: 'primary',
      variant: 'fullWidth',
      indicatorColor: 'primary',
    },
    quantity: {
      box: {
        style: {
          display: 'flex',
          alignItems: 'center',
        },
      },
      button: {
        style: {
          minWidth: 50,
          fontSize: 18,
          margin: 5,
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
      variant: 'subtitle1',
    },
  },
  realtimeProduct: {
    styles: {
      padding: 10,
    },
    title: 'Apresentando:',
    titleVariant: 'subtitle2',
  },
  i18n: {
    locale: 'pt-BR',
    currency: 'BRL',
  },
  operator: {},
  system: {
    messages: {
      lgpd: {
        button: {
          variant: 'outlined',
          style: {
            backgroundColor: 'transparent',
            color: template.mainColor,
            border: '1px solid',
          },
        },
        box: { background: 'rgba(242,228,211, 0.8)' },
        text: {
          style: { color: template.mainColor },
          content: [
            {
              value:
                'Utilizamos cookies para personalizar e melhorar a sua experiência em nosso site. Ao clicar em prosseguir, você concorda com a nossa ',
              type: 'text',
            },
            {
              value: 'Política de Privacidade',
              type: 'link',
              href: 'https://www.mariafilo.com.br/politicas/privacidade',
              title: 'Política de privacidade',
            },
            {
              value: '',
              type: 'text',
            },
            {
              buttonText: 'PROSSEGUIR',
              type: 'text',
            },
          ],
        },
      },
    },
  },
  favicon: faviconMariaFilo,
  title: 'Maria Filó - Premiere Live',
};

export default { ...main, ...ptBR };

export { themeTemplate, colorTransform };
