import React from 'react';
import { Box, Grid, Card, CardContent, CardMedia, Typography, CardHeader } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Styles from './styles';

const Anchor = () => {
  const theme = useTheme();
  const cardHeaderClasses = Styles.useCardHeaderStyle(theme);
  const cardMediaClasses = Styles.useCardMediaStyle();
  const cardStyleClasses = Styles.useCardStyle(theme);
  const cardContentClasses = Styles.useCardContentStyle();
  const typographyTitleClasses = Styles.useTypographyTitleStyle();
  const typographyNameClasses = Styles.useTypographyNameStyle(theme);
  const typographyValueClasses = Styles.useTypographyValueStyle();
  const gridCardInfoClasses = Styles.useGridCardInfoStyle();
  const gridCardContentClasses = Styles.useGridCardContentStyle();
  const boxContentClasses = Styles.useBoxContentStyle();
  const infoValueContentClasses = Styles.useInfoValuesStyle();
  const profilesAnchor = theme.anchorSize.profiles;
  const unitOfMeasure = Styles.useUnitOfMeasureStyle();

  return (
    <Box classes={boxContentClasses}>
      <CardHeader
        title={theme.anchorSize.titleAnchorSize}
        subheader={theme.anchorSize.subTitleAnchorSize}
        classes={cardHeaderClasses}
      />
      <Grid style={{ overflow: 'auto' }}>
        {profilesAnchor.map((profile) => (
          <Grid classes={gridCardContentClasses} key={profile.name} container>
            <CardMedia classes={cardMediaClasses} component="img" src={profile.srcImage} />
            <Card classes={cardStyleClasses}>
              <CardContent classes={cardContentClasses}>
                <Typography classes={typographyNameClasses}>{profile.name}</Typography>
                <Grid classes={gridCardInfoClasses}>
                  <Box classes={infoValueContentClasses}>
                    <Typography classes={typographyTitleClasses} style={{ justifyContent: 'flex-start' }}>
                      {profile.size}
                    </Typography>
                    <Typography classes={typographyValueClasses}>veste</Typography>
                  </Box>
                  <Box classes={infoValueContentClasses}>
                    <Grid classes={typographyTitleClasses}>
                      <Typography>{profile.bust}</Typography>
                      <Typography classes={unitOfMeasure}>cm</Typography>
                    </Grid>
                    <Typography classes={typographyValueClasses}>busto</Typography>
                  </Box>
                  <Box classes={infoValueContentClasses}>
                    <Grid classes={typographyTitleClasses}>
                      <Typography>{profile.waist}</Typography>
                      <Typography classes={unitOfMeasure}>cm</Typography>
                    </Grid>
                    <Typography classes={typographyValueClasses}>cintura</Typography>
                  </Box>
                  <Box classes={infoValueContentClasses}>
                    <Grid classes={typographyTitleClasses}>
                      <Typography>{profile.hips}</Typography>
                      <Typography classes={unitOfMeasure}>cm</Typography>
                    </Grid>
                    <Typography classes={typographyValueClasses}>quadril</Typography>
                  </Box>
                  <Box classes={infoValueContentClasses}>
                    <Grid classes={typographyTitleClasses}>
                      <Typography>{profile.height}</Typography>
                      <Typography classes={unitOfMeasure}>m</Typography>
                    </Grid>
                    <Typography classes={typographyValueClasses} style={{ textAlign: 'right' }}>
                      altura
                    </Typography>
                  </Box>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Anchor;
