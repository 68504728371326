import { makeStyles } from '@material-ui/core';

const useBoxContentStyle = () =>
  makeStyles({
    root: {
      flexDirection: 'column',
      padding: '0 22px',
      display: 'flex',
      '@media(orientation: portrait)': {
        padding: '25px 24px 0 24px',
      },
    },
  })();

const useCardHeaderStyle = (theme) =>
  makeStyles({
    root: {
      padding: 0,
    },
    title: {
      lineHeight: '32px',
      fontSize: theme.anchorSize.style.fontSize,
      fontFamily: theme.anchorSize.style.fontFamily,
      '@media(orientation: portrait)': {
        lineHeight: '22px',
        fontSize: theme.anchorSize.mobileStyle.fontSize,
        marginBottom: '8px',
      },
    },
    subheader: {
      lineHeight: '24px',
      marginBottom: '22px',
      color: theme.palette.primary.light,
      fontSize: theme.anchorSize.subtitleStyle.fontSize,
      '@media(orientation: portrait)': {
        fontSize: '14px',
        lineHeight: '16px',
      },
    },
  })();

const useCardMediaStyle = () =>
  makeStyles({
    root: {
      position: 'absolute',
      height: '94px',
      objectFit: 'contain',
      borderRadius: '50%',
      width: 'auto',
      '@media(orientation: portrait)': {
        marginBottom: '115px',
        marginLeft: '20px',
      },
    },
  })();

const useCardStyle = (theme) =>
  makeStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      border: '1px solid #E5E5E5',
      boxShadow: 'none',
      borderRadius: theme.anchorSize.style.borderRadius,
      height: '120px',
      width: '520px',
      marginLeft: '46px',
      '@media(orientation: portrait)': {
        marginLeft: 'auto',
        justifyContent: 'center',
      },
    },
  })();
const useCardContentStyle = () =>
  makeStyles({
    root: {
      padding: '16px 25px 0 80px',
      '@media(orientation: portrait)': {
        '&:last-child': {
          padding: '0 24px',
          '@media screen and (max-width: 420px)': {
            padding: '0 16px',
          },
        },
      },
    },
  })();

const useTypographyTitleStyle = () =>
  makeStyles({
    root: {
      lineHeight: '24px',
      textTransform: 'none',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      '@media(orientation: portrait)': {
        justifyContent: 'center',
      },
    },
  })();

const useTypographyNameStyle = (theme) =>
  makeStyles({
    root: {
      lineHeight: '24px',
      fontSize: theme.anchorSize.subtitleStyle.fontSize,
      '@media(orientation: portrait)': {
        paddingLeft: '100px',
      },
    },
  })();

const useTypographyValueStyle = () =>
  makeStyles({
    root: {
      lineHeight: '16px',
      fontSize: '12px',
    },
  })();

const useGridCardInfoStyle = () =>
  makeStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: '12px',
    },
  })();

const useGridCardContentStyle = () =>
  makeStyles({
    root: {
      position: 'relative',
      padding: '10px 10px 32px',
      alignItems: 'center',
      '@media(orientation: portrait)': {
        padding: '50px 10px 25px',
      },
    },
  })();

const useInfoValuesStyle = () =>
  makeStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      textAlign: 'center',
    },
  })();

const useUnitOfMeasureStyle = () =>
  makeStyles({
    root: {
      marginLeft: '3px',
      '@media(orientation: portrait)': {
        fontSize: '12px',
        lineHeight: '20px',
      },
    },
  })();

export default {
  useBoxContentStyle,
  useCardHeaderStyle,
  useCardMediaStyle,
  useCardStyle,
  useCardContentStyle,
  useTypographyTitleStyle,
  useTypographyNameStyle,
  useTypographyValueStyle,
  useGridCardInfoStyle,
  useGridCardContentStyle,
  useInfoValuesStyle,
  useUnitOfMeasureStyle,
};
