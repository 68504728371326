import { makeStyles } from '@material-ui/core';

const useButtonStyles = makeStyles((theme) => ({
  root: {
    background: 'transparent',
    border: `1px solid ${theme.palette.secondary.dark} `,
    color: theme.palette.primary.main,
    padding: 0,
    width: theme.pdp.buttonSizeWidth,
    height: theme.pdp.buttonSizeHeight,
    borderRadius: theme.pdp.buttonSizeRadius,
  },
  label: {
    fontSize: '12px',
    lineHeight: '16px',
  },
  selected: {
    '&&': {
      '&:hover': {
        background: theme.palette.primary.main,
      },
      background: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main} `,
      color: 'white',
    },
  },
  disabled: {
    background: theme.palette.secondary.light,
    color: `${theme.palette.primary.light} !important`,
    border: 'none',
  },
}));

const useContainerStyle = () =>
  makeStyles({
    root: {
      padding: '0',
      display: 'flex',
      margin: '35px 0 18px 0',
      flexDirection: 'column',
      flexWrap: 'wrap',

      '@media (orientation: portrait)': {
        margin: '0 0 10px 0',
      },
    },
  })();

const useGridStyle = (theme) =>
  makeStyles({
    root: {
      justifyContent: 'flex-start',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      padding: '0',
      gap: theme.pdp.buttonSizeGap,
    },
  })();

const useTextStyles = makeStyles((theme) => ({
  title: {
    fontSize: '16px',
    lineHeight: '24px',
    marginBottom: '8px',
    display: 'none',

    '@media (orientation: portrait)': {
      display: 'flex',
    },
  },
  text: {
    color: theme.palette.feedback.main,
    lineHeight: '16px',
    marginTop: '7px',
    fontSize: 10,
  },
}));

export default { useButtonStyles, useContainerStyle, useGridStyle, useTextStyles };
