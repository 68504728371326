import { makeStyles } from '@material-ui/core';

const useListStyle = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    overflowY: 'scroll',
    paddingTop: 10,

    '@media(orientation: portrait)': {
      padding: '32px 25px 0',
    },

    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
});

const headerMessageStyle = makeStyles((theme) => ({
  secondary: {
    color: theme.palette.primary.light,
    lineHeight: '16px',
    fontSize: 12,
  },
  primary: {
    color: 'blue',
    lineHeight: '16px',
    fontSize: 12,
  },
  root: {
    margin: 0,
  },
}));

const bodyMessageStyle = makeStyles((theme) => ({
  primary: {
    wordWrap: 'break-word',
    color: theme.palette.primary.main,
    lineHeight: '16px',
    fontSize: 14,
    textTransform: 'none',
  },
  secondary: {
    wordWrap: 'break-word',
    fontWeight: 'bold',
    textTransform: 'lowercase',
    color: theme.palette.primary.main,
  },
  root: {
    margin: 0,
  },
}));

export default {
  useListStyle,
  headerMessageStyle,
  bodyMessageStyle,
};
