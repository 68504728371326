import { makeStyles } from '@material-ui/core';

const useBoxStyle = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const useButtonStyle = makeStyles((theme) => ({
  root: {
    minWidth: 24,
    minHeight: 24,
    width: 24,
    height: 24,
    fontSize: 18,
    margin: 0,
    borderRadius: theme.quantitySelector.borderRadius,
    backgroundColor: theme.quantitySelector.backgroundColor,
    border: theme.quantitySelector.border,
    '&:hover': {
      backgroundColor: theme.quantitySelector.backgroundColor,
    },
  },
}));

const useTextStyle = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    fontSize: 14,
    margin: 16,
  },
}));

export default { useButtonStyle, useBoxStyle, useTextStyle };
