import socketIOClient from 'socket.io-client';
import SOMACONFIG from '../config';

export const socketBuilder = (props) => {
  const { websocketUrl, environment } = SOMACONFIG;
  const { brand, streamName } = props;

  const key = `${brand}-${streamName}-${environment}`;
  const path = '/socket.io/';

  return socketIOClient(websocketUrl, { path, transports: ['websocket'], query: { key } });
};
