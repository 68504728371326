import React, { useRef } from 'react';
import { Box, CardMedia, IconButton, Paper, useMediaQuery } from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';
import {
  useArrowStyles,
  useCarouselStylesWithProps,
  indicatorIconButtonProps,
  activeIndicatorIconButtonProps,
  indicatorContainerProps,
} from './styles';
import { useEventDispatch, somaEvents } from '../../../events';
import config from '../../../config';

const SomaCarousel = ({ imagesArray, imageViewing, onSelectPicture, product }) => {
  const arrowIcon = `${config.assetsUrl}/common/icons/Union.svg`;
  const hasContentEnoughToScroll = Array.from(imagesArray).length > 4;
  const carouselRef = useRef([]);
  const portrait = useMediaQuery('(orientation: portrait)');
  const Styles = useCarouselStylesWithProps({
    hasContentEnoughToScroll,
  })();
  const arrowClasses = useArrowStyles();
  const dispatchEvent = useEventDispatch();

  const scrollCarrossel = (type) => {
    const getScroll = 'scrollWidth';
    const scrollDirection = type === '-' ? 0 : carouselRef.current[getScroll];
    const scrollTo = { left: scrollDirection };

    carouselRef.current.scrollTo({ ...scrollTo, behavior: 'smooth' });
  };

  const Indicator = () => <div style={{ width: '100%', height: '100%' }} />;
  return (
    <>
      {portrait && (
        <Box style={{ position: 'relative' }}>
          <Carousel
            autoPlay={false}
            navButtonsAlwaysInvisible
            animation="fade"
            indicatorIconButtonProps={indicatorIconButtonProps}
            activeIndicatorIconButtonProps={activeIndicatorIconButtonProps}
            indicatorContainerProps={indicatorContainerProps}
            IndicatorIcon={<Indicator />}
          >
            {imagesArray.map((picture) => (
              <CardMedia
                component="img"
                key={picture}
                image={picture}
                alt={picture}
                className={Styles.imageItem}
                onClick={() => onSelectPicture(picture)}
              />
            ))}
          </Carousel>
          <Paper className={Styles.paper} elevation={0} />
        </Box>
      )}
      {!portrait && (
        <Box className={Styles.container}>
          <IconButton
            onClick={() => {
              scrollCarrossel('-');
              dispatchEvent(somaEvents.onProductImagesGalleryNavigatedPrevious, {
                product,
              });
            }}
            disableRipple
            disableFocusRipple
            className={arrowClasses.root}
          >
            <img src={arrowIcon} className={arrowClasses.previous} alt="backIcon" />
          </IconButton>
          <Box className={Styles.scrollable} ref={carouselRef}>
            {imagesArray.map((picture) => (
              <CardMedia
                component="img"
                key={picture}
                image={picture}
                alt={picture}
                className={Styles.imageItem}
                onClick={() => onSelectPicture(picture)}
                ref={(item) => {
                  if (!item) return;
                  item.style.opacity = imageViewing === picture ? 1 : 0.7;
                }}
              />
            ))}
          </Box>
          <IconButton
            onClick={() => {
              scrollCarrossel('+');
              dispatchEvent(somaEvents.onProductImagesGalleryNavigatedNext, {
                product,
              });
            }}
            disableRipple
            disableFocusRipple
            disabled={!hasContentEnoughToScroll}
            className={arrowClasses.root}
          >
            <img src={arrowIcon} className={arrowClasses.next} alt="nextIcon" />
          </IconButton>
        </Box>
      )}
    </>
  );
};

export default SomaCarousel;
