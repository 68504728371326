const sizeOrder = new Map([
  ['pp', 0],
  ['p', 1],
  ['m', 2],
  ['g', 3],
  ['gg', 4],
  ['u', 5],
]);

export default { sizes: sizeOrder };
