import { makeStyles } from '@material-ui/core';

// const aspectRatio = '56.25%'; // 16:9

const usePlayerWrapper = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    bottom: 0,
    height: '100%',
    width: '100%',
    backgroundColor: theme.palette.common.white,
    // paddingTop: aspectRatio, -> Enquadramento total do video na tela (com corte)

    '@media (max-width: 900px) and (orientation: landscape)': {
      padding: '0',
    },
  },
}));

export { usePlayerWrapper };
