import { useState } from 'react';
import { useEvent } from '../events/useEvent';
import { useStreamContext } from '../contexts/streamContext';
import { useRealTimeProductsContext } from '../contexts/realTimeProductsContext';
import { somaEvents } from '../events';

export default () => {
  const { streamContent } = useStreamContext();
  const { realTimeProductsState, setShowCasing } = useRealTimeProductsContext();
  const [currentKeyframeId, setCurrentKeyframeId] = useState();
  const contentValidation = [streamContent.video.isRerun, realTimeProductsState.timeline];

  useEvent(somaEvents.onVideoPlayedMilliseconds, (ms) => {
    if (contentValidation.every(Boolean) === false) {
      return;
    }
    const { keyframes } = realTimeProductsState.timeline;
    const keyframe = keyframes.find((kf) => kf.rerun.displayAt <= ms);

    if (keyframe && keyframe.products && currentKeyframeId !== keyframe.id) {
      const products = keyframe.products.map((item) => streamContent.uniqueProducts.get(item.id_product));
      setShowCasing(products);
      setCurrentKeyframeId(keyframe.id);
    }
  });
};
