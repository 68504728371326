import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Styles from './styles';
import FigureButton from '../../molecules/SomaClickable/figureButton';
import { somaEvents, useEventDispatch } from '../../../events';

const MobileShare = () => {
  const theme = useTheme();

  const shareIconClasses = Styles.useShareIconStyle();
  const gridContainerClasses = Styles.userGridStyles();
  const buttomClasses = Styles.userButtomStyles(theme);
  const isMobileLandscape = useMediaQuery('(max-width:926px) and (orientation: landscape)');
  const displayShareButton = Boolean(navigator.share && !isMobileLandscape);

  const dispatchEvent = useEventDispatch();

  const share = () => {
    dispatchEvent(somaEvents.onLiveShared);

    const url = new URL(window.location.href);
    url.searchParams.set('utm_live', somaEvents.liveSharedParam);

    navigator.share({
      title: `${theme.title}`,
      url,
    });
  };

  return (
    <Grid classes={gridContainerClasses}>
      {displayShareButton && (
        <FigureButton
          figure={<Typography classes={shareIconClasses} />}
          buttonStyles={{ ...buttomClasses }}
          buttonProps={{
            disableRipple: true,
            disableFocusRipple: true,
            onClick: share,
          }}
        />
      )}
    </Grid>
  );
};
export default MobileShare;
