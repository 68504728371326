import { makeStyles } from '@material-ui/core';

const useBoxStyle = makeStyles({
  root: {
    paddingTop: 13,
    paddingBottom: 25,
    overflow: 'hidden',
    position: 'relative',
    '@media(orientation: portrait)': {
      paddingLeft: 25,
      paddingRight: 5,
    },
    width: '100%',
    '@media(orientation: landscape)': {
      paddingTop: 0,
      marginTop: 18,
      width: 430,
      marginLeft: 24,
    },
    margin: 'auto',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
});

const useMainStyle = makeStyles({
  root: {
    width: '100%',
    overflow: 'hidden',
    '@media (orientation: portrait)': {
      overflowY: 'hidden',
    },
    '@media (orientation: landscape)': {
      maxHeight: 'calc(100vh - 364px)',
      width: 430,
      overflowY: 'scroll',
      '&::scrollbar': {
        '@media (orientation: portrait)': {
          display: 'none',
        },
      },
      '&::-webkit-scrollbar': {
        '@media (orientation: portrait)': {
          display: 'none',
        },
      },
    },
  },
});

const useFooterStyle = () =>
  makeStyles({
    root: {
      width: 362,
      flex: '0 0 auto',
      backgroundColor: '#fff',
      paddingBottom: 5,
      '@media(orientation: portrait)': {
        paddingRight: 20,
        width: '100%',
      },
    },
  })();

const useEmptyBoxStyle = (theme) =>
  makeStyles({
    root: {
      padding: '24px 0',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      '@media(orientation: portrait)': {
        padding: '19px 26px',
        justifyContent: 'flex-start',
        '& .center-container': {
          marginLeft: theme.bag.button.shape.marginLeft,
        },
      },
      '& .center-container': {
        padding: '0px',
        width: '217px',
        marginTop: '21px',
      },
    },
  })();

const useButtomStyle = (theme) => ({
  composed: {
    cursor: 'default',
    padding: 0,
  },
  label: {
    fontSize: 24,
    lineHeight: '32px',
    whiteSpace: 'nowrap',
    fontFamily: theme.bag.emptyTextStyle.fontFamily,
  },
  basic: {
    backgroundColor: theme.palette.primary.main,
    height: 56,
    borderRadius: theme.bag.continueShopping.borderRadius,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
});

export default { useBoxStyle, useEmptyBoxStyle, useMainStyle, useFooterStyle, useButtomStyle };
