import React from 'react';
import { Box } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PropTypes from 'prop-types';
import Styles from './styles';

const ViewDisplay = (props) => {
  const { label, border, borderColor, maxWidth } = props;

  const boxClasses = Styles.useBoxStyle();

  return (
    <Box border={border} borderColor={borderColor} maxWidth={maxWidth} classes={boxClasses}>
      <VisibilityIcon fontSize="small" />
      {label}
    </Box>
  );
};

ViewDisplay.defaultProps = {
  border: 1,
  label: 0,
  borderColor: 'default',
  maxWidth: 80,
};

ViewDisplay.propTypes = {
  border: PropTypes.number,
  label: PropTypes.number,
  borderColor: PropTypes.oneOf(['default', 'inherit', 'primary.main', 'secondary.main']),
  maxWidth: PropTypes.number,
};

export default ViewDisplay;
