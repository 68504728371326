import { enUS } from '@material-ui/core/locale';
import config from '../config';
import { themeTemplate, colorTransform } from './somalive';

const welcomeBackgroundDesktop = `${config.assetsUrl}/testes/tapume-desktop.png`;
const welcomeBackgroundMobile = `${config.assetsUrl}/testes/tapume-mobile.png`;
const welcomeIcon = `${config.assetsUrl}/farmUS/welcomeIcon.png`;
const faqIcon = `${config.assetsUrl}/farmUS/icon/helpIcon.svg`;
const bagIcon = `${config.assetsUrl}/farmUS/icon/bagIcon.svg`;
const productIcon = `${config.assetsUrl}/farmUS/icon/products.svg`;
const chatIcon = `${config.assetsUrl}/farmUS/icon/chatIcon.svg`;
const couponImage = `${config.assetsUrl}/farmUS/coupon.png`;

const colorIntensity = 50;
const template = themeTemplate({
  mainColor: '#000000',
  secondaryColor: '#F50000',
  fontFamily: 'Futura',
  badgeBgColor: '#000',
  badgeColor: 'white',
  buttonsBorderRadius: 0,
});

const main = {
  palette: {
    primary: colorTransform(template.mainColor, colorIntensity),
    secondary: colorTransform(template.secondaryColor, colorIntensity),
  },
  typography: {
    fontFamily: template.fontFamily,
  },
  DialogTitle: {
    fontFamily: template.fontFamily,
  },
  overrides: {
    MuiTypography: {
      root: {
        fontFamily: template.fontFamily,
        color: template.mainColor,
      },
    },
    MuiButton: {
      root: {
        borderRadius: template.buttonsBorderRadius,
      },
    },
    MuiAlert: {
      root: {
        color: template.mainColor,
      },
      standardSuccess: {
        color: template.mainColor,
        backgroundColor: '#fff',
        '& $icon': {
          color: template.mainColor,
        },
      },
      standardInfo: {
        color: template.mainColor,
        backgroundColor: '#fff',
        '& $icon': {
          color: template.mainColor,
        },
      },
    },
  },
  faq: {
    text: 'Help',
    icon: {
      src: faqIcon,
      alt: 'Help',
    },
    content: [
      {
        title: 'What is Live from Rio?',
        description:
          'Live from Rio is our virtual store, directly from Brazil, with the objective of reproducing the store experience in your home. Now, Farm is everywhere!',
      },
      {
        title: 'How do I send a message?',
        description: 'Click on the chat icon and leave your message',
      },
      {
        title: 'How do I see the products?',
        description: 'Just click on "All Products" on the screen',
      },
      {
        title: 'How do I choose a product?',
        description: 'After clicking on "All Products", just select a size and click on "Add To Bag"',
      },
      {
        title: 'How do I complete the purchase?',
        description: `Click on the banana icon and "Checkout". You'll be redirected to our website and will be able to make the payment.`,
      },
    ],
  },
  bag: {
    text: 'My bag',
    emptyBag: 'Your bag is empty',
    emptyBagColor: 'secondary',
    icon: {
      src: bagIcon,
      alt: 'Bag',
    },
    continueShopping: {
      text: 'Shop',
      button: {
        color: 'primary',
      },
    },
  },
  products: {
    text: 'Products',
    successBag: {
      text: 'Product added to bag!',
    },
    icon: {
      src: productIcon,
      alt: 'Products',
    },
    size: {
      text: 'Select your size:',
      typography: {
        variant: 'subtitle1',
      },
    },
    details: {
      text: 'Product Details',
      button: {
        variant: 'text',
        fullWidth: false,
      },
    },
    button: {
      text: 'Add to bag',
      variant: 'contained',
      color: 'primary',
    },
    composition: {
      text: 'Composition',
    },
    name: {
      color: 'primary',
    },
    price: {
      sale: {
        color: 'secondary',
      },
    },
    quantity: {
      variant: 'subtitle1',
    },
    notAvaiable: {
      text: 'Not avaiable',
    },
    search: {
      title: 'Search product',
      notFound: 'Product not found',
      emptyBrand: 'É necessário fornecer uma brand para pesquisar o produto',
    },
  },
  liveNotFound: {
    title: 'Ops, something went wrong...',
    subtitle: 'Please, verify the address (URL) link and make shure that`s correct or try again later.',
  },
  embedLiveStream: 'it was not possible to perform this operation, try again later!',
  welcomePage: {
    paper: {
      backgroundImageDesktop: `url(${welcomeBackgroundDesktop})`,
      backgroundImageMobile: `url(${welcomeBackgroundMobile})`,
      backgroundPosition: 'left top',
    },
    container: {
      justify: 'center',
      header: {
        maxWidthDesktop: '20%',
        welcomeIcon: {
          src: welcomeIcon,
          alt: 'brand-welcome-background',
        },
      },
      content: {
        spacing: 2,
        main: {
          text: 'Live from Rio: a new brand experience to buy FARM Rio',
        },
        additional: {
          text: 'It’s almost time to discover and shop our Fall 20 Collection in first hand. During this live all items you love are one-click away. You see, click and buy. A new simple way to take Casa FARM Rio to the world with you. Our house is yours, welcome!',
        },
        footer: {
          text: '09.26 - 11AM EST',
        },
      },
      callToAction: {
        button: {
          text: 'Watch Stream',
          color: 'secondary',
          variant: 'contained',
        },
      },
    },
  },
  checkout: {
    baseUrl: 'https://farmrio.com',
    path: '/cart/add',
    params: '',
    button: {
      text: 'Checkout',
    },
    discountText: 'Discount',
    totalText: 'Total',
    couponText: '15% OFF',
    subtotalText: 'Subtotal',
    couponImage,
  },
  chat: {
    active: true,
    text: 'Chat',
    icon: {
      src: chatIcon,
      alt: 'Chat',
    },
    mainBackgroundColor: template.mainColor,
    headerTextColor: '#fff',
    headerText: 'Chat!',
    setNameText:
      "The chat is off-line. But don't worry, you can ask questions at hello@farmrio.com or chat with us at farmrio.com. See you there!",
    buttonTextTheme: 'primary',
    setUserNameButtonText: 'Send',
    colorTextChatPortrait: 'black',
    mainColor: template.mainColor,
    headerBackgroundColor: template.mainColor,
    tooltipEmailText: 'E-mail',
    tooltipMessageText: 'Message',
    emptyMessageErrorText: 'Please insert a mesage!',
    messageSuccessText: 'Mesage sent!',
    emptyNameErrorText: 'Please insert a name!',
    productDetailText: 'Product Detail',
  },
  drawer: {
    header: {
      button: {
        size: 'small',
      },
    },
    padding: 10,
    width: '40%',
  },
  productSearch: {
    errorText: 'Ops, no products were found for this search.',
  },
  SomaIconButton: {
    text: 'top',
  },
  somaNavigation: {
    appBar: {
      position: 'static',
      elevation: 0,
      color: 'transparent',
    },
    tab: {
      textColor: 'primary',
      variant: 'fullWidth',
      indicatorColor: 'primary',
    },
    quantity: {
      box: {
        style: {
          display: 'flex',
          alignItems: 'center',
        },
      },
      button: {
        style: {
          minWidth: 50,
          fontSize: 18,
          margin: 5,
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
      variant: 'subtitle1',
    },
  },
  realtimeProduct: {
    styles: {
      padding: 10,
    },
    title: 'Add to bag:',
    label: 'showcasing',
    titleVariant: 'subtitle2',
  },
  i18n: {
    locale: 'en-us',
    currency: 'USD',
  },
  operator: {},
  login: {
    form: {
      inputUser: {
        text: 'User',
      },
      inputPassword: {
        text: 'Password',
      },
      button: {
        text: 'SignIn',
      },
    },
    footer: {
      text: 'Created and Developed by SomaLabs',
    },
  },
  system: {
    messages: {
      email: {
        postError: 'Unable to save e-mail, please try again in a moment.',
        timeout: 'Unable to save e-mail, please try again in a moment.',
        failedToFetch: 'Unable to save e-mail, please check your connection and try again in a moment.',
      },
      offline: 'We are currently offline',
      lgpd: {
        button: {
          style: {
            backgroundColor: template.mainColor,
          },
        },
        text: {
          style: { color: template.mainColor, fontWeight: '700' },
          content: [
            {
              value: 'we use ',
              type: 'text',
            },
            {
              value: 'cookies',
              type: 'link',
              href: 'https://www.farmrio.com.br/institucional/politicas#seguranca',
              title: 'Política de privacidade',
            },
            {
              value:
                ' on the website to give you a better shopping experience :) to continue browsing, just click on the button: ',
              type: 'text',
            },
            {
              buttonText: 'accept cookies',
              type: 'text',
            },
          ],
        },
      },
    },
  },
};

export default { ...main, ...enUS };
