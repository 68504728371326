import { makeStyles } from '@material-ui/core';

const useCountdownValueStyles = makeStyles({
  root: {
    position: 'relative',
    fontSize: '32px',
    lineHeight: '48px',
  },
});

const useCountdownLabelStyles = makeStyles({
  root: {
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, 0)',
    fontSize: '10px',
    lineHeight: '16px',
    marginTop: '-8px',
  },
});

const useSeparatorStyles = makeStyles({
  root: {
    marginTop: '-4px',
    fontSize: '32px',
    lineHeight: '48px',

    '@media (max-width: 926px) and (orientation: landscape)': {
      marginTop: '-9px',
    },
  },
});

const useCountDownStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '350px',
    justifyContent: 'center',
    marginTop: '9px',
  },
});

const useWrapperItemStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '-5px',

    '@media (max-width: 926px) and (orientation: landscape)': {
      marginTop: '-10px',
    },
  },
});
const useCountdownGridStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '75px',
    backgroundColor: theme.palette.secondary.main,
    position: 'fixed',
    bottom: 0,

    '@media (max-width: 926px) and (orientation: landscape)': {
      width: '926px',
      height: '54px',
      bottom: 0,
    },

    '@media (max-width: 480px) and (orientation: portrait)': {
      width: '430px',
      height: '84px',
      bottom: 0,
    },
  },
}));

export default {
  useCountdownValueStyles,
  useCountdownLabelStyles,
  useSeparatorStyles,
  useCountDownStyles,
  useWrapperItemStyles,
  useCountdownGridStyles,
};
