import axiosRetry from 'axios-retry';
import axios from 'axios';
import config from '../config';
import { makeFetchParams, formatCartSimulatorResponse } from '../helpers/bagHelper';
import { somaEvents, errorTypes } from '../events';
import { getDevice } from '../helpers/eventHelper';

export default class SomaliveAPIClient {
  constructor(streamName) {
    this.client = axios.create({ baseURL: config.backendUrl, timeout: config.request.timeout });
    axiosRetry(this.client, {
      retries: config.request.retries,
      retryDelay: (retryNumber) => retryNumber * config.request.retryBaseDelay,
    });
    this.streamName = `/${streamName}`;
    this.stream = `/stream`;
    this.stream_timeline = `/products/timeline`;
    this.stream_videoURL = `/vimeo-liveurl`;
    this.stream_cart_simulation = `/ecommerce/vtex/cart/simulation`;
    this.embed = `/embed/stream`;
    this.customerEmail = `/customer/email`;
  }

  getStream({ filters }) {
    const abortController = window.AbortController ? new AbortController() : null;

    const requestConfigs = {
      signal: abortController ? abortController.signal : null,
    };

    const filterQueryString = filters ? `?fq=${filters}` : '';
    const endpoint = `${this.stream}${this.streamName}${filterQueryString}`;

    const request = this.client
      .get(endpoint, requestConfigs)
      .then((response) => response.data)
      .catch((e) => {
        throw new Error(e);
      });

    return { request, abortController };
  }

  getEmbedInfo() {
    const abortController = window.AbortController ? new AbortController() : null;

    const requestConfigs = {
      signal: abortController ? abortController.signal : null,
    };

    const endpoint = `${this.embed}${this.streamName}`;

    const request = this.client
      .get(endpoint, requestConfigs)
      .then((response) => response.data)
      .catch((e) => {
        throw new Error(e);
      });
    return { request, abortController };
  }

  getTimeline(streamID) {
    const abortController = window.AbortController ? new AbortController() : null;

    const requestConfigs = {
      signal: abortController ? abortController.signal : null,
    };

    const endpoint = `${this.stream}/${streamID}${this.stream_timeline}`;

    const request = this.client
      .get(endpoint, requestConfigs)
      .then((response) => response.data)
      .catch((e) => {
        throw new Error(e);
      });

    return { request, abortController };
  }

  getVimeoInfo(videoURL) {
    const abortController = window.AbortController ? new AbortController() : null;

    const requestConfigs = {
      signal: abortController ? abortController.signal : null,
    };

    const endpoint = `${this.stream}${this.stream_videoURL}/${videoURL}`;

    const request = this.client
      .get(endpoint, requestConfigs)
      .then((response) => response.data)
      .catch((e) => {
        throw new Error(e);
      });

    return { request, abortController };
  }

  saveUserEmail(payload) {
    const abortController = window.AbortController ? new AbortController() : null;

    const requestConfigs = {
      signal: abortController ? abortController.signal : null,
    };

    const endpoint = `${this.customerEmail}`;

    const request = this.client
      .post(endpoint, payload, requestConfigs)
      .then((response) => response.data)
      .catch((e) => {
        throw new Error(e);
      });

    return { request, abortController };
  }

  async getCartSimulation(products, brand, exceptions, salesChannel) {
    const abortController = window.AbortController ? new AbortController() : null;
    const signal = abortController ? abortController.signal : null;

    const productsList = products?.map((item) => ({
      quantity: item.bagInfo.quantity,
      id: item.bagInfo.selectedItem.sku,
      seller: item.bagInfo.selectedItem.sellerId,
    }));

    const body = {
      brandName: brand,
      items: productsList,
      salesChannel,
    };

    const request = fetch(`${config.backendUrl}${this.stream_cart_simulation}`, makeFetchParams('POST', body, signal))
      .then((response) => response.json())
      .then((response) => formatCartSimulatorResponse(response, exceptions))
      .catch((error) => {
        dispatchEvent(somaEvents.onError, {
          type: errorTypes.requestError,
          message: `getCartSimulation fetch failed: ${error.message}`,
          path: 'somaliveAPI.js -> getCartSimulation()',
          device: getDevice(),
          stack: error.stack,
        });
      });

    return { request, abortController };
  }
}
