/* eslint-disable no-console */
import { useEvent } from '../../events/useEvent';
import config from '../../config';
import { somaEvents } from '../../events';

const sendToNewRelic = (payload) => {
  if (config.environment !== 'production') return;

  fetch(`${config.backendUrl}/newrelic/log`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  });
};

export default ({ debug }) => {
  if (debug) {
    useEvent('logError', console.error);
    useEvent('log', console.log);
  }
  useEvent(somaEvents.onError, sendToNewRelic);
  useEvent(somaEvents.deviceInfo, sendToNewRelic);
  return null;
};
