import React, { useEffect, useState } from 'react';
import { Box, Container, Typography, Button, IconButton } from '@material-ui/core';
import ReactPlayer from 'react-player';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import { isIOS, deviceType } from 'react-device-detect';
import { useEventDispatch, somaEvents, errorTypes } from '../../../events';
import Theme from '../../../themes';
import SomaliveAPIClient from '../../../services/somaliveAPI';

import {
  useMainBoxStyles,
  usePlayerWrapperStyles,
  useTitleBoxStyles,
  useTitleTypographyStyles,
  useCtaBoxStyles,
  useOfflineContainerStyles,
  useVideoControlsBoxStyles,
  useVideoControlsIconButtonStyles,
  useVideoControlsIconStyles,
} from './styles';

import { getDevice } from '../../../helpers/eventHelper';

const EmbedLiveStream = () => {
  const [streamContent, setStreamContent] = useState();
  const [theme, setTheme] = useState(Theme());
  const [tapumeImageURL, setTapumeImageURL] = useState('');
  const [isLiveStream, setIsLiveStream] = useState();
  const [redirectUrl, setRedirectUrl] = useState('');
  const [muted, setMuted] = useState(true);
  const dispatchEvent = useEventDispatch();
  const [, streamName] = window.location.pathname.split('/');

  useEffect(() => {
    if (!dispatchEvent || !streamName) {
      return;
    }
    new SomaliveAPIClient(streamName)
      .getEmbedInfo()
      .request.then((response) => {
        setStreamContent({ ...response });
      })
      .catch((error) => {
        dispatchEvent(somaEvents.onError, {
          type: errorTypes.requestError,
          message: error.message,
          path: 'EmbedLiveStream/index.js -> Somalive.getStream()',
          device: getDevice(),
          stack: error.stack,
        });
      });
  }, [dispatchEvent, streamName]);

  useEffect(() => {
    if (streamContent) {
      const brandTheme = Theme(streamContent.brand.name, streamName);
      setTheme(brandTheme);
      setTapumeImageURL(brandTheme.miniPlayer.tapumeImageURL);
      setRedirectUrl(`${streamContent.brand.frontend_url}/${streamName}?${brandTheme.miniPlayer.URLParams}`);
      setIsLiveStream(streamContent.is_live && streamContent.video.isRerun === 0);
    }
  }, [streamContent, streamName]);

  const mainBoxClasses = useMainBoxStyles();
  const playerWrapperClasses = usePlayerWrapperStyles();
  const titleBoxClasses = useTitleBoxStyles();
  const titleTypographyClasses = useTitleTypographyStyles(theme);
  const ctaBoxClasses = useCtaBoxStyles();
  const offlineContainerClasses = useOfflineContainerStyles();
  const videoControlsBoxClasses = useVideoControlsBoxStyles();
  const videoControlsIconButtonClasses = useVideoControlsIconButtonStyles();
  const videoControlsIconClasses = useVideoControlsIconStyles();
  const reactPlayerConfig = isIOS && deviceType === 'mobile' ? {} : { file: { forceHLS: true } };

  const renderVideo = () => (
    <Box classes={mainBoxClasses}>
      <Box classes={playerWrapperClasses}>
        <ReactPlayer
          url={streamContent.video.url}
          width="100%"
          height="100%"
          playing
          playsinline
          volume={1}
          muted={muted}
          config={reactPlayerConfig}
          style={{ top: 0, left: 0, position: 'absolute' }}
        />
        <Box classes={titleBoxClasses}>
          <Typography variant="h6" classes={titleTypographyClasses}>
            {streamContent.video.title}
          </Typography>
          <Typography variant="subtitle2" classes={titleTypographyClasses}>
            ao vivo
          </Typography>
        </Box>

        <Button
          classes={ctaBoxClasses}
          onClick={() => {
            setMuted(true);
            dispatchEvent(somaEvents.onMiniplayerRedirectClicked);
            try {
              window.parent.postMessage(
                {
                  redirectUrl,
                  type: 'CTARedirectionRequest',
                },
                streamContent.brand.ecommerce_url
              );
            } catch (error) {
              dispatchEvent(somaEvents.onError, {
                type: errorTypes.requestError,
                message: error.message,
                path: 'EmbedLiveStream/index.js -> window.parent.postMessage()',
                device: getDevice(),
                stack: error.stack,
              });
            }
          }}
        />
        <Box classes={videoControlsBoxClasses}>
          <IconButton onClick={() => setMuted(!muted)} classes={videoControlsIconButtonClasses}>
            {muted && <VolumeOffIcon classes={videoControlsIconClasses} />}
            {!muted && <VolumeUpIcon classes={videoControlsIconClasses} />}
          </IconButton>
        </Box>
      </Box>
    </Box>
  );

  const renderOffline = () => (
    <Container fixed classes={offlineContainerClasses}>
      <img src={tapumeImageURL} alt="" />
    </Container>
  );

  return isLiveStream ? renderVideo() : renderOffline();
};

export default EmbedLiveStream;
