import { Grid, Typography, useTheme } from '@material-ui/core';
import React, { useContext } from 'react';

import { SalesWomanContext } from '../../../hooks/contexts';

const SomaSalesCoupon = () => {
  const { saleswomanCouponCode } = useContext(SalesWomanContext) || {};

  const theme = useTheme();
  const couponImage = saleswomanCouponCode ? theme.checkout.saleswomanCouponImage : theme.checkout.couponImage;

  const renderSaleswoman = () => (
    <Grid container>
      <Grid item xs={12}>
        <img
          src={couponImage}
          style={{
            padding: 0,
            textAlign: 'center',
            width: '100%',
          }}
          alt="Logo"
        />
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="body1"
          style={{
            textTransform: 'uppercase',
            fontWeight: 'bold',
            fontSize: '2.25rem',
            lineHeight: '1.25',
            textAlign: 'center',
          }}
        >
          {saleswomanCouponCode}
        </Typography>
      </Grid>
    </Grid>
  );

  const renderDefault = () => (
    <img
      src={couponImage}
      style={{
        padding: theme.checkout.padding,
        textAlign: 'center',
        width: '100%',
      }}
      alt="Logo"
    />
  );

  return saleswomanCouponCode ? renderSaleswoman() : renderDefault();
};

export default SomaSalesCoupon;
