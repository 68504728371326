import { v4 as uuidv4 } from 'uuid';
import { save } from './localStorageHelper';

const saveBag = (userStorage, bagProducts) => {
  userStorage.set('bag', bagProducts ?? []);
  save(userStorage);
};

const addToBag = (product, selectedItem, userStorage) => {
  const bagProducts = userStorage.get('bag') || [];
  const foundOnBag = bagProducts.find(
    (bagProduct) =>
      bagProduct.bagInfo.selectedItem.sku === selectedItem.sku &&
      bagProduct.bagInfo.selectedItem.size === selectedItem.size
  );

  if (foundOnBag) {
    const modifier = selectedItem.sharedBagQuantity
      ? Math.max(foundOnBag.bagInfo.quantity, selectedItem.sharedBagQuantity)
      : foundOnBag.bagInfo.quantity + 1;
    foundOnBag.bagInfo.quantity = modifier;
  } else {
    bagProducts.push({
      productId: product.id,
      bagInfo: { selectedItem, quantity: selectedItem.sharedBagQuantity || 1, uuid: uuidv4() },
    });
  }
  save(userStorage);
  return bagProducts;
};

const makeFetchParams = (method, body, signal) => {
  let params = {
    method,
    body: JSON.stringify(body),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    cache: 'default',
  };
  if (signal) {
    params = { ...params, signal };
  }
  return params;
};

const formatCartSimulatorResponse = (response, exceptions) => {
  const unavailable = { notAvailable: [], qtyNotAvailable: [] };

  if (response.messages?.length) {
    response.messages.forEach((item) => {
      switch (item.code) {
        case exceptions.itemNotAvailable:
          unavailable.notAvailable.push(item.fields.id);
          break;
        case exceptions.itemQuantityNotAvailable:
          unavailable.qtyNotAvailable.push(item.fields.id);
          break;
        default:
          break;
      }
    });
  }

  delete response.messages;

  return { ...response, ...unavailable };
};

const itemsCounter = (bagProducts) =>
  bagProducts.map((product) => product.bagInfo?.quantity).reduce((previous, current) => previous + current, 0);

const clearBagFromSharedUrl = (params) => {
  params.delete('bag');

  const newParams = params.toString() ? `?${params.toString()}` : '';
  window.history.replaceState({}, 'Soma Live', `${window.location.origin}${window.location.pathname}${newParams}`);

  return Promise.resolve();
};

const updateStorageBagProducts = (setBagBadgeCount, userStore, setComponentProducts, bagProducts) => {
  const quantities = bagProducts.map((product) => product.bagInfo.quantity);
  const total = quantities.reduce((previous, current) => previous + current, 0);

  setBagBadgeCount(total);
  userStore.set('bag', bagProducts);
  if (setComponentProducts) {
    setComponentProducts([...userStore.get('bag')]);
  }
  save(userStore);
};

const getSharedBagReferences = (encodedBag) => {
  let result;
  try {
    const payload = JSON.parse(Buffer.from(encodedBag.toString(), 'base64').toString('utf-8'));
    result = Promise.resolve(payload);
  } catch (e) {
    result = Promise.reject();
  }
  return result;
};

const buildBagByUrl = (allProducts, userStorage) => {
  const params = new URLSearchParams(window.location.search);
  const encodedBag = params.get('bag');
  if (encodedBag)
    return getSharedBagReferences(encodedBag).then((payload) => {
      clearBagFromSharedUrl(params);
      const sharedBagReferences = payload;

      const allFindedProducts = allProducts.filter(
        (item) => !!sharedBagReferences.find((sbr) => sbr.productId === item.id)
      );

      sharedBagReferences.forEach((sharedProduct) => {
        const product = allFindedProducts.find((item) => item.id === sharedProduct.productId);
        if (product) {
          sharedProduct.items.forEach((sharedItem) => {
            const item = product.items.find((pItem) => pItem.sku === sharedItem.sku);
            if (item) {
              addToBag(product, { ...item, sharedBagQuantity: sharedItem.qty }, userStorage);
            }
          });
        }
      });
      return { bag: userStorage.get('bag') };
    });
  return Promise.resolve({ bag: userStorage.get('bag') });
};

export {
  addToBag,
  itemsCounter,
  saveBag,
  buildBagByUrl,
  makeFetchParams,
  formatCartSimulatorResponse,
  updateStorageBagProducts,
};
