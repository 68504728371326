import React from 'react';
import { Drawer, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import { HeaderDrawer } from '../../atoms';
import { useDrawerStyle, useHeaderDrawerStyle, useGridStyles } from './Styles';
import { somaEvents, useEventDispatch } from '../../../events';

const SomaDrawer = (props) => {
  const {
    anchorDirection,
    children,
    isOpen,
    setIsOpen,
    titleDrawer,
    icon,
    scrollTarget,
    width,
    padding,
    left,
    borderRadius,
    maxHeight,
    type,
    showBackButton,
    closeSelfButton,
    drawerStyles,
    refParent,
    gridStyles,
    onClose,
  } = props;

  const leftParent = left || lodash.get(refParent, 'current.clientWidth', 0);

  const classes = useDrawerStyle({ width, padding, left: leftParent, borderRadius, maxHeight, ...drawerStyles });
  const headerClasses = useHeaderDrawerStyle({ type });
  const gridClasses = useGridStyles({ ...gridStyles });
  const dispatchEvent = useEventDispatch();

  const closeSelf = () => {
    setIsOpen(false);
  };

  const closeAllDrawer = () => {
    dispatchEvent(somaEvents.closeAllDrawers);
    if (onClose) onClose();
  };

  return (
    <Drawer open={isOpen} anchor={anchorDirection} onClose={closeAllDrawer} PaperProps={{ classes, ref: scrollTarget }}>
      <Grid container classes={gridClasses}>
        <Grid container item xs={12} classes={headerClasses}>
          <HeaderDrawer
            titleDrawer={titleDrawer}
            onClickClose={closeSelfButton ? closeSelf : closeAllDrawer}
            onClickBack={closeSelf}
            showBackButton={showBackButton}
            icon={icon}
            type={type}
          />
        </Grid>
        <Grid item xs={12} style={{ display: 'flex', maxHeight: 'calc(100% - 44px)', flexDirection: 'column' }}>
          {children}
        </Grid>
      </Grid>
    </Drawer>
  );
};

SomaDrawer.defaultProps = {
  anchorDirection: 'left',
  icon: null,
  type: 'default',
};

SomaDrawer.propTypes = {
  anchorDirection: PropTypes.oneOf(['left', 'right', 'bottom']),
  isOpen: PropTypes.bool.isRequired,
  icon: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
  }),
  type: PropTypes.oneOf(['default', 'measure', 'productDetail', 'productList']),
};

export default SomaDrawer;
