import { makeStyles } from '@material-ui/core';

const useFormattedPricesClasses = ({ theme, fontSize, ...installmentStyle }) =>
  makeStyles({
    root: {
      fontSize,
      lineHeight: '16px',
      color: theme.palette.primary.light,
      ...installmentStyle,
    },
    body2: { textTransform: theme.products.price.textTransform },
  })();

export default { useFormattedPricesClasses };
