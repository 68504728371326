import { makeStyles } from '@material-ui/core';

const useContainerStyle = makeStyles(() => ({
  root: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
    paddingBottom: 20,
    marginBottom: 30,
  },
}));

const useTypographyStyle = makeStyles(() => ({
  root: {
    marginBottom: 20,
  },
}));

export default { useContainerStyle, useTypographyStyle };
