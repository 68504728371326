import { makeStyles } from '@material-ui/core';

const useInputStyle = makeStyles(() => ({
  root: {
    margin: 10,
  },
}));

const useButtonStyle = makeStyles(() => ({
  root: {
    display: 'block',
    margin: '30px auto',
    backgroundColor: 'transparent',
    color: '#000',
    maxWidth: 300,
    width: '100%',
    border: '1px solid #000',
    '&:hover': {
      backgroundColor: '#DCC6B7',
      borderColor: '#DCC6B7',
    },
  },
}));

const fullWidthStyle = makeStyles(() => ({
  root: {
    width: '100%',
  },
}));

export default { useInputStyle, useButtonStyle, fullWidthStyle };
