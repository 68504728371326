import { makeStyles } from '@material-ui/core';

const useSomaTag = ({ ...tagStyles }) =>
  makeStyles((theme) => ({
    root: {
      lineHeight: '16px',
      display: 'flex',
      justifyContent: 'center',
      margin: '1.02px 0 3px 0',
      color: theme.somaTag.color,
      width: theme.somaTag.width,
      height: theme.somaTag.height,
      borderRadius: theme.somaTag.borderRadius,
      fontSize: theme.somaTag.fontSize,
      ...tagStyles,
    },
  }))();

export default {
  useSomaTag,
};
