import React from 'react';
import { Box, Container, Typography, useTheme } from '@material-ui/core';
import Styles from './styles';

const LiveNotFound = () => {
  const theme = useTheme();
  // remover localhost e vercel para validar a alteração para animale em ambiente local e de testes.
  const checkLocation = window.location.origin.includes('localhost', 'vercel', 'farm', 'lojix');
  const [containerClasses, titleClasses, subtitleClasses, additionalTitleClasses, boxClasses, imageClasses] = [
    Styles.useContainerStyle(),
    Styles.useTypographyTitleStyle(checkLocation),
    Styles.useTypographySubStyle(checkLocation),
    Styles.useTypographyAdditionalTitleStyle(checkLocation),
    Styles.useBoxStyle(),
    Styles.useImageStyle(),
  ];

  const Content = () => (
    <Box>
      {checkLocation && (
        <Box classes={boxClasses}>
          <img src={theme.liveNotFound.image.src} alt={theme.liveNotFound.image.alt} classes={imageClasses} />
        </Box>
      )}
      <Typography variant="h4" classes={titleClasses}>
        {theme.liveNotFound.title}
      </Typography>

      <Typography variant="h4" classes={subtitleClasses}>
        {theme.liveNotFound.subtitle}
      </Typography>

      <Typography variant="subtitle1" classes={additionalTitleClasses}>
        {theme.liveNotFound.additionalTitle}
      </Typography>
    </Box>
  );

  return (
    <Container classes={containerClasses}>
      <Content image={theme.liveNotFound.image} />
    </Container>
  );
};

export default LiveNotFound;
