import { makeStyles } from '@material-ui/core';

const useBoxStyle = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: '2px 5px 0px',
    height: 45,
  },
}));

export default { useBoxStyle };
