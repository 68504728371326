import { makeStyles } from '@material-ui/core';

const useDescriptionStyle = makeStyles((theme) => ({
  text: {
    color: theme.palette.primary.main,
    lineHeight: '16px',
    fontSize: theme.products.details.descriptionSize,
    margin: 0,

    '@media (orientation: portrait)': {
      fontSize: 12,
    },
  },

  b: {
    fontWeight: 'bold',
  },
  strong: {
    fontWeight: 'bold',
  },
  em: {
    fontStyle: 'italic',
  },
  i: {
    fontStyle: 'italic',
  },
}));

export default {
  useDescriptionStyle,
};
