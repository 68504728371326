import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Card,
  CardContent,
  Typography,
  Box,
  Link,
  Button,
} from '@material-ui/core/';
import { Close } from '@material-ui/icons';
import { useEventDispatch, somaEvents } from '../../../events';
import Style from './style';

const FaqContent = ({ title, description, href, hrefTitle, descriptionEnd }) => {
  const dispatchEvent = useEventDispatch();
  const cardClasses = Style.useCardStyle();
  if (href && hrefTitle && descriptionEnd) {
    return (
      <Card className={cardClasses.root}>
        <CardContent className={cardClasses.container}>
          <Typography className={cardClasses.title}>{title}</Typography>
          <Typography className={cardClasses.text}>
            {description}
            <Link
              onClick={() => dispatchEvent(somaEvents.onRedirectHelpButton)}
              className={cardClasses.useLinkStyle}
              href={href}
              target="_blank"
              title={href}
            >
              {hrefTitle}
            </Link>
            {descriptionEnd}
          </Typography>
        </CardContent>
      </Card>
    );
  }
  return (
    <Card className={cardClasses.root}>
      <CardContent className={cardClasses.container}>
        <Typography className={cardClasses.title}>{title}</Typography>
        <Typography className={cardClasses.text}>{description}</Typography>
      </CardContent>
    </Card>
  );
};
const SomaFAQ = ({ open, setOpen, noDialog = true }) => {
  const theme = useTheme();
  const { content } = theme.faq;
  const { boxContainer } = Style.useCardStyle();

  return (
    <Box className={boxContainer}>
      {noDialog && content?.map((question) => <FaqContent key={question.title} {...question} />)}

      {!noDialog && (
        <Dialog open={open} fullWidth>
          <DialogActions>
            <Button
              onClick={() => {
                setOpen(false);
              }}
            >
              <Close fontSize="small" />
            </Button>
          </DialogActions>

          <DialogContent style={{ overflowX: 'hidden', overflow: 'hidden', paddingBottom: 0 }}>
            {content?.map((question) => (
              <React.Fragment key={question.title}>
                <DialogTitle component="div">
                  <Box fontWeight="fontWeightBold">{question.title}</Box>
                </DialogTitle>
                <DialogContentText>{question.description}</DialogContentText>
              </React.Fragment>
            ))}
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
};

export default SomaFAQ;
