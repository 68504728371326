export default {
  evalError: 'eval_error',
  internalError: 'internal_error',
  uriError: 'uri_error',
  typeError: 'type_error',
  syntaxError: 'syntax_error',
  referenceError: 'reference_error',
  rangeError: 'range_error',
  requestError: 'request_error',
};
