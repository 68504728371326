import { makeStyles } from '@material-ui/core';

const useWrapperStyle = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    zIndex: 1600,
    width: '100%',
    minHeight: '75px',
    maxHeight: '75px',
    padding: `${theme.spacing(1)}px 0`,
    background: theme.system.messages.lgpd.box.background,
    transition: `transform 0.15s cubic-bezier(0.4, 0.0, 0.2, 1)`,
    willChange: 'transform',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    '@media(orientation: portrait)': {
      minHeight: '84px',
      maxHeight: '84px',
    },
    '@media (max-width: 900px) and (orientation: landscape)': {
      minHeight: '0px',
      maxHeight: '84px',
    },
  },
}));

const useTextStyle = makeStyles((theme) => ({
  root: {
    color: theme.system.messages.lgpd.text.style.color,
    fontWeight: theme.system.messages.lgpd.text.style.fontWeight,
    fontSize: theme.system.messages.lgpd.text.style.fontSize,
    '@media (max-width: 900px) and (orientation: landscape)': {
      marginTop: 0,
      marginLeft: '55px',
    },
    '@media (min-width: 900px)': {
      fontSize: '14px',
      marginTop: 0,
      marginLeft: '55px',
    },
    '@media(orientation: portrait)': {
      marginLeft: '18px',
      lineHeight: '16px',
      height: '100%',
    },
  },
}));

const useLinkStyle = makeStyles((theme) => ({
  root: {
    color: theme.system.messages.lgpd.text.style.color,
  },
}));

const useButtonAcceptStyle = (theme, portrait) => ({
  button: {
    borderRadius: theme.system.messages.lgpd.button.style.borderRadius,
    width: '100%',
    minWidth: 130,
    margin: portrait ? '24px 14px 24px 15px' : '18px',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  label: {
    color: theme.palette.secondary.main,
    fontSize: theme.system.messages.lgpd.button.style.fontSize,
  },
});

const useButtonGridStyle = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '157px',
    width: '157px',
    height: '40px',
    '@media (max-width: 900px) and (orientation: landscape)': {
      height: '33px',
    },
    '@media(orientation: portrait)': {
      width: '149px',
      minWidth: '149px',
    },
  },
}));

export { useWrapperStyle, useTextStyle, useLinkStyle, useButtonAcceptStyle, useButtonGridStyle };
