import { useEffect, useContext } from 'react';

import EventContext, { contextFnIndex } from './eventContext';

export const useEvent = (event, callback) => {
  const context = useContext(EventContext);

  const subscribe = context[contextFnIndex.subscribe];
  const unsubscribe = context[contextFnIndex.unsubscribe];

  useEffect(() => {
    subscribe(event, callback);

    return () => unsubscribe(event, callback);
  }, [subscribe, unsubscribe, event, callback]);
};
