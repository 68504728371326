import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Grid, Hidden, Container, Box, useMediaQuery } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useTheme } from '@material-ui/core/styles';
import lodash from 'lodash';
import {
  useContentStyle,
  useMainStyles,
  useVideoStyles,
  useDesktopMenuStyles,
  useMobileMenuStyles,
  useChatAndReactionBoxStyles,
  useShareButtonStyles,
  useMuteAndPipStyles,
} from './live.styles';

export default (props) => {
  const [initialMobileLandscapeWindowHeight, setInitialMobileLandscapeWindowHeight] = useState(window.innerHeight);
  const theme = useTheme();
  const [widthMenuDesk, setWidthMenuDesk] = useState(theme.menu.width);
  const [showCasePosition, setThowCasePosition] = useState('0');
  const isMobileLandscape = useMediaQuery(`(max-width: 926px) and (orientation: landscape)`);
  const contentClasses = useContentStyle();
  const muteAndPipClasses = useMuteAndPipStyles();
  const shareButtonClasses = useShareButtonStyles();
  const mainClasses = useMainStyles();
  const videoClasses = { ...mainClasses, ...useVideoStyles() };
  const menuDesktopClasses = { ...mainClasses, ...useDesktopMenuStyles(widthMenuDesk) };
  const menuMobileClasses = { ...mainClasses, ...useMobileMenuStyles() };
  const chatAndReactionBoxClasses = useChatAndReactionBoxStyles();

  const containerVideo = useRef();
  const refParent = useRef();

  const showTemplate = new URLSearchParams(window.location.search).has('showtemplate');

  const renderComponent = (component, skeletonCustomProps) => {
    if (showTemplate) {
      const skeletonDefault = { variant: 'rect', height: '100%', width: '100%' };
      const { title, hideTemplate, ...skeletonProps } = { ...skeletonDefault, ...skeletonCustomProps };
      return hideTemplate ? null : <Skeleton {...skeletonProps}>{title}</Skeleton>;
    }
    return component || null;
  };

  const [
    Somatube,
    MenuMobile,
    MenuDesktop,
    Showcasing,
    ProductsListDrawer,
    SurveyDrawer,
    anchorSizeDrawer,
    ProductDetailsDrawer,
    SomaBagDrawer,
    MuteButton,
    ChatButton,
    ReactionButton,
    surveyButton,
    MobileShare,
    PipButton,
  ] = [
    { title: 'somatube' },
    { title: 'menuMobile' },
    { title: 'menuDesktop' },
    { title: 'showcasing' },
    { title: 'productsList', hideTemplate: true },
    { title: 'somaBag', hideTemplate: true },
    { title: 'survey', hideTemplate: true },
    { title: 'anchorSize', hideTemplate: true },
    { title: 'productDetails', hideTemplate: true },
    { title: 'muteButton', variant: 'circle', width: 50, height: 50 },
    { title: 'chatButton', width: 120, height: 50 },
    { title: 'reactionButton', variant: 'circle', width: 50, height: 50 },
    { title: 'surveyButton', variant: 'circle', width: 50, height: 50 },
    { title: 'mobileShare', variant: 'circle', width: 50, height: 50 },
    { title: 'pipButton', variant: 'circle', width: 50, height: 50 },
  ].map(({ title, variant = 'rect', ...others }) =>
    renderComponent(
      [].concat(props.children ?? []).find((child) => child.key === title),
      {
        title,
        variant,
        ...others,
      }
    )
  );

  useEffect(() => {
    window.addEventListener('orientationchange', () => {
      setTimeout(() => {
        setInitialMobileLandscapeWindowHeight(window.innerHeight);
      }, 100);
    });
  }, [setInitialMobileLandscapeWindowHeight]);

  const [aspectRatio, marginBottonProduct] = [
    lodash.get(theme, 'video.aspectRatio', 1),
    lodash.get(theme, 'showCase.marginBottomShowCase', 0),
  ];

  const refreshshowCasePosition = useCallback(() => {
    const heightVideo = containerVideo.current.clientWidth / aspectRatio;
    const positionVideo = (containerVideo.current.clientHeight - heightVideo) / 2;

    const widthVideo = containerVideo.current.clientHeight * aspectRatio;
    const widthPage = window.innerWidth;

    const widthLeftover = widthPage - widthVideo;
    setWidthMenuDesk(widthLeftover);

    setThowCasePosition(positionVideo + marginBottonProduct);
  }, [marginBottonProduct, aspectRatio]);

  useEffect(() => {
    refreshshowCasePosition();
  }, [Showcasing, refreshshowCasePosition]);

  window.addEventListener('resize', () => refreshshowCasePosition());
  return (
    <Container
      maxWidth={false}
      disableGutters
      style={{
        height: isMobileLandscape ? initialMobileLandscapeWindowHeight : window.innerHeight,
      }}
    >
      <Grid classes={contentClasses} container wrap="nowrap">
        <Grid item classes={videoClasses} ref={containerVideo}>
          {Somatube}
          <Hidden smDown>
            <Box position="absolute" bottom={showCasePosition}>
              {Showcasing}
            </Box>
          </Hidden>
          <Box classes={chatAndReactionBoxClasses}>
            <Hidden smUp>{surveyButton}</Hidden>
            {ReactionButton}
            <Hidden smDown>{ChatButton}</Hidden>
          </Box>

          <Box classes={muteAndPipClasses}>
            {PipButton}
            {MuteButton}
          </Box>
          <Box classes={shareButtonClasses}>
            <Hidden smUp>{MobileShare}</Hidden>
          </Box>
        </Grid>
        <Hidden smUp>
          <Grid item classes={menuMobileClasses} md={1}>
            {MenuMobile}
          </Grid>
        </Hidden>
        <Hidden smDown>
          <Grid item classes={menuDesktopClasses} id="pageMenu" xs={12} ref={refParent}>
            {MenuDesktop}
          </Grid>
        </Hidden>
      </Grid>
      {[ProductsListDrawer, SomaBagDrawer, SurveyDrawer, ProductDetailsDrawer, anchorSizeDrawer].map((drawer) =>
        React.cloneElement(drawer, { refParent })
      )}
    </Container>
  );
};
