import { ptBR } from '@material-ui/core/locale';
import config from '../config';
import { themeTemplate, colorTransform } from './somalive';

const welcomeBackgroundDesktop = `${config.assetsUrl}/farm/tapume_reprise_desktop.jpg`;
const welcomeBackgroundMobile = `${config.assetsUrl}/farm/tapume_reprise_mobile.jpg`;
const welcomeIcon = `${config.assetsUrl}/farm/logotipo-novo-farm.png`;
const faqIcon = `${config.assetsUrl}/common/icons/help_icon.svg`;
const bagIcon = `${config.assetsUrl}/common/icons/bag_icon.svg`;
const productIcon = `${config.assetsUrl}/common/icons/products_icon.svg`;
const chatIcon = `${config.assetsUrl}/common/icons/chat_icon.svg`;
const quizIcon = `${config.assetsUrl}/common/icons/poll_icon.svg`;
const onGoingIcon = `${config.assetsUrl}/common/icons/onGoingIcon.svg`;
const resultsIcon = `${config.assetsUrl}/common/icons/resultsIcon.svg`;
const couponImage = `${config.assetsUrl}/farm/reprise_coupon.png`;
const saleswomanCouponImage = `${config.assetsUrl}/farm/reprise_salesWomanCoupon.png`;
const tapumeMiniplayer = `${config.assetsUrl}/farm/miniplayer-tapume.jpg`;
const faviconFarm = `${config.assetsUrl}/common/favicons/farmv2.png`;
const measureGuideImage = `${config.assetsUrl}/farm/measure_guide.png`;
const measureMobileImage = `${config.assetsUrl}/farm/measure_table_mobile.png`;
const emptyBagIcon = `${config.assetsUrl}/farm/icon/emptyBag.svg`;
const emptySurveyIcon = `${config.assetsUrl}/farm/icon/emptySurvey.svg`;
const pageNotFound = `${config.assetsUrl}/common/error-page-image.png`;
const errorIcon = `${config.assetsUrl}/farm/errorText-icon.svg`;
const mobileChatImage = `${config.assetsUrl}/farm/mobile-chat-image.svg`;

const template = themeTemplate({
  mainColor: '#333333',
  secondaryColor: '#FFF',
  secondaryFontFamily: 'GalanoGrotesqueAltMedium',
  fontFamily: 'GalanoGrotesqueAlt',
  badgeBgColor: '#333333',
  badgeColor: 'white',
  buttonsBorderRadius: 0,
});

const main = {
  palette: {
    primary: { main: '#333333', dark: '#222222', light: '#999999' },
    secondary: { main: '#fff', light: '#f5f5f5', dark: '#E5E5E5' },
    feedback: {
      main: '#FE6F66',
      light: '#FFF3F2',
      medium: '#F94F44',
      dark: '#C4A9FF',
    },
    common: {
      white: '#FFF',
    },
  },
  typography: {
    fontFamily: template.fontFamily,
  },
  DialogTitle: {
    fontFamily: template.fontFamily,
  },
  overrides: {
    MuiTypography: {
      root: {
        fontFamily: template.fontFamily,
        color: template.mainColor,
        textTransform: 'lowercase',
      },
    },
    MuiButton: {
      root: {
        borderRadius: template.buttonsBorderRadius,
        textTransform: 'lowercase',
      },
    },
    MuiAlert: {
      root: {
        color: template.mainColor,
        textTransform: 'lowercase',
      },
      standardSuccess: {
        color: template.mainColor,
        backgroundColor: '#fff',
        '& $icon': {
          color: template.mainColor,
        },
      },
      standardInfo: {
        color: template.mainColor,
        backgroundColor: '#fff',
        '& $icon': {
          color: template.mainColor,
        },
      },
    },
  },

  errorOnSearch: {
    icon: {
      src: errorIcon,
    },
    errorTextPrimary: 'ops, sua busca não foi encontrada',
    errorTextSecondary: 'você pode tentar utilizar outro termo ou navegar através das categorias',
  },

  faq: {
    text: 'ajuda',
    icon: {
      src: faqIcon,
      alt: 'ajuda',
    },
    content: [
      {
        title: 'o que é lojix?',
        description:
          'lojix é a nossa primeira loja virtual 100% interativa. uma experiência única com a curadoria especial do time farm pra você.',
      },
      {
        title: 'posso interagir em tempo real?',
        description: 'sim! clique no ícone "chat" pra enviar um recadinho pra gente ou tirar suas dúvidas.',
      },
      {
        title: 'como vejo os produtos apresentados?',
        description:
          'clique no ícone "produtos" pra visualizar o catálogo e navegue pelas categorias pra encontrar o seu desejo.',
      },
      {
        title: 'posso comprar em tempo real?',
        description:
          'sim! escolha o produto, selecione o seu tamanho e clique em "eu quero". o produto será imediatamente adicionado à sua mochila.',
      },
      {
        title: 'como finalizo minha compra?',
        description:
          'clique no ícone "mochila" e depois em "finalizar compra". a gente vai te direcionar pro nosso site pra efetuar o pagamento de forma rápida e segura.',
      },

      {
        title: 'fiz uma compra, posso devolver?',
        description:
          'dá pra solicitar a devolução no nosso site em até 7 dias corridos, após o recebimento da sua compra. caso opte por receber um vale-troca, o prazo será de até 14 dias. é só ',
        hrefTitle: ' clicar aqui',
        href: 'https://lojafarm.minhatroca.com.br/',
        descriptionEnd: ', escolher o pedido e seguir o passo a passo.',
      },
      {
        title: 'posso trocar?',
        description:
          'sim! você tem até 30 dias para trocar produtos em uma de nossas lojas físicas. mas fica de olho, produtos do bazar, me leva ou produtos entregues por parceiros, marketplace e off premium não poderão ser trocados, ok? somente devolvidos no prazo de 30 dias corridos, contados a partir da data de recebimento do pedido.',
      },
    ],
  },
  survey: {
    openModalColor: template.mainColor,
    buttonChoiceColor: template.mainColor,
    emptySurvey: {
      text: 'hmmm, parece que nenhuma enquete foi iniciada...',
    },
    emptyResult: {
      text: 'hmmm, parece que ainda não temos resultados...',
    },
    buttonChoiceTextColor: '#fff',
    icon: {
      src: quizIcon,
      alt: 'Enquete',
    },
    emptyIcon: {
      src: emptySurveyIcon,
      alt: 'Enquete vazia',
    },
    onGoingIcon: {
      src: onGoingIcon,
      alt: 'Em andamento',
    },
    resultsIcon: {
      src: resultsIcon,
      alt: 'resultados',
    },
  },
  anchorSize: {
    profiles: [
      {
        name: 'Carol Potengy',
        srcImage: `${config.assetsUrl}/farm/profile/anchor/carol potengy.jpg`,
        size: 'P',
        bust: '92',
        waist: '70',
        hips: '102',
        height: '1,61',
      },
      {
        name: 'Jéssica Louzada',
        srcImage: `${config.assetsUrl}/farm/profile/anchor/Jéssica.jpg`,
        size: 'P',
        bust: '88',
        waist: '73',
        hips: '109',
        height: '1,66',
      },
      {
        name: 'Júlia Polido',
        srcImage: `${config.assetsUrl}/farm/profile/anchor/Julia polido.jpg`,
        size: 'P',
        bust: '86',
        waist: '70',
        hips: '99',
        height: '1,65',
      },
      {
        name: 'Lili Rubim',
        srcImage: `${config.assetsUrl}/farm/profile/anchor/lili.png`,
        size: 'P',
        bust: '92',
        waist: '81',
        hips: '103',
        height: '1,57',
      },
      {
        name: 'Reysla Rocha',
        srcImage: `${config.assetsUrl}/farm/profile/anchor/Resyla.jpg`,
        size: 'GG',
        bust: '124',
        waist: '98',
        hips: '121',
        height: '1,59',
      },
    ],
  },
  bag: {
    text: 'mochila',
    emptyBag: 'sua mochila está vazia',
    emptyBagColor: 'secondary',
    urlSharer: {
      color: template.mainColor,
      textFirstStep: 'Compartilhar mochila',
      textSecondStep: 'Copiar link',
      background: 'default',
      buttonFontWeight: 'bold',
    },
    icon: {
      src: bagIcon,
      alt: 'sacola',
    },
    emptyIcon: {
      src: emptyBagIcon,
      alt: 'sacola',
    },
  },
  products: {
    text: 'produtos',
    icon: {
      src: productIcon,
      alt: 'produtos',
    },
    size: {
      text: 'selecione o tamanho:',
      typography: {
        variant: 'subtitle1',
      },
    },
    details: {
      text: 'detalhes do produto',
      button: {
        variant: 'text',
        fullWidth: false,
      },
    },
    measure: {
      image: measureGuideImage,
      mobileImage: measureMobileImage,
      typography: {
        fontFamily: template.secondaryFontFamily,
      },
    },
    button: {
      text: 'eu quero',
      variant: 'contained',
      color: 'primary',
      hoverBackground: '#553717',
    },
    composition: {
      text: 'composição',
    },
    name: {
      color: 'primary',
    },
    price: {
      sale: {
        color: 'primary',
      },
    },
    quantity: {
      variant: 'subtitle1',
    },
    notAvaiable: {
      text: 'produto indisponível',
    },
    search: {
      notFound: 'produto não encontrado',
      emptyBrand: 'é necessário fornecer uma brand para pesquisar o produto',
    },
  },
  liveNotFound: {
    image: {
      src: pageNotFound,
      alt: 'Page 404',
    },
    title: 'erro 404',
    subtitle: 'ops, algo deu errado :(',
    additionalTitle: 'por favor, verifique se o link está correto ou tente novamente em instantes.',
  },
  welcomePage: {
    paper: {
      backgroundImageDesktop: `url(${welcomeBackgroundDesktop})`,
      backgroundImageMobile: `url(${welcomeBackgroundMobile})`,
      backgroundPosition: 'center bottom',
    },
    container: {
      header: {
        maxWidthDesktop: '18%',
        maxWidthMobile: '58%',
        maxHeight: '35%',
        paddingTop: 20,
        welcomeIcon: {
          src: welcomeIcon,
          alt: 'farm-welcome-logo',
        },
      },
      content: {
        paddingTop: 20,
        paddingBottom: 20,
        paddingRight: 0,
        paddingLeft: 0,
        boxContainerMaxWidth: 650,
        main: {
          text: ['a reprise da live já tá disponível!', 'aproveita pra assistir quantas vezes quiser 🤍'],
          textAlign: 'center',
          fontSize: '1.75rem',
          fontWeight: '200',
          lineHeight: 1,
        },
        additional: {
          text: '',
          textAlign: 'center',
          fontSize: 24,
          fontWeight: '200',
          maxHeight: '',
          letterSpacing: 1,
        },
        footer: {
          text: '',
          fontSize: '1.75rem',
          fontWeight: '700',
          letterSpacing: 1,
        },
      },
      callToAction: {
        mode: 'button',
        width: 150,
        button: {
          text: 'vem assistir',
          textColor: '#333333',
          color: 'secondary',
          variant: 'contained',
          borderRadius: '100px',
          fontSize: '16px',
          maxHeight: '',
          custom: true,
          fullWidth: true,
        },
      },
    },
  },
  checkout: {
    baseUrl: 'https://www.farmrio.com.br',
    path: '/checkout/cart/add',
    button: {
      text: 'finalizar compra',
    },
    discountText: 'descontos',
    totalText: 'Total',
    couponText: 'cupom de 15% off',
    subtotalText: 'subtotal',
    installmentNumber: 10,
    installmentMinValue: 10,
    couponImage,
    saleswomanCouponImage,
  },

  somaTag: {
    exclusive: {
      text: 'preview lojix',
      backgroundColor: '#23A484',
    },
  },

  chat: {
    active: true,
    text: 'chat',
    icon: {
      src: chatIcon,
      alt: 'chat',
    },
    mobileChatImage,
    mainBackgroundColor: template.mainColor,
    headerTextColor: '#FFF',
    headerText: 'chat!',
    setFirstNameText: 'seja bem-vinda(o) ao chat da lojix!',
    setSecondNameText: 'para participar, informe seu nome e e-mail.',
    buttonTextTheme: 'primary',
    setUserNameButtonText: 'acessar chat',
    colorTextChatPortrait: '#64473F',
    mainColor: template.mainColor,
    headerBackgroundColor: template.mainColor,
    tooltipEmailText: 'seu e-mail*',
    tooltipMessageText: 'mensagem*',
    emptyMessageErrorText: 'por favor insira uma mensagem!',
    messageSuccessText: 'mensagem enviada com sucesso!',
    emptyNameErrorText: 'por favor insira um nome!',
  },
  drawer: {
    header: {
      button: {
        size: 'small',
      },
    },
    padding: 10,
    width: '585px',
  },
  somaNavigation: {
    appBar: {
      position: 'static',
      elevation: 0,
      color: 'transparent',
    },
    tab: {
      variant: 'fullWidth',
      indicatorColor: 'primary',
      textTransform: 'lowercase',
    },
    quantity: {
      box: {
        style: {
          display: 'flex',
          alignItems: 'center',
        },
      },
      button: {
        style: {
          minWidth: 50,
          fontSize: 18,
          margin: 5,
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
      variant: 'subtitle1',
    },
  },
  realtimeProduct: {
    styles: {
      padding: 10,
    },
    title: 'Apresentando:',
    titleVariant: 'subtitle2',
    rerun: {
      playOnVideoTitle: 'Ir para o momento da apresentação',
    },
  },
  i18n: {
    locale: 'pt-BR',
    currency: 'BRL',
  },
  miniPlayer: {
    typography: {
      fontFamily: template.secondaryFontFamily,
      titleFontSize: '0.875rem',
    },
    tapumeImageURL: tapumeMiniplayer,
  },

  operator: {},
  system: {
    messages: {
      lgpd: {
        button: {
          style: {
            fontSize: '12px',
            borderRadius: '100px',
            backgroundColor: 'primary',
          },
        },
        box: { background: template.secondaryColor },
        text: {
          style: { color: template.mainColor },
          content: [
            {
              value: 'usamos ',
              type: 'text',
            },
            {
              value: 'cookies',
              type: 'link',
              href: 'https://www.farmrio.com.br/institucional/politicas#seguranca',
              title: 'Política de privacidade',
            },
            {
              value:
                ' no site pra dar a você uma melhor experiência :) pra continuar navegando, é só clicar no botão: ',
              type: 'text',
            },
          ],
        },
      },
    },
  },
  favicon: faviconFarm,
  title: 'LOJIX: a live interativa da FARM',
  snackbar: {
    iconStyles: {
      color: themeTemplate.mainColor,
      height: '50%',
    },
  },
};

export default { ...main, ...ptBR };

export { themeTemplate, colorTransform };
