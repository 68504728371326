import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Grid, ButtonBase } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useRealTimeProductsContext } from '../../../contexts/realTimeProductsContext';
import Product from '../Product';
import Styles from './styles';
import { useStreamContext } from '../../../contexts/streamContext';
import { somaEvents, useEventDispatch } from '../../../events';

const LiveProduct = () => {
  const { realTimeProductsState } = useRealTimeProductsContext();
  const { streamContent } = useStreamContext();
  const { showCasing } = realTimeProductsState;
  const { buttons } = streamContent;
  const theme = useTheme();

  const liveProductWrapper = useRef();
  const [transitionEnd, setTransitionEnd] = useState(false);
  const [isLiveProductOpen, setIsLiveProductOpen] = useState(true);
  const [liveProductMinHeight, setLiveProductMinHeight] = useState(0);
  const [showCasingProducts, setShowCasingProducts] = useState([]);
  const dispatchEvent = useEventDispatch();

  const allProducts = useMemo(
    () =>
      buttons
        .map((button) => button.products)
        .reduce((accumulator, currentProductsList) => accumulator.concat(currentProductsList), []),
    [buttons]
  );

  const buttonStyles = Styles.useButtonStyles(isLiveProductOpen);
  const gridProduct = Styles.useGridButtonStyles();
  const gridCard = Styles.useGridCardStyles();
  const mainGridContainerClasses = Styles.mainGridContainerStyles(
    !isLiveProductOpen && transitionEnd,
    isLiveProductOpen,
    liveProductMinHeight
  );

  const toogleProduct = () => {
    setIsLiveProductOpen(!isLiveProductOpen);
    setTransitionEnd(false);
  };

  useEffect(() => {
    const containerElement = liveProductWrapper.current;
    if (!containerElement) return undefined;
    const listener = (evt) => {
      if (evt.propertyName === 'transform') {
        setTransitionEnd(true);
      }
    };

    containerElement.addEventListener('transitionend', listener);
    return () => {
      containerElement.removeEventListener('transitioned', listener);
    };
  }, []);

  useEffect(() => {
    if (liveProductMinHeight < liveProductWrapper.current.clientHeight) {
      setLiveProductMinHeight(liveProductWrapper.current.clientHeight);
    }
  }, [liveProductMinHeight, showCasing]);

  useEffect(() => {
    if (!showCasing) return;
    try {
      const filteredProducts = showCasing.map(({ provider }) =>
        allProducts.find((product) => product && product.provider.productId === provider.productId)
      );

      const productsShowCasing = filteredProducts.filter((item) => !!item);

      setShowCasingProducts(productsShowCasing);
    } catch (error) {
      setShowCasingProducts([]);
    }
  }, [allProducts, showCasing]);

  return (
    <Grid container ref={liveProductWrapper} classes={mainGridContainerClasses}>
      {Boolean(showCasingProducts.length) && (
        <>
          {showCasingProducts.map((product) => (
            <Grid
              item
              classes={gridCard}
              key={product.id}
              onClick={() => dispatchEvent(somaEvents.onProductShowcasingClicked, { product })}
            >
              <Product isShowCasing category={theme.categories.showCasing} product={{ ...product, bagInfo: {} }} />
            </Grid>
          ))}
          <Grid item classes={gridProduct}>
            <ButtonBase disableRipple onClick={toogleProduct} classes={buttonStyles} />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default LiveProduct;
