import { makeStyles } from '@material-ui/core';

const useButtonStyle = (theme) => ({
  button: {
    margin: '0 0 15px',
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.checkout.button.shape.borderRadius,
    '&:hover': { backgroundColor: theme.palette.primary.dark },
  },
  label: {
    color: theme.palette.secondary.main,
    fontSize: 16,
    lineHeight: '24px',

    '@media(orientation: portrait)': {
      fontSize: 14,
    },
  },
  icon: {
    fontSize: 14,
    color: theme.palette.secondary.main,
  },
});

const shareIconButtonStyles = makeStyles((theme) => ({
  root: {
    border: theme.checkout.shareButton.shape.border,
    width: '100%',
    height: '100%',
    borderRadius: theme.checkout.shareButton.shape.borderRadius,
  },
}));

const useGridContainerStyles = makeStyles(() => ({
  root: {
    marginTop: 23,
    '@media(orientation: portrait)': {
      marginTop: 20,
    },
  },
}));

const useBuyButtonGridStyles = makeStyles(() => ({
  root: {
    height: 56,
    marginTop: 34,
    width: 205,
    '@media(orientation: portrait)': {
      width: 182,
      height: 48,
      marginTop: 30,
    },
  },
}));

const useGridItemStyles = makeStyles(() => ({
  root: {
    padding: 0,
  },
}));

const useShareButtonGridStyles = makeStyles(() => ({
  root: {
    height: 56,
    width: 56,
    marginTop: 34,
    marginRight: 20,
    '@media(orientation: portrait)': {
      height: 48,
      width: 48,
      marginTop: 30,
    },
  },
}));

export default {
  useButtonStyle,
  useGridContainerStyles,
  useGridItemStyles,
  useShareButtonGridStyles,
  useBuyButtonGridStyles,
  shareIconButtonStyles,
};
