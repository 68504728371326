import _ from 'lodash';
import { ptBR } from '@material-ui/core/locale';
import Color from 'color';
import { common } from '@mui/material/colors';
import config from '../config';

const welcomeBackgroundDesktop = `${config.assetsUrl}/default/bgDesktop.jpeg`;
const welcomeBackgroundMobile = `${config.assetsUrl}/default/bgMobile.jpg`;
const welcomeIcon = `${config.assetsUrl}/default/logo.png`;
const magnifyIcon = `${config.assetsUrl}/default/zoomIcon.svg`;
const logoSoma = `${config.assetsUrl}/common/logoSoma.png`;
const logoSomaLabs = `${config.assetsUrl}/common/logoSomalabs.png`;
const pageNotFound = `${config.assetsUrl}/common/error-page-image.png`;
const faqIcon = `${config.assetsUrl}/common/icons/help_icon.svg`;
const bagIcon = `${config.assetsUrl}/common/icons/bag_icon.svg`;
const mobileBagIcon = `${config.assetsUrl}/common/icons/bag_icon.svg`;
const productIcon = `${config.assetsUrl}/common/icons/products_icon.svg`;
const mobileProductIcon = `${config.assetsUrl}/common/icons/products_icon.svg`;
const chatIcon = `${config.assetsUrl}/common/icons/chat_icon.svg`;
const mobileChatIcon = `${config.assetsUrl}/common/icons/chat_icon.svg`;
const quizIcon = `${config.assetsUrl}/common/icons/poll_icon.svg`;
const answerIcon = `${config.assetsUrl}/common/icons/survey-heart-icon.svg`;
const answerIconHover = `${config.assetsUrl}/common/icons/survey-heart-icon-hover.svg`;
const couponImage = `${config.assetsUrl}/common/blank-coupon.png`;
const saleswomanCouponImage = `${config.assetsUrl}/common/blank-sales-coupon.png`;
const tapumeMiniplayer = `${config.assetsUrl}/common/miniplayer-tapume.jpg`;
const favicomDefault = `${config.assetsUrl}/common/favicons/faviconSoma.ico`;
const deleteIcon = `${config.assetsUrl}/common/icons/delete.svg`;
const desktopMuteIcon = `${config.assetsUrl}/common/icons/desktop_mute_icon.svg`;
const desktopUnmuteIcon = `${config.assetsUrl}/common/icons/desktop_unmute_icon.svg`;
const mobileMuteIcon = `${config.assetsUrl}/common/icons/mobile_mute_icon.svg`;
const mobileUnmuteIcon = `${config.assetsUrl}/common/icons/mobile_unmute_icon.svg`;
const outlinedHeart = `${config.assetsUrl}/farm/icon/heart.svg`;
const selfReactionHeart = `${config.assetsUrl}/farm/icon/heartRed.svg`;
const newFeatureSurvey = `${config.assetsUrl}/common/icons/new_feature_survey.svg`;
const pipIcon = `${config.assetsUrl}/common/icons/pip.svg`;
const sendMessageIcon = `${config.assetsUrl}/common/icons/send_message.svg`;
const anchorSizeIcon = `${config.assetsUrl}/animale/icons/animale_medidaApresentadores.svg`;
const shareIconProduct = `${config.assetsUrl}/common/icons/shareIconProduct.svg`;

const colorTransform = (hex, colorIntensity = 50) => {
  const colorObject = Color.rgb(hex);
  const colorDark = colorObject.color.map((item) => item - colorIntensity);
  const colorLight = colorObject.color.map((item) => item + colorIntensity);

  return {
    dark: `rgba(${colorDark.join()}, ${colorObject.valpha})`,
    light: `rgba(${colorLight.join()}, ${colorObject.valpha})`,
    main: hex,
  };
};

const themeTemplate = ({
  mainColor,
  secondaryColor,
  fontFamily,
  secondaryFontFamily,
  badgeBgColor,
  badgeColor,
  buttonsBorderRadius,
  badgeRightPosition,
  badgeToptPosition,
}) => ({
  mainColor,
  secondaryColor,
  fontFamily,
  secondaryFontFamily,
  badgeBgColor,
  badgeColor,
  buttonsBorderRadius,
  badgeRightPosition,
  badgeToptPosition,
});
const palette = {
  primary: { main: '#333333', dark: '#222222', light: '#999999' },
  secondary: { main: '#fff', light: '#f5f5f5', dark: '#E5E5E5' },
  feedback: { main: '#FE6F66', light: '#FFF3F2', medium: '#F94F44', dark: '#C4A9FF' },
  common,
};
const main = {
  snackBarRadius: '4px',
  pdp: {
    mobileBorderRadius: '16px 16px 0 0',
    productNameMobileSize: '14px',
    productNameSize: '14px',
    productNameHeight: '18px',
    productNameSpacingTop: '43px',
    productPriceSize: 14,
    ctaButtonWidth: '111px',
    ctaButtonHeight: '48px',
    ctaButtonRadius: '100px',
    ctaButtonSize: '14px',
    ctaButtonMobileBG: '#fff',
    ctaButtonMobileColor: '#000',
    buttonSizeWidth: '40px',
    buttonSizeHeight: '40px',
    buttonSizeRadius: '50%',
    buttonSizeGap: '15px',
  },
  palette,
  typography: {
    mainColor: palette.primary.main,
    fontFamily: 'GalanoGrotesqueAlt',
  },
  quantitySelector: {
    borderRadius: '50%',
    backgroundColor: palette.secondary.dark,
    border: 'none',
  },
  DialogTitle: {
    fontFamily: 'GalanoGrotesqueAlt',
  },
  props: {
    MuiUseMediaQuery: {
      noSsr: true,
    },
  },
  errorOnSearch: {
    fontSizeH1: '24px',
    fontSizeP: '16px',
    lineHeightP: '24px',
    marginTop: '100px',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          WebkitTextSizeAdjust: '100%',
          'scrollbar-color': `${palette.primary.light} transparent`,
        },
        '*::-webkit-scrollbar': {
          width: '5px',
          height: '100%',
        },
        '*::-webkit-scrollbar-track': {
          background: `${palette.secondary.dark}`,
          borderRadius: '2px',
        },
        '*::-webkit-scrollbar-thumb': {
          borderRadius: '2px',
          background: palette.primary.light,
        },
        '*::-webkit-scrollbar-button': {
          display: 'none',
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: 'initial',
      },
    },
    MuiBadge: {
      badge: {
        backgroundColor: '#333333',
        color: '#FFFFFF',
      },
    },
    MuiTab: {
      textColorInherit: {
        opacity: 1,
      },
    },
    MuiTypography: {
      caption: {
        fontSize: '0.625rem',
        color: themeTemplate.mainColor,
      },
    },
    MuiDialogTitle: {
      root: {
        padding: 0,
      },
    },
    MuiSnackbar: {
      anchorOriginTopRight: {
        marginRight: '60px',
      },
    },
  },
  gtm: { active: true },
  categories: {
    showCasing: 'apresentando',
  },
  menu: {
    width: 180,
  },
  faq: {
    active: true,
    text: 'Ajuda',
    icon: {
      src: faqIcon,
      alt: 'Ajuda',
    },
    shape: {
      borderRadius: 10,
    },
    content: [
      {
        title: `Como vejo outras FAQs?`,
        description: `Selecione no addon acima (ícone lápis) a brand que deseja visualizar.`,
      },
    ],
    fontWeight: 'bold',
    lineHeight: '24px',
    lineHeightText: '16px',
  },
  survey: {
    active: true,
    text: 'Enquete',
    openModalColor: themeTemplate.mainColor,
    shape: {
      borderRadius: 100,
      fontSize: 24,
    },
    textStyle: {
      fontFamily: themeTemplate.fontFamily,
    },
    subTextStyle: {
      fontSize: '16px',
    },
    emptySurvey: {
      title: 'enquetes',
      subtitle: 'queremos saber o que você acha',
      text: 'nenhuma enquete foi iniciada',
      subtext: 'aproveita pra explorar nossas novidades e pra interagir com a gente pelo chat',
    },
    emptyResult: {
      text: 'ainda não temos resultado da enquete',
      subtext: 'aproveita pra explorar nossas novidades e pra interagir com a gente pelo chat',
    },
    answeredSurvey: 'recebemos seu voto. já já vamos compartilhar o resultado',
    padding: '8px 20px 16px',
    winnerColor: '#23A484',
    questionText: 'pergunta ',
    buttonChoiceColor: themeTemplate.mainColor,
    buttonChoiceTextColor: '#fff',
    answerIcon,
    answerIconHover,
    icon: {
      src: quizIcon,
      alt: 'Enquete',
    },
    emptyIcon: {
      src: quizIcon,
      alt: 'Enquete vazia',
    },
  },
  poll: {
    shape: {
      borderRadius: '12px',
    },
  },
  anchorSize: {
    icon: {
      src: anchorSizeIcon,
      alt: 'medidas dos apresentadores',
    },
    style: {
      borderRadius: '12px',
      fontSize: '24px',
      fontFamily: themeTemplate.fontFamily,
    },
    mobileStyle: {
      fontSize: '20px',
    },
    subtitleStyle: {
      fontSize: '16px',
    },
    active: true,
    text: 'Medidas',
    openModalColor: themeTemplate.mainColor,
    titleAnchorSize: 'medidas dos apresentadores',
    subTitleAnchorSize: 'confira as medidas dos nossos apresentadores.',
    buttonChoiceColor: themeTemplate.mainColor,
    buttonChoiceTextColor: '#fff',
    profiles: [],
  },
  bag: {
    active: true,
    text: 'Sacola',
    emptyBag: 'Sua sacola está vazia',
    emptyTextStyle: {
      fontFamily: themeTemplate.fontFamily,
    },
    button: {
      shape: {
        marginLeft: 0,
      },
    },
    emptyBagColor: 'secondary',
    urlSharer: {
      color: themeTemplate.mainColor,
      textFirstStep: 'Compartilhar sacola',
      errorCopyText: 'Não foi possivel copiar o link do carrinho, tente novamente mais tarde',
      successCopyText: 'Link copiado com sucesso!',
      textSecondStep: 'Copiar link',
      background: 'default',
      buttonFontWeight: 'bold',
    },
    icon: {
      src: bagIcon,
      alt: 'Sacola',
    },
    mobileIcon: {
      src: mobileBagIcon,
      alt: 'Sacola',
    },
    emptyIcon: {
      src: bagIcon,
      alt: 'sacola',
    },
    continueShopping: {
      text: 'Continuar comprando',
      borderRadius: 100,
    },
    deleteIcon: {
      href: deleteIcon,
      src: deleteIcon,
    },
  },
  bagSimulationExceptions: {
    itemQuantityNotAvailable: 'itemQuantityNotAvailable',
    itemNotAvailable: 'ORD027',
  },
  products: {
    shareIcon: {
      src: shareIconProduct,
      alt: 'compartilhar',
    },
    categoryButtom: {
      shape: {
        borderRadius: 100,
      },
      textTransform: 'lowercase',
    },
    useFigureButtonStyles: {
      shape: {
        borderRadius: '50%',
      },
      border: '1px',
      backgroundColor: '',
    },
    FigureButtonMobileStyle: {
      shape: {
        borderRadius: '50%',
      },
      height: '48px',
      minWidth: '48px',
    },
    filter: {
      buttonConfirm: {
        label: 'confirmar',
        shape: {
          borderRadius: 100,
        },
        width: '124px',
        height: '56px',
        fontSize: '16px',
      },
      buttonClearFilters: {
        label: 'limpar',
        width: '99px',
        height: '56px',
      },
      sideHelper: {
        label: 'filtrar por tamanho',
        textColor: palette.primary.main,
      },
      textDecoration: 'none',
      badge: {
        textColor: '#999',
        background: '#eee',
      },
      shape: {
        borderRadius: 10,
      },
    },
    placeholder: '#333333',
    active: true,
    text: 'Produtos',
    successBag: {
      text: 'Produto adicionado!',
    },
    successBagFromUrl: {
      text: 'Produtos adicionados!',
    },
    filters: {
      titles: {
        sizes: 'filtre por tamanho',
      },
      FilterButton: {
        shape: {
          borderRadius: '100px',
        },
      },
      ToggleButton: {
        minWidth: '40px',
        shape: {
          borderRadius: '50%',
        },
        margin: 0,
      },
      shape: {
        borderRadius: '16px 16px 0 0',
      },
      marginRight: '',
    },
    icon: {
      src: productIcon,
      alt: 'Produtos',
      shape: {
        borderRadius: '100px',
        backgroundColor: palette.secondary.light,
      },
    },
    mobileIcon: {
      src: mobileProductIcon,
      alt: 'Produtos',
    },
    hover: {
      icon: `url(${magnifyIcon})`,
    },
    size: {
      text: 'Selecione o Tamanho:',
      typography: {
        variant: 'subtitle1',
        textTransform: 'uppercase',
        size: 14,
      },
      shape: {
        borderRadius: '50%',
      },
    },
    details: {
      title: 'sobre a peça',
      titleSize: '16px',
      textSize: '14px',
      descriptionSize: '12px',
      text: 'Detalhes do Produto',
      button: {
        variant: 'text',
        fullWidth: false,
      },
    },
    measure: {
      title: 'tabela de medidas',
      additionalText: ' *se você não tem uma fita métrica, pode usar um barbante ou fita junto com uma régua.',
      firstColumnTitle: 'tamanho',
      borderRadius: '10px',
    },
    button: {
      text: 'Adicionar na sacola',
      variant: 'contained',
      color: 'secondary',
      hoverBackground: '#333',
    },
    composition: {
      text: 'Composição',
    },
    name: {
      color: 'primary',
    },
    price: {
      variant: 'body2',
      installment: {
        variant: 'caption',
      },
      sale: {
        color: 'secondary',
      },
      textTransform: 'uppercase',
    },
    quantity: {
      variant: 'subtitle1',
      size: 12,
    },
    notAvaiable: {
      text: 'Produto Indisponível',
    },
    search: {
      title: 'busque em todas as categorias',
      notFound: 'Produto não encontrado',
      emptyBrand: 'É necessário fornecer uma brand para pesquisar o produto',
      shape: {
        borderRadius: 32,
      },
    },
  },
  showCase: {
    productWidth: 142,
    marginBottomShowCase: 70,
    closeTabShowCaseWidth: 47,
    button: {
      src: `${config.assetsUrl}/common/icons/arrow-show-case.svg`,
    },
  },
  liveNotFound: {
    image: {
      src: pageNotFound,
      alt: 'Page 404',
    },
    title: 'erro 404',
    subtitle: 'ops, algo deu errado :(',
    additionalTitle: 'por favor, verifique se o link está correto ou tente novamente em instantes.',
  },
  welcomePage: {
    paper: {
      backgroundImageDesktop: `url(${welcomeBackgroundDesktop})`,
      backgroundImageMobile: `url(${welcomeBackgroundMobile})`,
      backgroundPosition: 'center center',
    },
    container: {
      justify: null,
      header: {
        maxHeight: '30%',
        maxWidthMobile: '80%',
        maxWidthDesktop: '50%',
        paddingBottom: 0,
        paddingTop: 0,
        letterSpacing: 0,
        welcomeIcon: {
          src: welcomeIcon,
          alt: 'brand-welcome-background',
        },
      },
      content: {
        maxHeight: '50%',
        paddingTop: 0,
        paddingRight: 10,
        paddingLeft: 10,
        paddingBottom: 0,
        spacing: 0,
        boxContainerMaxWidth: 750,
        main: {
          text: 'Bem vindo à Somalive, este é um texto exemplo da página de Welcome com o tema default. Você pode ver um tema customizado selecionando uma brand no ícone de tema na toolbar.',
          textAlign: 'center',
          fontSize: 22,
          color: '#FFF',
          fontWeight: '400',
          margin: 0,
          letterSpacing: 0,
        },
        additional: {
          text: '',
          textAlign: 'center',
          fontSize: 16,
          color: '#FFF',
          fontWeight: '400',
          letterSpacing: 0,
        },
        footer: {
          text: new Date().toLocaleDateString(),
          textAlign: 'center',
          fontSize: 32,
          fontWeight: '700',
          color: '#FFF',
          letterSpacing: 0,
        },
      },
      callToAction: {
        mode: 'button',
        maxHeight: '20%',
        margin: 0,
        width: 'auto',
        button: {
          text: 'Assistir Live',
          color: 'secondary',
          fullWidth: false,
          variant: 'contained',
          custom: false,
          weight: 'normal',
          shape: {
            borderRadius: '100px',
          },
        },
        input: {
          text: 'preencha seu e-mail para entrar',
          color: 'primary',
          fullWidth: false,
          variant: 'standard',
          custom: false,
          weight: 'normal',
          fontSize: '10px',
        },
      },
    },
  },
  checkout: {
    baseUrl: '',
    path: '',
    params: '',
    button: {
      text: 'Finalizar',
      shape: {
        borderRadius: '100px',
      },
    },
    discountText: 'Desconto',
    totalText: 'Total',
    couponText: 'CUPOM DE 10% OFF',
    subtotalText: 'Subtotal',
    installmentNumber: 1,
    installmentMinValue: 0,
    couponImage,
    padding: 0,
    saleswomanCouponImage,
    discountTextColor: '#23A484',
    shareButton: {
      shape: {
        border: `1px solid ${palette.secondary.dark}`,
        borderRadius: 100,
      },
    },
    cupomCodeText: 'adicione o cupom "<CUPOMCODEVALUE>" no checkout',
    cupomCodeValue: '',
  },
  somaTag: {
    stockOut: {
      text: 'sem estoque',
      backgroundColor: '#FE6F66',
    },
    cashBack: {
      text: 'cashback',
      backgroundColor: 'green',
    },
    exclusive: {
      text: 'exclusivo lojix',
      backgroundColor: '#23A484',
    },
    mostLiked: {
      text: 'queridinho voltou',
      backgroundColor: '#333333',
    },

    color: '#FFFFFF',
    width: 91,
    height: 16,
    borderRadius: '4px',
    fontSize: '12px',
  },
  chat: {
    active: true,
    text: 'Chat',
    height: 274,
    width: 375,
    icon: {
      src: chatIcon,
      alt: 'Chat',
    },
    sendMessageIcon,
    mobileIcon: {
      src: mobileChatIcon,
      alt: 'Chat',
    },
    mainBackgroundColor: themeTemplate.mainColor,
    headerTextColor: '#fff',
    headerText: 'Chat!',
    setNameText:
      "The chat is off-line. But don't worry, you can ask questions at hello@farmrio.com or chat with us at farmrio.com. See you there!",
    buttonTextTheme: 'primary',
    setUserNameButtonText: 'Acessar Chat',
    colorTextChatPortrait: 'black',
    mainColor: '#000',
    headerBackgroundColor: themeTemplate.mainColor,
    tooltipEmailText: 'E-mail',
    tooltipNameText: 'seu nome*',
    tooltipMessageText: 'Mensagem',
    emptyMessageErrorText: 'Por favor insira uma mensagem!',
    messageSuccessText: 'Mensagem enviada com sucesso!',
    emptyNameErrorText: 'Por favor insira um nome!',
    productDetailText: 'Detalhes do Produto',
    borderRadius: '32px',
    fontSizeButtonLabel: '14px',
    lineHeight: '16px',
    fontWeight: '500px',
    borderTopLeftRadius: '10px !important',
    borderBottomLeftRadius: '10px !important',
  },
  drawer: {
    header: {
      button: {
        size: 'small',
      },
    },
    padding: 10,
    width: '40%',
  },
  productSearch: {
    errorText: 'Ops, nenhum produto encontrado para essa pesquisa.',
  },
  SomaIconButton: {
    text: 'topo',
  },
  somaNavigation: {
    appBar: {
      position: 'static',
      elevation: 0,
      color: 'transparent',
      display: 'flex',
    },
    tab: {
      textColor: 'primary',
      variant: 'fullWidth',
      indicatorColor: 'primary',
      fontSize: 12,
      textTransform: 'inherit',
      fontWeight: 'bold',
    },
    quantity: {
      box: {
        style: {
          display: 'flex',
          alignItems: 'center',
        },
      },
      button: {
        style: {
          minWidth: 50,
          fontSize: 18,
          margin: 5,
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
      variant: 'subtitle1',
    },
  },
  realtimeProduct: {
    styles: {
      padding: 10,
    },
    title: 'Incluir na sacola:',
    label: 'apresentando',
    titleVariant: 'subtitle2',
    rerun: {
      playOnVideoTitle: 'Ir para o momento da apresentação',
    },
    modalWidthDesktop: 150,
    modalWidthMobile: 111,
  },
  i18n: {
    locale: 'pt-BR',
    currency: 'BRL',
  },
  operator: {},
  login: {
    header: {
      logo: {
        url: logoSoma,
        alt: 'Grupo SOMA',
      },
      text: {
        variant: 'h5',
      },
    },
    form: {
      inputUser: {
        text: 'Usuário',
      },
      inputPassword: {
        text: 'Senha',
      },
      button: {
        text: 'Entrar',
      },
    },
    footer: {
      text: 'Criado e desenvolvido por SomaLabs',
      logo: {
        url: logoSomaLabs,
        alt: 'SomaLabs',
      },
    },
  },
  system: {
    messages: {
      email: {
        postError: 'Falha ao salvar o email, tente novamente em instantes.',
        timeout: 'Falha ao salvar o email, tente novamente em instantes.',
        failedToFetch: 'Falha ao salvar o e-mail, verifique sua conexão e tente novamente em instantes.',
      },
      embedLiveStream: 'não foi possivel executar esta ação, tente novamente mais tarde!',
      cancelPreviousRequestError: 'Uma nova requisição de atualização foi iniciada, abortando a anterior.',
      refreshProductFailed: 'Não foi possivel atualizar os produtos',
      offline: 'No momento estamos offline',
      lgpd: {
        button: {
          variant: 'contained',
          style: {
            backgroundColor: 'blue',
            color: '#FFFFFF',
            border: '1px solid transparent',
            borderRadius: '100px',
          },
        },
        box: { background: 'rgba(255,255,255, 0.8)' },
        text: {
          style: { color: '#FFFFFF', fontWeight: '400', fontSize: '12px' },
          content: [
            {
              value: 'usamos',
              type: 'text',
            },
            {
              value: 'cookies',
              type: 'link',
              href: '/institucional/politicas#seguranca',
            },
            {
              value: 'no site para dar a você uma experiencia melhor de compra',
              type: 'text',
            },
            {
              buttonText: 'aceitar cookies',
              type: 'text',
            },
          ],
        },
      },
    },
  },
  miniPlayer: {
    URLParams: 'utm_live=miniplayer',
    tapumeImageURL: tapumeMiniplayer,
  },
  favicon: favicomDefault,
  title: 'Soma Live',
  snackbar: {
    iconStyles: {
      color: themeTemplate.mainColor,
      height: '50%',
    },
  },
  mutePlayer: {
    borderRadius: 100,
    desktopIcons: {
      desktopMuteIcon: {
        src: desktopMuteIcon,
      },
      desktopUnmuteIcon: {
        src: desktopUnmuteIcon,
      },
    },
    mobileIcons: {
      mobileMuteIcon: {
        src: mobileMuteIcon,
      },
      mobileUnmuteIcon: {
        src: mobileUnmuteIcon,
      },
    },
  },
  video: {
    aspectRatio: 16 / 9,
  },
  reaction: {
    active: true,
    borderRadius: 100,
    icons: {
      selfReactionHeart,
      outlinedHeart,
    },
    colors: {
      myReaction: '#FE6F66',
      othersReaction: '#FFFFFF',
    },
  },
  newFeatures: [
    {
      active: false,
      icon: newFeatureSurvey,
      title: 'tem novidade na lojix',
      text: 'agora você pode interagir com a gente pela',
      name: 'enquete',
      subText: 'fica de olho: vão rolar algumas perguntas pra você ajudar a construir a lojix junto com a gente.',
      button: 'adorei a novidade',
    },
  ],
  pictureInPicture: { icon: pipIcon, borderRadius: 100 },
  share: { borderRadius: '50%' },
};

export default _.merge(main, ptBR);
export { themeTemplate, colorTransform };
