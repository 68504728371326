import { makeStyles } from '@material-ui/core';

const useImageStyle = makeStyles(() => ({
  root: {
    width: 80,
    marginBottom: 20,
    textAlign: 'center',
  },
}));

const useContainerStyle = makeStyles(() => ({
  root: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: 50,
  },
}));

export default { useImageStyle, useContainerStyle };
