import { makeStyles } from '@material-ui/core';

const useButtonStyles = makeStyles({
  text: {
    padding: 0,
  },
});

const useBoxStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
});

const useDialogStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  paperWidthSm: {
    overflow: 'hidden',
    height: '500px',
  },
  paperWidthXs: {
    overflow: 'hidden',
    maxHeight: '95%',
    maxWidth: '360px',
  },
});

const useCloseIconStyles = makeStyles((theme) => ({
  root: {
    padding: '0px',
    color: theme.palette.primary.main,
  },
}));

const useNavButtonsStyles = {
  backgroundColor: 'transparent',
  color: '#494949',
  padding: 0,
  transform: 'scale(1.5)',
  margin: '0 10px',
};

const useImageGridStyle = makeStyles({
  root: {
    marginTop: '0',
    height: '500px',
    width: '100%',
    padding: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    '@media(orientation: portrait)': {
      flexDirection: 'column-reverse',
      height: 'unset',
      marginTop: '30px',
    },
  },
});

const useImageStyle = makeStyles((theme) => ({
  mainPicture: {
    '@media(orientation: portrait)': {
      cursor: `${theme.products.hover.icon} 2 2, pointer`,
    },
    '@media(orientation: landscape)': {
      marginRight: 0,
    },
  },
  productImage: {
    width: '100%',
    borderRadius: '5px',
  },
  carouselImage: {
    objectFit: 'contain',
    margin: '10px 10px 5px 10px',
    paddingBottom: '60px',
  },
}));

const useCloseIconButtonStyle = makeStyles({
  root: {
    position: 'absolute',
    top: 0,
    right: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

export default {
  useButtonStyles,
  useBoxStyles,
  useImageStyle,
  useNavButtonsStyles,
  useCloseIconStyles,
  useCloseIconButtonStyle,
  useImageGridStyle,
  useDialogStyles,
};
