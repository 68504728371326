import slugify from 'slugify';
import { deviceDetect, isMobile, browserName } from 'react-device-detect';

export const slugfy = (string) => {
  const config = {
    lower: true,
    replacement: '_',
    strict: true,
  };
  return slugify(string, config);
};

export const getMainProductInfos = (payload = {}) => {
  if (!payload.product) return payload;

  const { images, guide, composition, description, id, id_button: idButton, ...mainInfo } = payload.product;
  payload.product = mainInfo;

  return payload;
};

export const getDevice = () => {
  const { model, osName, osVersion, os } = deviceDetect();

  if (isMobile) {
    return `${model} ${os} ${osVersion} (${browserName})`;
  }

  return `${osName} ${osVersion} (${browserName})`;
};
