import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import Styles from './styles';

const HeaderLogin = (props) => {
  const { text } = props;
  const theme = useTheme();

  const containerClasses = Styles.useContainerStyle();
  const typographyClasses = Styles.useTypographyStyle();

  const image = get(theme.login.header, 'logo', {});

  return (
    <Grid container item classes={containerClasses}>
      <Typography variant={theme.login.header.text.variant} classes={typographyClasses}>
        {text}
      </Typography>
      <img src={image.url} alt={image.alt} />
    </Grid>
  );
};

HeaderLogin.defaultProps = {
  text: 'Somalive',
};

HeaderLogin.propTypes = {
  text: PropTypes.string,
};

export default HeaderLogin;
