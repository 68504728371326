import { makeStyles } from '@material-ui/core';

const useInputFieldStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    marginTop: '20px',

    '& .MuiOutlinedInput-root': {
      borderRadius: theme.chat.borderRadius,
      height: '48px',

      '& .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme.palette.secondary.dark}`,
      },

      '&.Mui-focused fieldset': {
        border: '1px solid',
        borderColor: theme.palette.primary.main,
      },

      '&:not(.Mui-focused):hover fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
      '& input::placeholder': {
        color: '#737378',
        fontSize: '14px',
        lineHeight: '16px',
        opacity: 1,
      },
      '&.Mui-error fieldset': {
        borderColor: theme.palette.feedback.main,
      },
      '&.Mui-error:hover fieldset': {
        borderColor: theme.palette.feedback.main,
      },
    },
    '& .MuiOutlinedInput-input': {
      padding: '12px 24px',
    },
  },
}));

const useButtonStyles = (theme) => ({
  button: {
    backgroundColor: theme.chat.mainColor,
    position: 'absolute',
    transition: '0.5s',
    width: '133px',
    height: '48px',
    borderRadius: theme.chat.borderRadius,

    '@media(orientation: portrait)': {
      width: '150px',
    },

    '&:hover': {
      opacity: '0.7',
      backgroundColor: theme.chat.mainColor,
    },
  },
  label: {
    color: theme.palette.secondary.main,
    fontSize: theme.chat.fontSizeButtonLabel,
    fontWeight: theme.chat.fontWeight,
    lineHeight: theme.chat.lineHeight,
  },
});

const useChatTitleStyles = makeStyles((theme) => ({
  root: {
    fontSize: '14px',
    lineHeight: theme.chat.lineHeight,
    fontWeight: theme.chat.fontWeight,
  },
}));

const useButtonGridStyles = makeStyles({
  root: {
    padding: 0,
    marginTop: 10,
    '@media(orientation: portrait)': {
      padding: '0 25px',
      marginTop: 20,
    },
  },
});

const useBoxStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    padding: 0,
    '@media(orientation: portrait)': {
      padding: 0,
      marginTop: theme.chat.mobileChatImage ? 0 : '30px',
    },
  },
}));

const useChatTitleGridStyles = makeStyles({
  root: {
    padding: 0,
    '@media(orientation: portrait)': {
      padding: '0 25px',
    },
  },
});

export default {
  useInputFieldStyles,
  useButtonStyles,
  useChatTitleStyles,
  useButtonGridStyles,
  useBoxStyles,
  useChatTitleGridStyles,
};
