const getAllProductsTimestamps = (timeline) => {
  const { keyframes } = timeline;

  if (timeline && keyframes && keyframes.length) {
    return timeline.keyframes.reduce((map, item) => {
      item.products.forEach((product) =>
        map.get(product.id_product)
          ? map.set(product.id_product, [...map.get(product.id_product), item.rerun.displayAt])
          : map.set(product.id_product, [item.rerun.displayAt])
      );
      return map;
    }, new Map());
  }

  return new Map();
};

const formatTimeStamp = (timestamp) => new Date(timestamp).toISOString().slice(11, 19);

export { getAllProductsTimestamps, formatTimeStamp };
